import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;

interface ChatSecretariaEntity {
  id: string;
  id_user: string;
  id_clinica: string;
  title: string;
  created_at: Date;
}

interface ChatSecretariaMessageEntity {
  id: string;
  id_user: string;
  user_query?: string;
  llm_answer?: string;
  created_at: Date;
}

export const getChatsList = async (id_user: string, id_clinica: string): Promise<ChatSecretariaEntity[]> => {
  try {
    const response = await axios.get(`${API_URL}/api/chat-secretaria/chats/${id_user}/${id_clinica}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching chat list:', error);
    throw new Error('Failed to fetch chat list. Please try again later.');
  }
};

export const createNewChat = async (id_user: string, id_clinica: string, title: string = 'Novo Chat'): Promise<ChatSecretariaEntity> => {
  try {
    const response = await axios.post(`${API_URL}/api/chat-secretaria/chats`, { id_user, id_clinica, title });
    return response.data;
  } catch (error) {
    console.error('Error creating new chat:', error);
    throw new Error('Failed to create new chat. Please try again later.');
  }
};

export const updateChatTitle = async (id_chat: string, title: string): Promise<ChatSecretariaEntity> => {
  try {
    const response = await axios.put(`${API_URL}/api/chat-secretaria/chats/${id_chat}/title`, { title });
    return response.data;
  } catch (error) {
    console.error('Error updating chat title:', error);
    throw new Error('Failed to update chat title. Please try again later.');
  }
};

export const getChatMessages = async (id_chat: string, id_clinica: string): Promise<ChatSecretariaMessageEntity[]> => {
  try {
    const response = await axios.get(`${API_URL}/api/chat-secretaria/messages/${id_chat}/${id_clinica}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    throw new Error('Failed to fetch chat messages. Please try again later.');
  }
};

export const sendMessage = async (id_user: string, id_chat: string, id_clinica: string, message: string): Promise<{ userMessage: ChatSecretariaMessageEntity; aiMessage: ChatSecretariaMessageEntity }> => {
  try {
    const response = await axios.post(`${API_URL}/api/chat-secretaria/messages`, { id_user, id_chat, id_clinica, message });
    return response.data;
  } catch (error) {
    console.error('Error sending message:', error);
    throw new Error('Failed to send message. Please try again later.');
  }
};

export const deleteChatService = async (id_chat: string): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/api/chat-secretaria/chats/${id_chat}`);
  } catch (error) {
    console.error('Error deleting chat:', error);
    throw new Error('Failed to delete chat. Please try again later.');
  }
};