import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;

// Tipos para melhorar a segurança de tipo
interface CustomerData {
  name: string;
  email: string;
  address?: string;
  cpfCnpj?: string;
}

interface SubscriptionData {
  customerId: string;
  priceId: string;
}

interface PaymentIntentData {
  amount: number;
  currency: string;
  productId?: string;
  priceId?: string;
}

interface Price {
  stripePriceId: string;
  nickname: string;
  currency: string;
  unitAmount: number;
  interval: string;
  intervalCount: number;
  active: boolean;
  metadata: object;
  id: string;
  createdAt: string;
  updatedAt: string;
}

interface Product {
  stripeProductId: string;
  name: string;
  description: string | null;
  active: boolean;
  metadata: object;
  id: string;
  createdAt: string;
  updatedAt: string;
  prices: Price[];
}

interface SubscriptionData {
  customerId: string;
  priceId: string;
  paymentMethodId: string;
  trialPeriodDays?: number;
}


// Função para obter os produtos e filtrar preços mensal e anual
export const getProducts = async (): Promise<Product[]> => {
  try {
    const response = await axios.get(`${API_URL}/api/stripe/products`);
    const products: Product[] = response.data;

    // Filtrar os preços para manter apenas os intervalos mensal e anual
    const filteredProducts = products.map(product => {
      const filteredPrices = product.prices.filter(price =>
        (price.interval === 'month' && price.intervalCount === 1) ||
        (price.interval === 'year' && price.intervalCount === 1)
      );
      return {
        ...product,
        prices: filteredPrices
      };
    });

    console.log('Produtos filtrados:', filteredProducts);
    return filteredProducts;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

// Função para criar um Payment Intent no Stripe
export const createPaymentIntent = async (params: PaymentIntentData, userId: string, clinicaId: string) => {
  try {
    const response = await axios.post(`${API_URL}/api/stripe/create-payment-intent`, {
      ...params,
      metadata: {
        user_id: userId,
        clinica_id: clinicaId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating payment intent:', error);
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Failed to create payment intent');
    }
    throw error;
  }
};



// Função para criar uma assinatura no Stripe
export const createSubscription = async (subscriptionData: SubscriptionData, userId: string, clinicaId: string) => {
  try {
    const response = await axios.post(`${API_URL}/api/stripe/create-subscription`, {
      ...subscriptionData,
      metadata: {
        user_id: userId,
        clinica_id: clinicaId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};



// Função para criar uma sessão de checkout no Stripe
export const createCheckoutSession = async (data: {
  customerId: string;
  priceId: string;
  paymentMethodId: string;
  userId: string;
  clinicaId: string;
}) => {
  try {
    const response = await axios.post(`${API_URL}/api/stripe/create-checkout-session`, {
      ...data,
      metadata: {
        user_id: data.userId,
        clinica_id: data.clinicaId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
};



// Função para criar um cliente no Stripe
export const createCustomer = async (customerData: CustomerData, userId: string, clinicaId: string) => {
  try {
    const response = await axios.post(`${API_URL}/api/stripe/create-customer`, {
      ...customerData,
      metadata: {
        user_id: userId,
        clinica_id: clinicaId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating customer:', error);
    throw error;
  }
};


// Função para obter as transações de uma clínica específica
export const getTransactionsByClinica = async (clinicaId: string) => {
  try {
    const response = await axios.get(`${API_URL}/api/stripe-events/${clinicaId}`);
    console.log('Transações recebidas:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error;
  }
};

// Função para obter a licença ativa de uma clínica
export const getActiveLicense = async (clinicaId: string) => {
  try {
    const response = await axios.get(`${API_URL}/api/stripe-events/active-license/${clinicaId}`);
    console.log('Licença ativa recebida:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching active license:', error);
    throw error;
  }
};
