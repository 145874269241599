import React, { useEffect, useState, useMemo } from "react";
import { Box, Flex, Heading, Text, VStack, Grid, Container, GridItem, useColorMode, useColorModeValue, Badge } from "@chakra-ui/react";
import Header from "components/Header";
import { Card, CardHeader, CardBody } from "@chakra-ui/card";
import { DefaultPageContainer, ContentContainer, LeftContainer } from "../../styles/DefaultPage.styles";
import { getActiveLicense, getTransactionsByClinica } from "services/stripeService";
import { useAuth } from "context/authContext";

interface SubscriptionEvent {
  title: string;
  date: string;
  description: string;
  valor: string;
}

interface SubscriptionDetails {
  plan: string;
  nickname: string;
  status: string;
  startDate: string;
  endDate: string;
  amount: string;
}

// Definição dos tipos das chaves permitidas
type EventType =
  | "customer.subscription.updated"
  | "invoice.payment_succeeded"
  | "payment_intent.created"
  | "requires_payment_method"
  | "customer.subscription.created"
  | "checkout.session.completed"
  | "customer.subscription.deleted";

// Função para formatar a data
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR'); // Formato dd/MM/AAAA
};

// Função para formatar o valor da moeda
const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount / 100); // Dividimos por 100 pois o valor geralmente está em centavos
};

const SubscriptionPage: React.FC = () => {
  const { toggleColorMode } = useColorMode();
  const currentTheme = useColorModeValue("light", "dark");

  const { userData } = useAuth();
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails | null>(null);
  const [subscriptionEvents, setSubscriptionEvents] = useState<SubscriptionEvent[]>([]);

  const eventTranslations = useMemo(
    () => ({
      "customer.subscription.updated": (event: any) => ({
        title: "Assinatura confirmada",
        description: "Sua assinatura foi confirmada",
        date: formatDate(event.createdAt),
        valor: "-"
      }),
      "invoice.payment_succeeded": (event: any) => ({
        title: "Sucesso no pagamento",
        description: "O pagamento foi confirmado pela instituição",
        date: formatDate(event.createdAt),
        valor: formatCurrency(event.amount)
      }),
      "payment_intent.created": (event: any) => ({
        title: "Intenção de pagamento iniciado",
        description: "Sua intenção de pagamento foi identificada",
        date: formatDate(event.createdAt),
        valor: "-"
      }),
      "requires_payment_method": (event: any) => ({
        title: "Método de pagamento detectado",
        description: "Método de pagamento escolhido",
        date: formatDate(event.createdAt),
        valor: "-"
      }),
      "customer.subscription.created": (event: any) => ({
        title: "Assinatura criada",
        description: "Sua assinatura foi criada",
        date: formatDate(event.createdAt),
        valor: "-"
      }),
      "checkout.session.completed": (event: any) => ({
        title: "Checkout completo",
        description: "Processo de checkout completado",
        date: formatDate(event.createdAt),
        valor: "-"
      }),
      "customer.subscription.deleted": (event: any) => ({
        title: "Assinatura cancelada",
        description: "Sua assinatura foi cancelada",
        date: formatDate(event.createdAt),
        valor: formatCurrency(event.amount)
      }),
    }),
    []
  );

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        if (userData) {
          const clinicaId = userData?.id_clinica; // Obtenha o clinicaId do userData

          if (clinicaId) {
            const data = await getActiveLicense(clinicaId);
            if (data) {
              setSubscriptionDetails({
                plan: data.product_ds || "N/A",
                nickname: data.nickname || "N/A",
                status: data.status || "N/A",
                startDate: formatDate(data.startDate) || "N/A",
                endDate: formatDate(data.endDate) || "N/A",
                amount: formatCurrency(data.amount) || "R$ 0,00"
              });

              const events = await getTransactionsByClinica(clinicaId);
              setSubscriptionEvents(events.map((event: any) => {
                const translationFunc = eventTranslations[event.eventType as EventType];
                if (translationFunc) {
                  return translationFunc(event);
                }
                return {
                  title: event.eventType || "Evento",
                  date: formatDate(event.createdAt) || "N/A",
                  description: event.descricao || "Status desconhecido",
                  valor: event.amount ? formatCurrency(event.amount) : "-"
                };
              }));
            }
          } else {
            console.error("ClinicaId não encontrado.");
          }
        }
      } catch (error) {
        console.error("Erro ao buscar os detalhes da assinatura:", error);
      }
    };

    fetchSubscriptionData();
  }, [userData, eventTranslations]);

  return (
    <>
      <Header theme={currentTheme} toggleTheme={toggleColorMode} />
      <DefaultPageContainer>
        <ContentContainer>
          <LeftContainer>
            <Container maxW="container.xl" py={12}>
              <Grid templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={8}>
                <GridItem>
                  <Heading as="h2" size="lg" mb={4}>
                    Assinatura - Eventos
                  </Heading>
                  <VStack
                    spacing={4}
                    align="stretch"
                    maxHeight="400px" // Define a altura máxima para a área de rolagem
                    overflowY="auto"
                    css={{
                      scrollbarWidth: "none", // Firefox
                      "&::-webkit-scrollbar": {
                        display: "none", // Webkit (Chrome, Safari)
                      },
                    }}
                  >
                    {subscriptionEvents.map((event, index) => (
                      <Box key={index} bg="gray.100" p={4} borderRadius="lg">
                        <Flex justifyContent="space-between" alignItems="center">
                          <Text fontWeight="medium">{event.title}</Text>
                          <Text fontSize="sm" color="gray.500">
                            {event.date}
                          </Text>
                        </Flex>
                        <Text fontSize="sm" color="gray.600" mt={1}>
                          {event.description}
                        </Text>
                        <Text fontSize="sm" color="gray.600" mt={1}>
                          Valor: {event.valor}
                        </Text>
                      </Box>
                    ))}
                  </VStack>
                </GridItem>
                <GridItem>
                  <Heading as="h2" size="lg" mb={4}>
                    Assinatura - Detalhes
                  </Heading>
                  {subscriptionDetails ? (
                    <Card>
                      <CardHeader>
                        <Flex justifyContent="space-between" alignItems="center">
                          <Box>
                            <Heading size="md">{subscriptionDetails.plan}</Heading>
                            <Text fontSize="sm" color="gray.500">
                              Recorrência: {subscriptionDetails.status === 'trialing' ? 'Período de avaliação' : subscriptionDetails.nickname}
                            </Text>
                          </Box>
                          <Badge variant="outline" px={2} py={1} borderRadius="full">
                            {subscriptionDetails.status}
                          </Badge>
                        </Flex>
                      </CardHeader>
                      <CardBody>
                        <VStack spacing={4} align="stretch">
                          <Flex justifyContent="space-between">
                            <Text fontSize="sm" color="gray.500">
                              Data de Início:
                            </Text>
                            <Text>{subscriptionDetails.startDate}</Text>
                          </Flex>
                          <Flex justifyContent="space-between">
                            <Text fontSize="sm" color="gray.500">
                              Válida Até:
                            </Text>
                            <Text>{subscriptionDetails.endDate}</Text>
                          </Flex>
                          {subscriptionDetails.status !== 'trialing' && (
                            <Flex justifyContent="space-between">
                              <Text fontSize="sm" color="gray.500">
                                Valor:
                              </Text>
                              <Text>{subscriptionDetails.amount}</Text>
                            </Flex>
                          )}
                        </VStack>
                      </CardBody>
                    </Card>

                  ) : (
                    <Text>Loading subscription details...</Text>
                  )}
                </GridItem>
              </Grid>
            </Container>
          </LeftContainer>
        </ContentContainer>
      </DefaultPageContainer>
    </>
  );
};

export default SubscriptionPage;
