import React from 'react';
import { Button, ButtonProps, Box } from '@chakra-ui/react';

interface CustomButtonProps extends ButtonProps {
  text: string;
  colorScheme?: string;
  onClick?: () => void; // Torne onClick opcional
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  icon?: React.ReactElement; // Adicionando a propriedade icon
}

const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  colorScheme = 'blue',
  onClick = () => {}, // Valor padrão para onClick
  disabled,
  isLoading,
  loadingText = 'Carregando...',
  icon,
  ...rest
}) => {
  return (
    <Button
      variant="outline"
      colorScheme={colorScheme}
      onClick={onClick}
      _hover={{
        bg: `${colorScheme}.500`,
        color: 'white',
      }}
      disabled={disabled || isLoading} // Desabilita o botão se estiver carregando
      {...rest} // Passa as demais props para o componente Button do Chakra UI
    >
      {isLoading ? loadingText : (
        <>
          {icon && <Box as="span" mr={2}>{icon}</Box>}
          {text}
        </>
      )}
    </Button>
  );
};

export default CustomButton;
