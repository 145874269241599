const messages: { [key: number]: string } = {
    200: 'Operação realizada com sucesso.',
    201: 'Recurso criado com sucesso.',
    400: 'Requisição inválida. Verifique os dados e tente novamente.',
    401: 'Email ou Senha incorretos.',
    403: 'Proibido. Você não tem permissão para realizar esta ação.',
    404: 'Usuário não existe.',
    500: 'Erro interno do servidor. Tente novamente mais tarde.',
  };
  
  const defaultMessage = 'Ocorreu um erro desconhecido.';
  
  export const getMessageByStatus = (status: number): string => {
   // console.log('Status in getMessageByStatus:', status, typeof status);
    return messages[status] || defaultMessage;
  };
  