import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;

interface QDAtendimento {
  id_qdh: number;
  quadro?: { [key: string]: any };
  profissionalId?: number;
  convenioId: number;
}

interface CreateQDAtendimentoDto {
  quadro?: { [key: string]: any };
  profissionalId?: number;
  convenioId: number;
}

interface UpdateQDAtendimentoDto {
  quadro?: { [key: string]: any };
  profissionalId?: number;
  convenioId?: number;
}

class QDAtendimentoService {
  private orderQuadro(quadro: { [key: string]: any }): { [key: string]: any } {
    const orderedDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
    const orderedQuadro: { [key: string]: any } = {};
    orderedDays.forEach(day => {
      if (quadro[day]) {
        orderedQuadro[day] = quadro[day];
      }
    });
    return orderedQuadro;
  }

  async create(createQDAtendimentoDto: CreateQDAtendimentoDto): Promise<QDAtendimento> {
    const orderedDto = {
      ...createQDAtendimentoDto,
      quadro: createQDAtendimentoDto.quadro ? this.orderQuadro(createQDAtendimentoDto.quadro) : undefined
    };
    const response = await axios.post<QDAtendimento>(`${API_URL}/api/qdatendimento`, orderedDto);
    return response.data;
  }

  async findAll(): Promise<QDAtendimento[]> {
    const response = await axios.get<QDAtendimento[]>(`${API_URL}/api/qdatendimento`);
    return response.data.map((item: QDAtendimento) => ({
      ...item,
      quadro: item.quadro ? this.orderQuadro(item.quadro) : undefined
    }));
  }

  async findOne(id: string): Promise<QDAtendimento> {
    const response = await axios.get<QDAtendimento>(`${API_URL}/api/qdatendimento/${id}`);
    return {
      ...response.data,
      quadro: response.data.quadro ? this.orderQuadro(response.data.quadro) : undefined
    };
  }

  async findByConvenio(idConvenio: number): Promise<QDAtendimento[]> {
    const response = await axios.get<QDAtendimento[]>(`${API_URL}/api/qdatendimento/convenio/${idConvenio}`);
    return response.data.map((item: QDAtendimento) => ({
      ...item,
      quadro: item.quadro ? this.orderQuadro(item.quadro) : undefined
    }));
  }

  async findByConvenioAndProfissional(idConvenio: number, idProfissional: number): Promise<QDAtendimento[]> {
    const response = await axios.get<QDAtendimento[]>(`${API_URL}/api/qdatendimento/convenio/${idConvenio}/profissional/${idProfissional}`);
    return response.data.map((item: QDAtendimento) => ({
      ...item,
      quadro: item.quadro ? this.orderQuadro(item.quadro) : undefined
    }));
  }

  async update(id: string, updateQDAtendimentoDto: UpdateQDAtendimentoDto): Promise<QDAtendimento> {
    const orderedDto = {
      ...updateQDAtendimentoDto,
      quadro: updateQDAtendimentoDto.quadro ? this.orderQuadro(updateQDAtendimentoDto.quadro) : undefined
    };
    const response = await axios.patch<QDAtendimento>(`${API_URL}/api/qdatendimento/${id}`, orderedDto);
    return response.data;
  }

  async remove(id: string): Promise<void> {
    await axios.delete(`${API_URL}/api/qdatendimento/${id}`);
  }

  async saveOrUpdate(payload: CreateQDAtendimentoDto): Promise<QDAtendimento> {
    const existingRecords = await this.findByConvenioAndProfissional(payload.convenioId, payload.profissionalId!);

    if (existingRecords.length > 0) {
      const record = existingRecords[0];
      return this.update(record.id_qdh.toString(), payload);
    } else {
      return this.create(payload);
    }
  }
}

const qdAtendimentoService = new QDAtendimentoService();
export default qdAtendimentoService;