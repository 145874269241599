import styled from 'styled-components';

export const DefaultPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 60px); // Subtrai a altura do Header
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const LeftContainer = styled.div`
  display: flex;  
  flex: 1;
  justify-content: center;
  padding: 20px;
`;

/* export const RightContainer = styled.div`
  width: 50%;
  padding: 20px;
  background-color: #f7f7f7;
`; */
