import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Config from 'config/Config';

export interface StripeCustomer {
  stripeCustomerId: string;
  name: string;
  email: string;
  id: string;
}

interface StripeStatus {
  id: string;
  amount: number;
  currency: string;
  stripePaymentIntentId: string | null;
  status: string;
  event_id: string;
  eventType: string;
  subscriptionId: string;
  product: string;
  product_ds: string;
  nickname: string;
  price_id: string;
  startDate: string;
  endDate: string;
  trialStart: string;
  trialEnd: string;
  trialPeriodDays: number;
  licenseValidity: string | null;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  userId: string;
  clinicaId: string;
}

interface User {
  id_users: string;
  nome_usuario: string;
  email_usuario: string;
  url_imagem: string;
  status_usuario: string;
  privilegio_usuario: string;
  id_clinica: string;
  stripeCustomer?: StripeCustomer;
  stripeStatus?: StripeStatus;
}

interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  token: string | null;
  setToken: (token: string | null) => void;
  userData: User | null;
  setUserData: (userData: User | null) => void;
  emailVerified: boolean;
  setEmailVerified: (emailVerified: boolean) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [userData, setUserData] = useState<User | null>(null);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);

  const logout = useCallback(async () => {
    try {
      if (token) {
        await axios.post(`${Config.API_URL}/api/token/logout`, { token });
      }
    } catch (error) {
      console.error('Erro durante logout:', error);
    } finally {
      await AsyncStorage.removeItem('TOKEN');
      setToken(null);
      setIsAuthenticated(false);
      setUserData(null);
      setEmailVerified(false);
    }
  }, [token]);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const savedToken = await AsyncStorage.getItem('TOKEN');
        if (savedToken) {
          setToken(savedToken);
          setIsAuthenticated(true);
          const response = await axios.get(`${Config.API_URL}/api/users/profile`, {
            headers: {
              Authorization: `Bearer ${savedToken}`,
            },
          });

          if (response && response.data) {
            setUserData(response.data);
            setEmailVerified(response.data.status_usuario.toLowerCase() === 'ativo');
          } else {
            throw new Error('Perfil do usuário não encontrado');
          }
        }
      } catch (error) {
        console.error('Erro ao buscar perfil do usuário:', error);
        logout();
      }
    };
    checkToken();
  }, [logout]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, token, setToken, userData, setUserData, emailVerified, setEmailVerified, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
