import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Nunito', sans-serif;
    background-color: ${({ theme }) => theme.colors.background.light};
    color: ${({ theme }) => theme.colors.text.light};
    margin: 0;
    padding: 0;
    transition: all 0.50s linear;
  }
  body.dark-theme {
    background-color: ${({ theme }) => theme.colors.background.dark};
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;

export default GlobalStyles;
