import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FaEdit } from 'react-icons/fa';
import { useToast } from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import ModalQuadroHorarios from 'components/ModalQuadroHorarios';
import { fetchConveniosByClinica, updateConvenio, Convenio } from 'services/conveniosService';
import { MultiValue } from 'react-select';
import QDAtendimentoService from 'services/qdatendimentoService';

export interface Tag {
  id: number;
  label: string;
  color: string;
  convenioId: number;
  id_profissional: number;
}

interface TagPickerConveniosProps {
  selectedTags: Tag[];
  onTagSelect: (tag: Tag) => void;
  onTagDeselect: (tag: Tag) => void;
  isEditable: boolean;
  clinicaId: string;
  id_profissional: number;
  isProfissionaisModule?: boolean;
}

const TagPickerContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
`;

const TagItem = styled.span<{ color: string }>`
  padding: 5px 10px;
  background-color: ${({ color }) => color};
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  position: relative;
  cursor: pointer;
`;

const RemoveIcon = styled.span`
  margin-left: 8px;
  cursor: pointer;
`;

const TagPickerInput = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 12px;
`;

const TagDropdown = styled.div`
  width: 200px;
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  font-size: 10px;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  scrollbar-width: none;
`;

const TagDropdownItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TagDropdownItem = styled.div<{ color: string }>`
  padding: 5px 10px;
  background-color: ${({ color }) => color};
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin: 2px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
  font-size: 12px;
  width: fit-content;
`;

const EditableTagInput = styled.input`
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  font-size: 10px;
  flex-grow: 1;
`;

const TagPickerConvenios: React.FC<TagPickerConveniosProps> = ({
  selectedTags,
  onTagSelect,
  onTagDeselect,
  isEditable,
  clinicaId,
  id_profissional,
  isProfissionaisModule
}) => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [editingTag, setEditingTag] = useState<Tag | null>(null);
  const [editedLabel, setEditedLabel] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const toast = useToast();

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const convenios = await fetchConveniosByClinica(clinicaId);
        setTags(convenios.map(convenio => ({
          id: convenio.id_convenio,
          label: convenio.ds_convenio,
          color: convenio.cor,
          convenioId: convenio.id_convenio,
          id_profissional: id_profissional,
        })));
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };
    fetchTags();
  }, [clinicaId, id_profissional]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
        setInputValue('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTagClick = async (tag: Tag, isFromTagList: boolean) => {
    if (isProfissionaisModule && isFromTagList) {
      const quadroHorarios = await QDAtendimentoService.findByConvenioAndProfissional(
        tag.convenioId,
        id_profissional
      );
      setSelectedTag(tag);
      setIsModalOpen(true);
      if (quadroHorarios.length === 0) {
        toast({
          title: "Aviso",
          description: `O convênio ${tag.label} não tem um quadro de horários associado. Configure-o agora.`,
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      if (!selectedTags.some(selectedTag => selectedTag.label === tag.label)) {
        onTagSelect({ ...tag, id_profissional });
      }
      setInputValue('');
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const handleEditClick = (tag: Tag, e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    setEditingTag(tag);
    setEditedLabel(tag.label);
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const capitalizedValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setEditedLabel(capitalizedValue);
  };

  const handleEditSave = async () => {
    if (editingTag) {
      try {
        const updatedTag: Convenio = {
          id_convenio: editingTag.id,
          ds_convenio: editedLabel,
          cor: editingTag.color,
          id_clinica: clinicaId,
        };
        await updateConvenio(updatedTag.id_convenio.toString(), updatedTag);
        setTags(tags.map(tag => (tag.id === updatedTag.id_convenio ? { id: updatedTag.id_convenio, label: updatedTag.ds_convenio, color: updatedTag.cor, convenioId: updatedTag.id_convenio, id_profissional: tag.id_profissional } : tag)));
        setEditingTag(null);
        setEditedLabel('');
      } catch (error) {
        console.error('Error updating tag:', error);
      }
    }
  };

  const filteredTags = tags.filter(tag =>
    !selectedTags.some(selectedTag => selectedTag.label === tag.label) &&
    tag.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleChange = (selectedOptions: MultiValue<{ value: number; label: string; color: string }>) => {
    const selectedTagsList = selectedOptions.map(option => ({
      id: option.value,
      label: option.label,
      color: option.color,
      convenioId: option.value,
      id_profissional,
    }));

    selectedTagsList.forEach((tag: Tag) => {
      if (!selectedTags.some(st => st.label === tag.label)) {
        onTagSelect(tag);
      }
    });
  };

  const handleRemove = (tag: Tag, event: React.MouseEvent) => {
    event.stopPropagation();
    onTagDeselect(tag);
  };

  return (
    <TagPickerContainer ref={containerRef}>
      <TagList onClick={isEditable ? handleInputFocus : undefined}>
        {selectedTags.map(tag => (
          <TagItem key={tag.id} color={tag.color} onClick={(event) => {
            if (!(event.target as HTMLElement).classList.contains('remove-icon')) {
              handleTagClick(tag, true);
            }
          }}>
            {tag.label}
            {isEditable && <RemoveIcon className="remove-icon" onClick={(event) => handleRemove(tag, event)}>x</RemoveIcon>}
          </TagItem>
        ))}
        {isEditable && (
          <TagPickerInput
            type="text"
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value)}
            onFocus={handleInputFocus}
            placeholder="Pesquisar rótulo"
          />
        )}
      </TagList>
      {showDropdown && isEditable && (
        <TagDropdown>
          {filteredTags.map(tag => (
            <TagDropdownItemContainer key={tag.id}>
              <TagDropdownItem
                color={tag.color}
                onClick={() => handleTagClick(tag, false)}
              >
                {tag.label}
              </TagDropdownItem>
              {isEditable && <FaEdit onClick={(e) => handleEditClick(tag, e)} />}
            </TagDropdownItemContainer>
          ))}
        </TagDropdown>
      )}
      {editingTag && (
        <div>
          <EditableTagInput
            type="text"
            value={editedLabel}
            onChange={handleEditChange}
          />
          <CustomButton text="Salvar" onClick={handleEditSave} />
        </div>
      )}
      <ModalQuadroHorarios
        open={isModalOpen}
        handleClose={handleModalClose}
        tag={selectedTag}
        id_profissional={selectedTag?.id_profissional}
        convenioId={selectedTag?.convenioId}
      />
    </TagPickerContainer>
  );
};

export default TagPickerConvenios;