import React, { useState } from 'react';
import { Box, List, ListItem, Icon, Text } from '@chakra-ui/react';
import { FaUser, FaShieldAlt, FaQuestionCircle, FaExclamationCircle } from 'react-icons/fa';
import styled from 'styled-components';
import EditProfileModal from '../EditProfileModal';
import ChangePasswordModal from 'components/ChangePasswordModal';
import ReportProblemModal from 'components/ReportProblemModal';

const StyledListItem = styled(ListItem)`
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

interface SettingsMenuProps {
  setModalOpen: (open: boolean) => void;
  onUserDataUpdate: (userData: UserData) => void;
  setSettingsOpen: (open: boolean) => void;
  handleMenuClick: (path: string) => void;
}

interface UserData {
  id_users: string;
  nome_usuario: string;
  email_usuario: string;
  url_imagem: string;
  status_usuario: string;
  privilegio_usuario: string;
  id_clinica: string;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({ setModalOpen, onUserDataUpdate, setSettingsOpen, handleMenuClick }) => {
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [isReportProblemModalOpen, setIsReportProblemModalOpen] = useState(false);

  const handleEditProfile = () => {
    setIsEditProfileModalOpen(true);
    setModalOpen(true);
  };

  const handleSecurity = () => {
    setIsChangePasswordModalOpen(true);
    setModalOpen(true);
  };

  const handleReportProblem = () => {
    setIsReportProblemModalOpen(true);
    setModalOpen(true);
  };

  const handleCloseEditProfileModal = (updatedUserData?: UserData) => {
    setIsEditProfileModalOpen(false);
    setModalOpen(false);
    setSettingsOpen(false);
    if (updatedUserData) {
      onUserDataUpdate(updatedUserData);
    }
  };

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
    setModalOpen(false);
    setSettingsOpen(false);
  };

  const handleCloseReportProblemModal = () => {
    setIsReportProblemModalOpen(false);
    setModalOpen(false);
    setSettingsOpen(false);
  };

  const handleHelpSupportClick = () => {
    handleMenuClick('/ajuda');
    setSettingsOpen(false);
  };

  return (
    <>
      <Box p="4" background="white" borderRadius="md" boxShadow="md">
        <Box mb="4">
          <Text fontWeight="bold" mb="2">Conta</Text>
          <List spacing={3}>
            <StyledListItem onClick={handleEditProfile}>
              <Icon as={FaUser} mr="2" />
              Editar perfil
            </StyledListItem>
            <StyledListItem onClick={handleSecurity}>
              <Icon as={FaShieldAlt} mr="2" />
              Segurança
            </StyledListItem>
          </List>
        </Box>

        <Box mb="4">
          <Text fontWeight="bold" mb="2">Suporte & Sobre</Text>
          <List spacing={3}>
            <StyledListItem onClick={handleHelpSupportClick}>
              <Icon as={FaQuestionCircle} mr="2" />
              Ajuda & Suporte
            </StyledListItem>
          </List>
        </Box>

        <Box>
          <Text fontWeight="bold" mb="2">Ações</Text>
          <List spacing={3}>
            <StyledListItem onClick={handleReportProblem}>
              <Icon as={FaExclamationCircle} mr="2" />
              Reportar um problema
            </StyledListItem>
          </List>
        </Box>
      </Box>
      <EditProfileModal isOpen={isEditProfileModalOpen} onClose={handleCloseEditProfileModal} onUserDataUpdate={onUserDataUpdate} />
      <ChangePasswordModal isOpen={isChangePasswordModalOpen} onClose={handleCloseChangePasswordModal} />
      <ReportProblemModal isOpen={isReportProblemModalOpen} onClose={handleCloseReportProblemModal} />
    </>
  );
};

export default SettingsMenu;