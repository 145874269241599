import React, { useState, useEffect, useCallback } from 'react';
import {
  UsersContainer,
  SearchInput,
  SidebarContainer,
  RightContainer,
  Flag,
  FiltersWrapper,
  Footer,
  RadioWrapper,
  RadioLabel,
  ProfileImage,
  UserListContainer,
  UserCard,
  UserInfo,
  UserName,
  UserRole,
  StyledInputLeftElement,
  SidebarToggleButton
} from './UsersModule.styles';
import {
  getUsersByClinicId,
  createUser,
  updateUser,
  getUserById,
  User
} from 'services/userService';
import { FaCircle, FaCheckCircle, FaTimesCircle, FaSearch, FaChevronLeft, FaChevronRight, FaUserPlus } from 'react-icons/fa';
import Config from 'config/Config';
import UserProfile from 'components/UserProfile';
import { useAuth } from 'context/authContext';
import { useToast, Tooltip, HStack, Text } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
// Import AddUserIconButton from UsersModule.styles
import { AddUserIconButton } from './UsersModule.styles';
const API_URL = Config.API_URL;

const UsersModule: React.FC = () => {
  const { userData } = useAuth();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('ativo');
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // New state for sidebar toggle
  const toast = useToast();

  const loadUsers = useCallback(async () => {
    try {
      if (!userData?.id_clinica) {
        throw new Error('ID da clínica não encontrado.');
      }
      const data = await getUsersByClinicId(userData.id_clinica);
      data.sort((a: User, b: User) => a.nome_usuario.localeCompare(b.nome_usuario));
      setUsers(data);
      setSelectedUserId(data[0]?.id_users || null);
    } catch (error) {
      console.error('Failed to load users', error);
      toast({
        title: "Erro",
        description: "Falha ao carregar usuários. Veja detalhes no console.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [userData, toast]);

  useEffect(() => {
    if (userData) {
      loadUsers();
    }
  }, [userData, loadUsers]);

  const handleAddUser = () => {
    if (!userData?.id_clinica) {
      toast({
        title: "Erro",
        description: "ID da clínica não encontrado.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setSelectedUserId(null);
    setIsEditing(true);
    setIsAdding(true);
  };

  const handleSaveUser = async (user: Partial<User>) => {
    try {
      const isAdmin = user.privilegio_usuario === 'ADMINISTRADOR';
      const adminUsers = users.filter(u => u.privilegio_usuario === 'ADMINISTRADOR');

      if (!isAdmin && adminUsers.length === 1 && adminUsers[0].id_users === selectedUserId) {
        toast({
          title: 'Erro',
          description: 'É obrigatório ter pelo menos um usuário administrador.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (isAdding) {
        const newUser = await createUser({ ...user, clinica: userData?.id_clinica });
        setUsers([...users, newUser]);
      } else if (selectedUserId) {
        const updatedUser = await updateUser(selectedUserId, user as User);
        setUsers(users.map((u) => u.id_users === updatedUser.id_users ? updatedUser : u));
      }
      setIsEditing(false);
      setIsAdding(false);
      setSelectedUserId(users[0]?.id_users || null);
      loadUsers();
    } catch (error) {
      console.error('Failed to save user', error);
      toast({
        title: "Erro",
        description: "Falha ao salvar usuário. Veja detalhes no console.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCancelEdit = async () => {
    setIsEditing(false);
    setIsAdding(false);
    if (selectedUserId) {
      try {
        const userData = await getUserById(selectedUserId);
        setUsers((prevUsers) =>
          prevUsers.map((u) => (u.id_users === userData.id_users ? userData : u))
        );
        setSelectedUserId(userData.id_users);
      } catch (error) {
        console.error('Failed to refresh user', error);
      }
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const filteredUsers = users
    .filter((user) => user.nome_usuario.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((user) => filterStatus === 'todos' ? true : user.status_usuario.toLowerCase() === filterStatus.toLowerCase());

    return (
      <UsersContainer>
        <SidebarContainer isOpen={isSidebarOpen}>
          <SidebarToggleButton onClick={toggleSidebar} isOpen={isSidebarOpen}>
            {isSidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
          </SidebarToggleButton>
          {isSidebarOpen && (
            <>
              <FiltersWrapper>
                <SearchInput>
                  <StyledInputLeftElement>
                    <FaSearch />
                  </StyledInputLeftElement>
                  <input
                    type="text"
                    placeholder="Pesquisar por nome"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </SearchInput>
                <RadioWrapper>
                  <Tooltip label="Todos">
                    <RadioLabel>
                      <input
                        type="radio"
                        name="status"
                        value="todos"
                        checked={filterStatus === 'todos'}
                        onChange={(e) => setFilterStatus(e.target.value.toLowerCase())}
                      />
                      <FaCircle />
                    </RadioLabel>
                  </Tooltip>
                  <Tooltip label="Ativo">
                    <RadioLabel>
                      <input
                        type="radio"
                        name="status"
                        value="ativo"
                        checked={filterStatus === 'ativo'}
                        onChange={(e) => setFilterStatus(e.target.value.toLowerCase())}
                      />
                      <FaCheckCircle />
                    </RadioLabel>
                  </Tooltip>
                  <Tooltip label="Inativo">
                    <RadioLabel>
                      <input
                        type="radio"
                        name="status"
                        value="inativo"
                        checked={filterStatus === 'inativo'}
                        onChange={(e) => setFilterStatus(e.target.value.toLowerCase())}
                      />
                      <FaTimesCircle />
                    </RadioLabel>
                  </Tooltip>
                </RadioWrapper>
                <Tooltip label="Adicionar usuário" placement="right">
                  <AddUserIconButton onClick={handleAddUser}>
                    <FaUserPlus />
                  </AddUserIconButton>
                </Tooltip>
              </FiltersWrapper>
              <UserListContainer>
                <AnimatePresence>
                  {filteredUsers.map((user, index) => (
                    <motion.div
                      key={user.id_users}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.2, delay: index * 0.05 }}
                    >
                      <UserCard
                        onClick={() => setSelectedUserId(user.id_users)}
                        isSelected={selectedUserId === user.id_users}
                      >
                        <HStack spacing={4}>
                          <ProfileImage
                            src={user.url_imagem ? `${API_URL}${user.url_imagem}` : '/perfil_default.jpeg'}
                            alt="Imagem de Perfil"
                          />
                          <UserInfo>
                            <UserName>{user.nome_usuario}</UserName>
                            <UserRole>{user.privilegio_usuario}</UserRole>
                          </UserInfo>
                        </HStack>
                        <Flag $status={user.status_usuario.toLowerCase()} />
                      </UserCard>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </UserListContainer>
              <Footer>
                <Text fontSize="sm" color="gray.500">Total de usuários: {filteredUsers.length}</Text>
              </Footer>
            </>
          )}
        </SidebarContainer>
        <RightContainer>
          {selectedUserId && !isAdding && (
            <UserProfile
              id_user={selectedUserId}
              onCancel={handleCancelEdit}
              onSave={handleSaveUser}
              isAdding={false}
              updateUsersList={loadUsers}
            />
          )}
          {isAdding && (
            <UserProfile
              id_user=""
              onCancel={handleCancelEdit}
              onSave={handleSaveUser}
              isAdding={true}
              updateUsersList={loadUsers}
            />
          )}
        </RightContainer>
      </UsersContainer>
    );
  };
  
  export default UsersModule;