import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  Heading,
  Text,
  Image,
  useToast,
  Link,
  VStack,
  Grid,
  GridItem,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { passwordRegex } from 'helpers/regex.helper';
import { register } from 'services/userService';
import minnerIcon from '../../images/minner-logov1.png';

const Register: React.FC = () => {
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerName, setRegisterName] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [formError, setFormError] = useState('');
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (formError || passwordError) {
      const timer = setTimeout(() => {
        setFormError('');
        setPasswordError('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [formError, passwordError]);

  const validatePassword = (password: string) => {
    return passwordRegex.test(password);
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!registerEmail || !registerName || !registerPassword || !confirmPassword) {
      setFormError('Todos os campos são obrigatórios.');
      return;
    }
    if (registerPassword !== confirmPassword) {
      setPasswordError('As senhas não são iguais.');
      return;
    }
    if (!validatePassword(registerPassword)) {
      setPasswordError('A senha deve ter no mínimo 8 caracteres, incluindo pelo menos uma letra maiúscula e um caractere especial.');
      return;
    }

    try {
      const data = {
        email_usuario: registerEmail,
        nome_usuario: registerName,
        senha_usuario: registerPassword,
        privilegio_usuario: 'ADMINISTRADOR',
        status_usuario: 'ATIVO',
      };
      await register(data);

      toast({
        title: 'Registro bem-sucedido!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      navigate('/login');
    } catch (error: any) {
      console.error('Erro durante registro:', error);
      toast({
        title: 'Erro no registro.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(135deg, #8DA4CC 0%, #6F87B5 50%, #4A5E80 100%)"
    >
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(1, 1fr)' }}
        gap={0}
        w="100%"
        maxW="400px"
        bg="white"
        boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
        borderRadius="8px"
        overflow="hidden"
      >
        <GridItem bg="#f7f8fc" p={8}>
          <VStack spacing={6} align="stretch">
            <Box>
              <Image src={minnerIcon} alt="Logotipo" mx="auto" mb={4} maxW="150px" />
            </Box>
            <Box textAlign="center">
              <Heading as="h2" size="xl" mb={2}>Crie sua conta</Heading>
              <Text color="gray.500">Preencha os campos abaixo para registrar-se</Text>
            </Box>
            <Box as="form" onSubmit={handleRegister}>
              <VStack spacing={4}>
                <FormControl isInvalid={!!formError}>
                  <Input
                    type="text"
                    placeholder="Nome"
                    value={registerName}
                    onChange={(e) => setRegisterName(e.target.value)}
                    autoComplete="name"
                  />
                </FormControl>
                <FormControl isInvalid={!!formError}>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={registerEmail}
                    onChange={(e) => setRegisterEmail(e.target.value)}
                    autoComplete="email"
                  />
                </FormControl>
                <FormControl isInvalid={!!passwordError}>
                  <Input
                    type="password"
                    placeholder="Senha"
                    value={registerPassword}
                    onChange={(e) => setRegisterPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                </FormControl>
                <FormControl isInvalid={!!passwordError}>
                  <Input
                    type="password"
                    placeholder="Confirme a senha"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                  <FormErrorMessage>{passwordError || formError}</FormErrorMessage>
                </FormControl>
                <Button colorScheme="blue" type="submit" width="100%">
                  Registrar
                </Button>
              </VStack>
            </Box>
            <Text textAlign="center">
              <Link color="blue.500" onClick={() => navigate('/login')}>
                Já sou registrado
              </Link>
            </Text>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Register;