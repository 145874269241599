import styled from 'styled-components';
import React from 'react';
import {
  FaEdit,
  FaSave,
  FaTimes,
  FaInfoCircle,
  FaSmile,
  FaClock,
  FaMapMarkerAlt,
} from 'react-icons/fa';
import { IconType } from 'react-icons';

export const ClinicaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

export const TopContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const BottomContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const ClinicName = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

export const FormField = styled.div`
  flex: 1;
  min-width: 200px;
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 500;
  color: #555;
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #4a90e2;
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

export const ErrorMessage = styled.span`
  color: #e74c3c;
  font-size: 12px;
  margin-top: 5px;
`;

export const ControlButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

export const SectionTitle = styled.h3`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  border-bottom: 2px solid #4a90e2;
  padding-bottom: 5px;
`;

export const EditIcon = styled(FaEdit)`
  margin-right: 5px;
`;

export const SaveIcon = styled(FaSave)`
  margin-right: 5px;
`;

export const CancelIcon = styled(FaTimes)`
  margin-right: 5px;
`;

export const StepIndicator = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Step = styled.div<{ isActive: boolean }>`
  flex: 1;
  text-align: center;
  position: relative;
  color: ${(props) => (props.isActive ? '#4a90e2' : '#ccc')};

  &::after {
    content: '';
    height: 2px;
    background-color: ${(props) => (props.isActive ? '#4a90e2' : '#ccc')};
    position: absolute;
    width: 100%;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:last-child::after {
    display: none;
  }
`;

export const StepTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const icons: Record<string, IconType> = {
  info: FaInfoCircle,
  smile: FaSmile,
  clock: FaClock,
  'map-pin': FaMapMarkerAlt,
};

interface SectionIconProps {
  name: keyof typeof icons;
}

const UnstyledSectionIcon: React.FC<SectionIconProps & React.SVGProps<SVGSVGElement>> = ({ name,...props }) => {
  const IconComponent = icons[name] || FaInfoCircle;
  return React.createElement(IconComponent, props);
};

export const SectionIcon = styled(UnstyledSectionIcon)`
  margin-right: 8px;
  color: #4a90e2;
`;



export const WelcomePreview = styled.div`
  margin-top: 20px;
`;

export const ChatBubble = styled.div`
  background-color: #e0f7fa;
  color: #00796b;
  padding: 15px;
  border-radius: 15px;
  position: relative;
  max-width: 300px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 30px;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: #e0f7fa;
    border-bottom: 0;
    margin-left: -15px;
    margin-bottom: -15px;
  }
`;
