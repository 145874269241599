import React, { useEffect } from "react";
import { Box, Flex, Heading, Text, VStack, Divider, Button, Link as ChakraLink, useColorMode, useColorModeValue } from "@chakra-ui/react";
import Header from "components/Header";
import { Card, CardHeader, CardBody } from "@chakra-ui/card";
import { Link } from "react-router-dom";
import {
  DefaultPageContainer,
  ContentContainer,
  LeftContainer,
} from "../../styles/DefaultPage.styles";

const Success: React.FC = () => {
  const { toggleColorMode } = useColorMode();
  const currentTheme = useColorModeValue("light", "dark");

  useEffect(() => {
    //console.log("Payment page loaded");
  }, []);

  return (
    <>
      <Header theme={currentTheme} toggleTheme={toggleColorMode} />
      <DefaultPageContainer>
        <ContentContainer>
          <LeftContainer>
            <Flex
              minH="100vh"
              align="center"
              justify="center"
              bg="gray.100"
              px={{ base: 4, sm: 6, lg: 8 }}
              py={12}
            >
              <Box maxW="md" mx="auto" textAlign="center">
                <Box mb={6}>
                  <CircleCheckIcon style={{ width: "64px", height: "64px", color: "green" }} />
                </Box>
                <Heading as="h1" size="xl" fontWeight="bold" mb={4}>
                  Thank you for your order!
                </Heading>
                <Text color="gray.500" mb={6}>
                  We appreciate your business and look forward to serving you again.
                </Text>
                <VStack spacing={4}>
                  <Card>
                    <CardHeader>
                      <Heading as="h2" size="md">
                        Order Summary
                      </Heading>
                    </CardHeader>
                    <CardBody>
                      <VStack spacing={4}>
                        <Flex justify="space-between">
                          <Text>Order #</Text>
                          <Text fontWeight="medium">12345</Text>
                        </Flex>
                        <Divider />
                        <VStack spacing={2} w="full">
                          <Flex justify="space-between">
                            <Text>Items</Text>
                            <Text fontWeight="medium">3</Text>
                          </Flex>
                          <Flex justify="space-between">
                            <Text>Total</Text>
                            <Text fontWeight="medium">$150.00</Text>
                          </Flex>
                        </VStack>
                      </VStack>
                    </CardBody>
                  </Card>
                  <Box textAlign="center">
                    <ChakraLink as={Link} to="#" _hover={{ textDecoration: 'none' }}>
                      <Button
                        colorScheme="blue"
                        size="sm"
                        px={4}
                        py={2}
                        fontWeight="medium"
                        shadow="sm"
                      >
                        View Order History
                      </Button>
                    </ChakraLink>
                  </Box>
                </VStack>
              </Box>
            </Flex>
          </LeftContainer>
        </ContentContainer>
      </DefaultPageContainer>
    </>
  );

  function CircleCheckIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      >
        <circle cx="12" cy="12" r="10" />
        <path d="m9 12 2 2 4-4" />
      </svg>
    );
  }
};

export default Success;
