import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;

export interface Convenio {
  id_convenio: number;
  ds_convenio: string;
  cor: string;
  id_clinica: string;
}

// Função para buscar todos os convênios de uma clínica específica
export const fetchConveniosByClinica = async (id_clinica: string): Promise<Convenio[]> => {
  if (!id_clinica) {
   // console.error('ID da clínica não fornecido.');
    throw new Error('ID da clínica não fornecido no logout - OK.');
  }
  try {
    const response = await axios.get<Convenio[]>(`${API_URL}/api/convenios/clinica/${id_clinica}`);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error('Error fetching convenios:', error.message, error.response?.data);
    } else {
      console.error('Unexpected error fetching convenios:', error);
    }
    throw new Error('Failed to fetch convenios.');
  }
};

// Função para buscar um convênio por ID
export const fetchConvenioById = async (id: string): Promise<Convenio> => {
  try {
    const response = await axios.get<Convenio>(`${API_URL}/api/convenios/${id}`);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error(`Error fetching convenio with id ${id}:`, error.message, error.response?.data);
    } else {
      console.error(`Unexpected error fetching convenio with id ${id}:`, error);
    }
    throw new Error('Failed to fetch convenio.');
  }
};

// Função para atualizar um convênio
export const updateConvenio = async (id: string, convenio: Partial<Convenio>): Promise<Convenio> => {
  try {
    const cleanedConvenio = removeDuplicateConvenios([convenio as Convenio]);
    if (cleanedConvenio.length === 0) {
      throw new Error('Invalid convenio data.');
    }
    const response = await axios.patch<Convenio>(`${API_URL}/api/convenios/${id}`, cleanedConvenio[0]);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error(`Error updating convenio with id ${id}:`, error.message, error.response?.data);
    } else {
      console.error(`Unexpected error updating convenio with id ${id}:`, error);
    }
    throw new Error('Failed to update convenio.');
  }
};

// util.ts (novo arquivo)
export const removeDuplicateConvenios = (convenios: Convenio[]): Convenio[] => {
  const uniqueConvenios = convenios.reduce((acc, current) => {
    const exists = acc.find(item => item.ds_convenio === current.ds_convenio);
    if (!exists) {
      acc.push(current);
    }
    return acc;
  }, [] as Convenio[]);
  return uniqueConvenios;
};
