import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, Box, Text } from '@chakra-ui/react';
import { FaSave } from 'react-icons/fa';
import styled from 'styled-components';
import CustomButton from 'components/CustomButton';
import { passwordRegex } from 'helpers/regex.helper';
import { updateUser } from 'services/userService';
import { useAuth } from 'context/authContext';

const ErrorText = styled(Text)`
  color: red;
`;

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ isOpen, onClose }) => {
  const { userData } = useAuth();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSave = async () => {
    if (newPassword !== confirmPassword) {
      setError('As senhas não coincidem.');
      return;
    }

    if (!passwordRegex.test(newPassword)) {
      setError('A senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um caractere especial.');
      return;
    }

    if (userData?.id_users) {
      try {
        await updateUser(userData.id_users, { senha_usuario: newPassword });
        onClose();
      } catch (err) {
        setError('Erro ao alterar a senha. Tente novamente.');
      }
    } else {
      setError('Usuário não encontrado.');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'newPassword') {
      setNewPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
    setError('');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alterar Senha</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Text>Nova Senha</Text>
            <Input
              type="password"
              name="newPassword"
              value={newPassword}
              onChange={handleChange}
            />
          </Box>
          <Box mb={4}>
            <Text>Confirmar Nova Senha</Text>
            <Input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleChange}
            />
          </Box>
          {error && <ErrorText>{error}</ErrorText>}
        </ModalBody>
        <ModalFooter>
          <CustomButton leftIcon={<FaSave />} onClick={handleSave} text="Salvar" />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
