import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;

export const requestPasswordReset = async (email: string) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/forgot-password`, { email });
    return response.data;
  } catch (error) {
    console.error('Error requesting password reset:', error);
    throw new Error('Failed to request password reset.');
  }
};

export const resetPassword = async (token: string, newPassword: string) => {
  try {
    const response = await axios.post(`${API_URL}/api/auth/reset-password`, { token, newPassword });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw new Error('Failed to reset password.');
  }
};
