import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  HStack,
  VStack,
  Input,
  IconButton,
  Button,
  Flex
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import axios from 'axios';
import QDAtendimentoService from 'services/qdatendimentoService';

const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

interface Schedule {
  workday: {
    start: string;
    end: string;
  };
  interval: {
    start: string;
    end: string;
  };
}

interface DaySchedule {
  day: string;
  schedules: Schedule[];
}

interface Tag {
  id: number;
  label: string;
  color: string;
}

interface ModalQuadroHorariosProps {
  open: boolean;
  handleClose: () => void;
  tag: Tag | null;
  id_profissional?: number;
  convenioId?: number;
  onSave?: () => void;
}

const defaultSchedule: DaySchedule[] = daysOfWeek.map(day => ({
  day,
  schedules: day === 'Dom' || day === 'Sab' ? [] : [{
    workday: { start: '07:00', end: '18:00' },
    interval: { start: '12:00', end: '13:00' }
  }]
}));

const ModalQuadroHorarios: React.FC<ModalQuadroHorariosProps> = ({ open, handleClose, tag, id_profissional, convenioId, onSave }) => {
  const [schedule, setSchedule] = useState<DaySchedule[]>(defaultSchedule);

  useEffect(() => {
    const fetchData = async () => {
      if (id_profissional !== undefined && convenioId !== undefined) {
        try {
          const data = await QDAtendimentoService.findByConvenioAndProfissional(convenioId, id_profissional);
          if (data.length > 0 && data[0].quadro) {
            const fetchedSchedule = data[0].quadro;
            const orderedSchedule: DaySchedule[] = daysOfWeek.map(day => ({
              day,
              schedules: fetchedSchedule[day] || []
            }));
            setSchedule(orderedSchedule);
          } else {
            setSchedule(defaultSchedule);
          }
        } catch (error: unknown) {
          if (axios.isAxiosError(error) && error.response && error.response.status === 404) {
            setSchedule(defaultSchedule);
          } else {
            console.error('Error fetching schedule:', error);
          }
        }
      }
    };

    if (open) {
      fetchData();
    }
  }, [open, id_profissional, convenioId]);

  const handleTimeChange = (dayIndex: number, scheduleIndex: number, type: 'workday' | 'interval', subType: 'start' | 'end', value: string) => {
    const newSchedule = [...schedule];
    newSchedule[dayIndex].schedules[scheduleIndex][type][subType] = value;
    setSchedule(newSchedule);
  };

  const addTimeSlot = (dayIndex: number) => {
    const newSchedule = [...schedule];
    newSchedule[dayIndex].schedules.push({ workday: { start: '07:00', end: '18:00' }, interval: { start: '12:00', end: '13:00' } });
    setSchedule(newSchedule);
  };

  const removeTimeSlot = (dayIndex: number, scheduleIndex: number) => {
    const newSchedule = [...schedule];
    newSchedule[dayIndex].schedules.splice(scheduleIndex, 1);
    setSchedule(newSchedule);
  };

  const timeInputStyles = {
    '::-webkit-calendar-picker-indicator': {
      display: 'none'
    }
  };

  const handleSave = async () => {
    if (id_profissional !== undefined && convenioId !== undefined) {
      try {
        const payload = {
          quadro: Object.fromEntries(schedule.map(daySchedule => [daySchedule.day, daySchedule.schedules])),
          profissionalId: id_profissional,
          convenioId: convenioId
        };

        await QDAtendimentoService.saveOrUpdate(payload);
        if (onSave) {
          onSave();
        }
        handleClose();
      } catch (error) {
        console.error('Error saving schedule:', error);
      }
    } else {
      console.error('id_profissional or convenioId is undefined');
    }
  };

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg={tag?.color} color="white">
          {tag?.label}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text fontSize="lg" mb={4}>Quadro de horários</Text>
            <VStack align="start">
              {schedule.map((daySchedule, dayIndex) => (
                <Box key={daySchedule.day} mb={4}>
                  <HStack align="center">
                    <Text fontSize="md" minW="60px">{daySchedule.day}</Text>
                    {daySchedule.schedules.length === 0 ? (
                      <Text fontSize="md">Indisponível</Text>
                    ) : (
                      <>
                        <Input
                          size="sm"
                          type="time"
                          value={daySchedule.schedules[0].workday.start}
                          onChange={(e) => handleTimeChange(dayIndex, 0, 'workday', 'start', e.target.value)}
                          width="95px"
                          css={timeInputStyles}
                        />
                        <Text textAlign="center">-</Text>
                        <Input
                          size="sm"
                          type="time"
                          value={daySchedule.schedules[0].workday.end}
                          onChange={(e) => handleTimeChange(dayIndex, 0, 'workday', 'end', e.target.value)}
                          width="95px"
                          css={timeInputStyles}
                        />
                        <IconButton
                          icon={<MinusIcon />}
                          onClick={() => removeTimeSlot(dayIndex, 0)}
                          aria-label="Remover intervalo de tempo"
                          size="sm"
                        />
                      </>
                    )}
                    {daySchedule.schedules.length === 0 && (
                      <IconButton
                        icon={<AddIcon />}
                        onClick={() => addTimeSlot(dayIndex)}
                        aria-label="Adicionar intervalo de tempo"
                        size="sm"
                      />
                    )}
                  </HStack>
                  {daySchedule.schedules.length > 0 && (
                    <HStack ml="0px" mt={2}>
                      <Text fontSize="sm">Intervalo:</Text>
                      <Input
                        size="sm"
                        type="time"
                        value={daySchedule.schedules[0].interval.start}
                        onChange={(e) => handleTimeChange(dayIndex, 0, 'interval', 'start', e.target.value)}
                        width="95px"
                        css={timeInputStyles}
                      />
                      <Text textAlign="center">-</Text>
                      <Input
                        size="sm"
                        type="time"
                        value={daySchedule.schedules[0].interval.end}
                        onChange={(e) => handleTimeChange(dayIndex, 0, 'interval', 'end', e.target.value)}
                        width="95px"
                        css={timeInputStyles}
                      />
                    </HStack>
                  )}
                </Box>
              ))}
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <Button colorScheme="gray" mr={3} onClick={handleClose}>
              Fechar
            </Button>
            <Button colorScheme="blue" onClick={handleSave}>Salvar</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalQuadroHorarios;