import { requestPasswordReset } from '../../services/authService';
import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  Heading,
  Text,
  Link,
  Image,
  VStack,
  Grid,
  GridItem,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import minnerIcon from '../../images/minner-logov1.png';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const toast = useToast();

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
  
    try {
      await requestPasswordReset(email);
      toast({
        title: 'Email enviado',
        description: 'Verifique sua caixa de entrada para redefinir sua senha.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Falha ao enviar email de recuperação de senha.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(135deg, #8DA4CC 0%, #6F87B5 50%, #4A5E80 100%)"
    >
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(1, 1fr)' }}
        gap={0}
        w="100%"
        maxW="400px"
        bg="white"
        boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
        borderRadius="8px"
        overflow="hidden"
      >
        
        <GridItem bg="#f7f8fc" p={8}>
          <VStack spacing={6} align="stretch">
            <Box>
              <Image src={minnerIcon} alt="Logotipo" mx="auto" mb={4} maxW="150px" />
            </Box>
            <Box textAlign="center">
              <Heading as="h2" size="xl" mb={2}>Esqueceu a Senha</Heading>
              <Text color="gray.500">Digite seu email abaixo para redefinir sua senha.</Text>
            </Box>
            <Box as="form" onSubmit={handleResetPassword}>
              <VStack spacing={4}>
                <FormControl>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormControl>
                <Button colorScheme="blue" type="submit" width="100%">
                  Redefinir Senha
                </Button>
              </VStack>
            </Box>
            <Text textAlign="center">
              <Link color="blue.500" onClick={() => navigate('/login')}>
                Voltar para o Login
              </Link>
            </Text>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;