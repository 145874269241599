// src/store/alertStore.ts
import create from 'zustand';

interface AlertState {
  alerts: string[];
  addAlert: (alert: string) => void;
  removeAlert: (index: number) => void;
}

export const useAlertStore = create<AlertState>((set) => ({
  alerts: [],
  addAlert: (alert) => set((state) => ({ alerts: [...state.alerts, alert] })),
  removeAlert: (index) => set((state) => ({
    alerts: state.alerts.filter((_, i) => i !== index)
  })),
}));

// Adicione esta linha para garantir que o arquivo seja tratado como um módulo
export {};
