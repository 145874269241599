// src/context/SocketContext.tsx
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAuth } from './authContext';
import Config from '../config/Config';

interface SocketContextProps {
  socket: Socket | null;
}

const SocketContext = createContext<SocketContextProps>({ socket: null });

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userData } = useAuth();
  const [socket, setSocket] = useState<Socket | null>(null);
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    if (userData?.id_clinica && !socketRef.current) {
      console.log('Connecting to socket...');
      const newSocket = io(Config.API_URL || 'https://apiclin.minner.ai', {
        query: { clinical_id: userData.id_clinica },
        withCredentials: true,
        transports: ['websocket', 'polling'],
        path: '/socket.io/',
      });

      newSocket.on('connect', () => {
        console.log('Connected to socket');
      });

      newSocket.on('disconnect', () => {
        console.log('Disconnected from socket');
      });

      // Add your socket event listeners here
      newSocket.on('connectionUpdate', (update: any) => {
        console.log('Received connectionUpdate:', update);
      });

      socketRef.current = newSocket;
      setSocket(newSocket);

      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }
  }, [userData]);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};