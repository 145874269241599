import React, { useEffect } from "react";
import { useColorMode, useColorModeValue,  Box, VStack, Heading, Text,  Center } from "@chakra-ui/react";
import Header from "components/Header";
import {
  DefaultPageContainer,
  ContentContainer,
  LeftContainer,
} from "../../styles/DefaultPage.styles";

const ErrorPage: React.FC = () => {
  const { toggleColorMode } = useColorMode();
  const currentTheme = useColorModeValue("light", "dark");

  useEffect(() => {
    //console.log("Payment page loaded");
  }, []);

  return (
    <>
      <Header theme={currentTheme} toggleTheme={toggleColorMode} />
      <DefaultPageContainer>
        <ContentContainer>
          <LeftContainer>
          <Box minHeight="100vh" bg="gray.50" py={12} px={4}>
      <Center>
        <VStack spacing={6} maxWidth="md" textAlign="center">
          <Box height={12} width={12} color="blue.500" />
          <Heading as="h1" size="xl" fontWeight="bold">
          Ops, algo deu errado!
          </Heading>
          <Text color="gray.600">
          Lamentamos, mas esta página que tentou acessar não está acessível. Por favor, tente navegar utilziando o menu no topo-esquerdo ou entre em contato com o suporte se o problema persistir.
          </Text>
        </VStack>
      </Center>
    </Box>
          </LeftContainer>
        </ContentContainer>
      </DefaultPageContainer>
    </>
  );
  

};

export default ErrorPage;
