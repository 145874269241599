import styled from 'styled-components';
import { Box, useColorModeValue } from '@chakra-ui/react';

export const HeaderContainer = styled.div`
  width: 100vw;
  background-color: ${({ theme }) => useColorModeValue(theme.colors.header.light, theme.colors.header.dark)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
  z-index: 1000; /* Adicionado para garantir que o header tenha um índice z alto */
`;

export const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: 30px;
  gap: 10px;

  img {
    width: 40px;
    height: 40px;
  }

  span {
    font-family: ${({ theme }) => theme.fonts.heading};
    font-size: 21px;
    font-weight: 700;
    color: white;
  }
`;

export const MenuIcon = styled.div`
  cursor: pointer;
`;

export const RightIcons = styled.div`
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-around;
`;

export const Icon = styled.div`
  position: relative;
  margin-left: 20px;
  cursor: pointer;
`;

export const AlertBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;

  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  span {
    font-weight: bold;
    color: #fff;
  }
`;

export const Menu = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: 60px;
  left: 0;
  width: 250px;
  background-color: ${({ theme }) => theme.colors.menu.background.light};
  color: ${({ theme }) => theme.colors.menu.text.light};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  z-index: 1001; /* Garantindo que o menu tenha um índice z maior que o header */
`;

export const MenuItem = styled.div`
  padding: 8px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background-color: #f0f0f0; /* Adicionando o estilo de hover consistente */
  }
`;

export const MenuTitle = styled.div`
  padding: 15px 20px;
  color: #bdc3c7;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
`;

export const SettingsBox = styled(Box)`
  position: absolute;
  top: 60px;
  right: 20px;
  z-index: 1002; /* Garantindo que o SettingsMenu tenha um índice z maior que o menu */
  background-color: ${({ theme }) => theme.colors.menu.background.light};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const TrialContainer = styled.div`
  width: 100%;
  background-color: #C84801;
  color: white;
   padding: 0 20px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

export const TrialTextLeft = styled.div`
  flex: 1;
  text-align: left;
`;

export const TrialTextCenter = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;
