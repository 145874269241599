import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';

export const FormContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
  padding: 16px;
`;

export const LeftContainer = styled(Box)`
  flex: 1;
  flex-direction: column;
  width: 60%;
  padding: 16px;
  border-right: 1px solid #e2e8f0;
`;

export const RightContainer = styled(Box)`
  flex: 0.7;
  flex-direction: column;
  width: 40%;
  padding: 16px;
`;

export const Card = styled(Box)`
  margin-top: 10px;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;

  & > * {
    margin-bottom: 8px;
  }
`;

export const PaymentCard = styled(Box)`
  margin-top: 10px;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;

  & > * {
    margin-bottom: 8px;
  }
`;
