import React, { useState } from 'react';
import {
  ContactsContainer,
  Header,
  SearchInput,
  NewContactButton,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  SaveButton,
  CancelButton,
  DeleteButton,
} from './Contacts.styles';

interface Contact {
  id: number;
  name: string;
  whatsapp: string;
  email: string;
  notes: string;
}

const Contacts: React.FC = () => {
  const [contacts, setContacts] = useState<Contact[]>([
    { id: 1, name: 'Daniel Rocha', whatsapp: '(37) 9 9844-5678', email: 'daniel@dadosinteligentes.com', notes: 'Ligar mais tarde.' }
  ]);
  const [newContact, setNewContact] = useState<Contact>({ id: 0, name: '', whatsapp: '', email: '', notes: '' });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleAddContact = () => {
    setNewContact({ id: contacts.length + 1, name: '', whatsapp: '', email: '', notes: '' });
    setIsEditing(false);
    setIsModalOpen(true);
  };

  const handleSaveContact = () => {
    if (newContact.name.trim() === '' || newContact.whatsapp.trim() === '' || newContact.email.trim() === '' || newContact.notes.trim() === '') {
      alert('Todos os campos são obrigatórios');
      return;
    }

    if (isEditing) {
      setContacts(contacts.map(contact => contact.id === newContact.id ? newContact : contact));
    } else {
      setContacts([...contacts, { ...newContact, id: contacts.length + 1 }]);
    }
    setIsModalOpen(false);
  };

  const handleEditContact = (contact: Contact) => {
    setNewContact(contact);
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleDeleteContact = () => {
    const confirmed = window.confirm('Você tem certeza que deseja deletar este contato?');
    if (confirmed && newContact.id !== null) {
      setContacts(contacts.filter(contact => contact.id !== newContact.id));
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const filteredContacts = contacts.filter(contact =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.whatsapp.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    contact.notes.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <ContactsContainer>
      <Header>Contatos</Header>
      <SearchInput
        type="text"
        placeholder="Pesquisar..."
        value={searchTerm}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
      />
      <NewContactButton onClick={handleAddContact}>Novo Contato</NewContactButton>
      <Table>
        <thead>
          <TableRow>
            <TableHeader>Nome</TableHeader>
            <TableHeader>WhatsApp</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Notas</TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {filteredContacts.map((contact) => (
            <TableRow key={contact.id} onClick={() => handleEditContact(contact)}>
              <TableCell>{contact.name}</TableCell>
              <TableCell>{contact.whatsapp}</TableCell>
              <TableCell>{contact.email}</TableCell>
              <TableCell>{contact.notes}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      {isModalOpen && (
        <Modal>
          <ModalContent>
            <CancelButton onClick={handleCancel}>✖</CancelButton>
            <ModalHeader>{isEditing ? 'Editar Contato' : 'Novo Contato'}</ModalHeader>
            <ModalBody>
              <Input
                type="text"
                placeholder="Nome"
                value={newContact.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewContact({ ...newContact, name: e.target.value })}
              />
              <Input
                type="text"
                placeholder="WhatsApp"
                value={newContact.whatsapp}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewContact({ ...newContact, whatsapp: e.target.value })}
              />
              <Input
                type="email"
                placeholder="Email"
                value={newContact.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewContact({ ...newContact, email: e.target.value })}
              />
              <Input
                type="text"
                placeholder="Notas"
                value={newContact.notes}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewContact({ ...newContact, notes: e.target.value })}
              />
            </ModalBody>
            <ModalFooter>
              {isEditing && <DeleteButton onClick={handleDeleteContact}>Deletar</DeleteButton>}
              <SaveButton onClick={handleSaveContact}>Salvar</SaveButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </ContactsContainer>
  );
};

export default Contacts;
