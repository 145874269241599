import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/authService';
import {
  Box,
  Button,
  FormControl,
  Input,
  Heading,
  Text,
  Link,
  Image,
  VStack,
  Grid,
  GridItem,
  useToast,
  FormErrorMessage,
} from '@chakra-ui/react';
import minnerIcon from '../../images/minner-logov1.png';
import { passwordRegex } from '../../helpers/regex.helper';

const ResetPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const token = searchParams.get('token');

  const validatePassword = (password: string) => {
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setNewPassword(password);
    setIsPasswordValid(validatePassword(password));
    setDoPasswordsMatch(password === confirmPassword);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const confirmPwd = e.target.value;
    setConfirmPassword(confirmPwd);
    setDoPasswordsMatch(newPassword === confirmPwd);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!token) {
      toast({
        title: 'Erro',
        description: 'Token inválido ou ausente.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!isPasswordValid || !doPasswordsMatch) {
      toast({
        title: 'Erro',
        description: 'Por favor, corrija os erros no formulário.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await resetPassword(token, newPassword);
      toast({
        title: 'Senha redefinida',
        description: 'Sua senha foi redefinida com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Falha ao redefinir a senha.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(135deg, #8DA4CC 0%, #6F87B5 50%, #4A5E80 100%)"
    >
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(1, 1fr)' }}
        gap={0}
        w="100%"
        maxW="400px"
        bg="white"
        boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
        borderRadius="8px"
        overflow="hidden"
      >
        <GridItem bg="#f7f8fc" p={8}>
          <VStack spacing={6} align="stretch">
            <Box>
              <Image src={minnerIcon} alt="Logotipo" mx="auto" mb={4} maxW="150px" />
            </Box>
            <Box textAlign="center">
              <Heading as="h2" size="xl" mb={2}>Redefinir Senha</Heading>
              <Text color="gray.500">Digite sua nova senha abaixo.</Text>
            </Box>
            <Box as="form" onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <FormControl isInvalid={!isPasswordValid}>
                  <Input
                    type="password"
                    placeholder="Nova Senha"
                    value={newPassword}
                    onChange={handlePasswordChange}
                    required
                  />
                  <FormErrorMessage>
                    A senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula e um caractere especial.
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!doPasswordsMatch}>
                  <Input
                    type="password"
                    placeholder="Confirmar Nova Senha"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                  />
                  <FormErrorMessage>
                    As senhas não coincidem.
                  </FormErrorMessage>
                </FormControl>
                <Button 
                  colorScheme="blue" 
                  type="submit" 
                  width="100%"
                  isDisabled={!isPasswordValid || !doPasswordsMatch}
                >
                  Redefinir Senha
                </Button>
              </VStack>
            </Box>
            <Text textAlign="center">
              <Link color="blue.500" onClick={() => navigate('/login')}>
                Voltar para o Login
              </Link>
            </Text>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default ResetPassword;