import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  SimpleGrid,
  Flex,
  LinkBox,
  LinkOverlay,
  Heading,
  Text,
  Input,
  Box,
  useToast,
  Button,
} from "@chakra-ui/react";
import { useAuth } from 'context/authContext';
import { EspecialidadeClinica, fetchEspecialidadesByClinica } from 'services/especialidadesClinicaService';
import EspecialidadesManager from './EspecialidadesManager';

const EspecialidadesModule: React.FC = () => {
  const { userData } = useAuth();
  const toast = useToast();
  const [especialidades, setEspecialidades] = useState<EspecialidadeClinica[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isManageEsp, setIsManageEsp] = useState(false);

  const loadEspecialidades = useCallback(async () => {
    if (!userData?.id_clinica) {
      toast({
        title: "Erro",
        description: "ID da clínica não encontrado.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const data = await fetchEspecialidadesByClinica(userData.id_clinica);
      setEspecialidades(data);
    } catch (error) {
      console.error('Falha ao carregar especialidades', error);
      toast({
        title: "Erro",
        description: "Falha ao carregar especialidades. Veja detalhes no console.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [userData, toast]);

  useEffect(() => {
    loadEspecialidades();
  }, [loadEspecialidades]);

  const filteredEspecialidades = especialidades.filter(esp =>
    esp.ds_especialidades.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleBack = () => {
    setIsManageEsp(false);
    loadEspecialidades(); // Recarrega as especialidades ao voltar
  };

  if (isManageEsp) {
    return <EspecialidadesManager onBack={handleBack} />;
  }

  return (
        <Box width="full">
          <Container maxWidth="container.xl" px={4} py={8}>
            <Flex direction={{ base: 'column', md: 'row' }} alignItems="center" justifyContent="space-between" gap={4}>
              <Heading as="h2" fontWeight="bold" letterSpacing="tight">
                Nossas Especialidades

                <Box width="full" maxWidth="md">
                  <Input
                    type="search"
                    placeholder="Buscar especialidades..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Box>
              </Heading>
              <Button colorScheme="blue" mr={3} onClick={() => setIsManageEsp(true)}>
                Gerenciar especialidades
              </Button>
            </Flex>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={6} mt={8}>
              {filteredEspecialidades.map((especialidade) => (
                <LinkBox key={especialidade.id} as="article" overflow="hidden" rounded="lg" role="group">
                  <LinkOverlay>
                    <Box
                      p={4}
                      bg={especialidade.cor}
                      h={60}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Text
                        fontSize="medium"
                        fontWeight="semibold"
                        color="white"
                        textAlign="center"
                        noOfLines={3}
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {especialidade.ds_especialidades}
                      </Text>
                    </Box>
                  </LinkOverlay>
                </LinkBox>
              ))}
            </SimpleGrid>
          </Container>
        </Box>
      
  );
};

export default EspecialidadesModule;