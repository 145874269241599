import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  useDisclosure,
  useToast,
  Spinner,
  Button,
  Center,
  HStack,
  Switch,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import { FaPlus, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import QRCode from 'qrcode.react';
import whatsAppConnectionService from 'services/whatsAppConnectionService';
import { useAuth } from 'context/authContext';
import { useSocket } from 'context/SocketContext';
import {
  WPInstanciasContainer,
  SidebarContainer,
  RightContainer,
  SearchInput,
  StyledInputLeftElement,
  SidebarToggleButton,
  AddInstanceIconButton,
  InstanceListContainer,
  InstanceCard,
  InstanceInfo,
  InstanceName,
  InstanceStatus,
  Footer,
  FiltersWrapper,
} from './WPInstancias.styles';

interface Clinica {
  id_clinica: string;
  nome_clinica: string | null;
  nome_do_chatbot: string | null;
  endereco_clinica: string;
  site_clinica: string;
  telefone_clinica: string;
  horario_duteis: string | null;
  dt_create: string;
}

interface Instancia {
  id: string;
  name: string | null;
  status: string;
  phoneNumber: string | null;
  sessionData: string;
  createdAt: string;
  padrao: boolean;
  clinica: Clinica;
}

const WPInstancias: React.FC = () => {
  const [data, setData] = useState<Instancia[]>([]);
  const { userData } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newName, setNewName] = useState<string>('');
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [isQrModalOpen, setQrModalOpen] = useState(false);
  const [qrSessionId, setQrSessionId] = useState<string | null>(null);
  const [, setConnectingSessions] = useState<Set<string>>(new Set());
  const [editingInstanceId, setEditingInstanceId] = useState<string | null>(null);
  const [confirmDisconnectOpen, setConfirmDisconnectOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const toast = useToast();
  const { socket } = useSocket();

  const fetchData = useCallback(async () => {
    if (userData?.id_clinica) {
      try {
        const instances = await whatsAppConnectionService.getWhatsAppInstancesByClinic(userData.id_clinica);
        setData(instances);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }, [userData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (socket) {
      socket.on('connectionUpdate', (update: any) => {
        setData((prevData) =>
          prevData.map((inst) =>
            inst.id === update.sessionId
              ? { ...inst, status: update.connection || (update.isOnline ? 'connected' : 'not_connected') }
              : inst
          )
        );

        if (update.qr && update.sessionId) {
          setQrCode(update.qr);
          setQrSessionId(update.sessionId);
          setQrModalOpen(true);
        }

        if (update.connection === 'connecting') {
          setConnectingSessions((prev) => new Set(prev).add(update.sessionId));
        }

        if (update.connection === 'open' && update.isOnline) {
          setConnectingSessions((prev) => {
            const newSet = new Set(prev);
            newSet.delete(update.sessionId);
            return newSet;
          });
          setQrModalOpen(false);
          setQrSessionId(null);
        }

        if (
          update.connection === 'close' &&
          update.lastDisconnect &&
          update.lastDisconnect.error &&
          update.lastDisconnect.error.message &&
          update.lastDisconnect.error.message.includes('QR refs attempts ended')
        ) {
          setConnectingSessions((prev) => {
            const newSet = new Set(prev);
            newSet.delete(update.sessionId);
            return newSet;
          });
          setQrModalOpen(false);
          toast({
            title: 'Erro ao conectar.',
            description: 'Tentativas de gerar QR code encerradas.',
            status: 'error',
            duration: 4000,
            isClosable: true,
          });
        }
      });

      return () => {
        socket.off('connectionUpdate');
      };
    }
  }, [socket, toast]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (data.some((inst) => inst.status === 'not_connected')) {
        fetchData();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [data, fetchData]);

  useEffect(() => {
    if (qrSessionId) {
      const instance = data.find((inst) => inst.id === qrSessionId);
      if (instance && instance.status === 'connected') {
        setQrModalOpen(false);
        setQrSessionId(null);
      }
    }
  }, [data, qrSessionId]);

  const handleShowQrCode = async (sessionId: string) => {
    setQrCode(null);
    setQrSessionId(sessionId);
    setQrModalOpen(true);

    try {
      const response = await whatsAppConnectionService.getWhatsAppQRCode(sessionId);
      setQrCode(response.qr);
    } catch (error) {
      console.error('Error fetching QR code:', error);
      toast({
        title: 'Erro ao obter QR code.',
        description: 'Não foi possível obter o QR code.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setQrModalOpen(false);
    }
  };

  const handleEdit = (id: string) => {
    const instance = data.find((inst) => inst.id === id);
    if (instance) {
      setNewName(instance.name || '');
      setEditingInstanceId(id);
      onOpen();
    }
  };

  const handleDelete = (id: string) => {
    setSelectedInstanceId(id);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedInstanceId) return;
    try {
      await whatsAppConnectionService.deleteWhatsAppSession(selectedInstanceId);
      const updatedData = data.filter((inst) => inst.id !== selectedInstanceId);
      setData(updatedData);
      toast({
        title: 'Instância excluída com sucesso.',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } catch (error: any) {
      console.error('Error deleting WhatsApp instance:', error);
      toast({
        title: 'Erro ao excluir instância.',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    setConfirmDeleteOpen(false);
    setSelectedInstanceId(null);
  };

  const handleDisconnect = (id: string) => {
    setSelectedInstanceId(id);
    setConfirmDisconnectOpen(true);
  };

  const handleConfirmDisconnect = async () => {
    if (!selectedInstanceId) return;
    try {
      await whatsAppConnectionService.disconnectWhatsAppSession(selectedInstanceId);
      const updatedData = data.map((inst) =>
        inst.id === selectedInstanceId ? { ...inst, status: 'not_connected' } : inst
      );
      setData(updatedData);
      toast({
        title: 'Instância desconectada com sucesso.',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } catch (error: any) {
      console.error('Error disconnecting WhatsApp instance:', error);
      toast({
        title: 'Erro ao desconectar instância.',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    setConfirmDisconnectOpen(false);
    setSelectedInstanceId(null);
  };

  const handleAddWhatsApp = () => {
    setEditingInstanceId(null);
    setNewName('');
    onOpen();
  };

  const handleConfirmAdd = async () => {
    if (!newName.trim()) {
      toast({
        title: 'Nome é obrigatório.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      if (editingInstanceId) {
        await whatsAppConnectionService.updateWhatsAppSession(editingInstanceId, { name: newName });
        const updatedData = data.map((inst) =>
          inst.id === editingInstanceId ? { ...inst, name: newName } : inst
        );
        setData(updatedData);
      } else if (userData?.id_clinica) {
        await whatsAppConnectionService.createWhatsAppInstance(userData.id_clinica, newName, false);
        fetchData();
      }
      onClose();
      setNewName('');
      setEditingInstanceId(null);
      toast({
        title: `Instância ${editingInstanceId ? 'atualizada' : 'criada'} com sucesso.`,
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } catch (error: any) {
      console.error(`Error ${editingInstanceId ? 'updating' : 'creating'} WhatsApp instance:`, error);
      toast({
        title: `Erro ao ${editingInstanceId ? 'atualizar' : 'criar'} instância.`,
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleSetDefault = async (id: string, isDefault: boolean) => {
    try {
      const updatedData = data.map((inst) =>
        inst.id === id ? { ...inst, padrao: isDefault } : { ...inst, padrao: false }
      );
      setData(updatedData);

      if (isDefault) {
        await whatsAppConnectionService.updateWhatsAppSession(id, { padrao: true });
        toast({
          title: 'Instância definida como padrão com sucesso.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      } else {
        await whatsAppConnectionService.updateWhatsAppSession(id, { padrao: false });
        toast({
          title: 'Instância padrão removida com sucesso.',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      console.error('Error setting default WhatsApp instance:', error);
      toast({
        title: 'Erro ao definir instância padrão.',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const filteredInstances = data.filter((instance) =>
    instance.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <WPInstanciasContainer>
      <SidebarContainer isOpen={isSidebarOpen}>
        <SidebarToggleButton onClick={toggleSidebar} isOpen={isSidebarOpen}>
          {isSidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
        </SidebarToggleButton>
        {isSidebarOpen && (
          <>
            <FiltersWrapper>
              <SearchInput>
                <StyledInputLeftElement>
                  <SearchIcon />
                </StyledInputLeftElement>
                <Input
                  placeholder="Pesquisar por nome"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </SearchInput>
              <AddInstanceIconButton onClick={handleAddWhatsApp}>
                <FaPlus />
              </AddInstanceIconButton>
            </FiltersWrapper>
            <InstanceListContainer>
              {filteredInstances.map((instancia) => (
                <InstanceCard
                  key={instancia.id}
                  onClick={() => setSelectedInstanceId(instancia.id)}
                  isSelected={selectedInstanceId === instancia.id}
                >
                  <InstanceInfo>
                    <InstanceName>{instancia.name || 'N/A'}</InstanceName>
                    <InstanceStatus>{instancia.status}</InstanceStatus>
                  </InstanceInfo>
                  <Switch
                    isChecked={instancia.padrao}
                    onChange={(e) => handleSetDefault(instancia.id, e.target.checked)}
                    colorScheme="green"
                    size="sm"
                  />
                </InstanceCard>
              ))}
            </InstanceListContainer>
            <Footer>
              <Text fontSize="sm" color="gray.500">Total de instâncias: {filteredInstances.length}</Text>
            </Footer>
          </>
        )}
      </SidebarContainer>
      <RightContainer>
        {selectedInstanceId && (
          <Box>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
              Detalhes da Instância
            </Text>
            <Table variant="simple">
              <Tbody>
                <Tr>
                  <Th>Nome</Th>
                  <Td>{data.find(i => i.id === selectedInstanceId)?.name || 'N/A'}</Td>
                </Tr>
                <Tr>
                  <Th>Status</Th>
                  <Td>{data.find(i => i.id === selectedInstanceId)?.status}</Td>
                </Tr>
                <Tr>
                  <Th>Número WhatsApp</Th>
                  <Td>{data.find(i => i.id === selectedInstanceId)?.phoneNumber || 'N/A'}</Td>
                </Tr>
                <Tr>
                  <Th>Data do Vínculo</Th>
                  <Td>{data.find(i => i.id === selectedInstanceId)?.sessionData}</Td>
                </Tr>
              </Tbody>
            </Table>
            <HStack mt={4} spacing={4}>
              {data.find(i => i.id === selectedInstanceId)?.status === 'connected' ? (
                <Button colorScheme="red" onClick={() => handleDisconnect(selectedInstanceId)}>
                  Desconectar
                </Button>
              ) : (
                <Button colorScheme="blue" onClick={() => handleShowQrCode(selectedInstanceId)}>
                  Conectar (QR Code)
                </Button>
              )}
              <Button leftIcon={<EditIcon />} onClick={() => handleEdit(selectedInstanceId)}>
                Editar
              </Button>
              <Button leftIcon={<DeleteIcon />} colorScheme="red" onClick={() => handleDelete(selectedInstanceId)}>
                Excluir
              </Button>
            </HStack>
          </Box>
        )}
      </RightContainer>

      <Modal
        isOpen={isQrModalOpen}
        onClose={() => {
          setQrModalOpen(false);
          setConnectingSessions((prev) => {
            const newSet = new Set(prev);
            newSet.delete(qrSessionId as string);
            return newSet;
          });
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Leia o QRCode para iniciar a sessão</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <Box borderWidth="1px" borderRadius="lg" padding="10px" boxShadow="lg">
                {qrCode ? (
                  <QRCode value={qrCode} size={256} />
                ) : (
                  <Box textAlign="center">
                    <Spinner size="xl" />
                    <Text mt={3}>Aguardando geração do QR Code...</Text>
                  </Box>
                )}
              </Box>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editingInstanceId ? 'Editar Instância do WhatsApp' : 'Adicionar Instância do WhatsApp'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input placeholder="Nome da Instância" value={newName} onChange={(e) => setNewName(e.target.value)} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleConfirmAdd}>
              Confirmar
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={confirmDisconnectOpen} onClose={() => setConfirmDisconnectOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Desconexão</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Tem certeza que deseja desconectar esta instância?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleConfirmDisconnect}>
              Confirmar
            </Button>
            <Button variant="ghost" onClick={() => setConfirmDisconnectOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Exclusão</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Tem certeza que deseja excluir esta instância?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleConfirmDelete}>
              Confirmar
            </Button>
            <Button variant="ghost" onClick={() => setConfirmDeleteOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </WPInstanciasContainer>
  );
};

export default WPInstancias;