import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, Text, Box } from '@chakra-ui/react';
import { FaSave } from 'react-icons/fa';
import { uploadImage } from 'services/imagesService';
import { updateUser, getUserById } from 'services/userService';
import { useAuth } from 'context/authContext';
import CustomButton from 'components/CustomButton';
import styled from 'styled-components';

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
`;

const ProfileImageLabel = styled.label`
  cursor: pointer;
`;

interface EditProfileModalProps {
  isOpen: boolean;
  onClose: (updatedUserData?: any) => void;
  onUserDataUpdate: (userData: any) => void;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({ isOpen, onClose, onUserDataUpdate }) => {
  const { userData, setUserData } = useAuth();
  const [profile, setProfile] = useState({ nome_usuario: '', email_usuario: '', url_imagem: '' });
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const loadProfile = async () => {
      if (userData?.id_users) {
        const user = await getUserById(userData.id_users);
        setProfile(user);
        setProfileImageUrl(user.url_imagem ? `${process.env.REACT_APP_API_URL}${user.url_imagem}` : null);
      }
    };
    if (userData) {
      loadProfile();
    }
  }, [userData]);

  const handleSave = async () => {
    if (userData?.id_users) {
      if (profileImageFile) {
        const uploadedImage = await uploadImage(profileImageFile);
        profile.url_imagem = uploadedImage.imageUrl;
      }
      const updatedUser = await updateUser(userData.id_users, profile);
      setUserData(updatedUser);
      onUserDataUpdate(updatedUser);
      onClose(updatedUser);
      setProfileImageUrl(null); // Limpar a URL da imagem temporária após salvar
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfileImageFile(file);
      setProfileImageUrl(URL.createObjectURL(file));
    }
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editar Perfil</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box display="flex" alignItems="center" mb={4}>
            <ProfileImageLabel title="Clique aqui para alterar a imagem do perfil">
              <ProfileImage
                src={profileImageUrl || `${process.env.REACT_APP_API_URL}${profile.url_imagem}` || '/perfil_default.jpeg'}
                alt="Imagem de Perfil"
                onClick={handleImageClick}
              />
            </ProfileImageLabel>
            <Input
              type="file"
              id="profileImageUpload"
              name="profile_image"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
            <Box ml={4}>
              <Text fontSize="lg">{profile.nome_usuario}</Text>
              <Text fontSize="sm">{profile.email_usuario}</Text>
            </Box>
          </Box>
          <Box mb={4}>
            <Text>Nome</Text>
            <Input
              type="text"
              name="nome_usuario"
              value={profile.nome_usuario}
              onChange={handleChange}
            />
          </Box>
          <Box mb={4}>
            <Text>Email</Text>
            <Input
              type="email"
              name="email_usuario"
              value={profile.email_usuario}
              readOnly
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <CustomButton leftIcon={<FaSave />} onClick={handleSave} text="Salvar" />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditProfileModal;
