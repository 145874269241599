import styled from 'styled-components';
import { Heading, Box } from '@chakra-ui/react';

export const ProfileContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
`;

export const ProfileImageLabel = styled.label`
  cursor: pointer;
`;

export const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormField = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  & > label {
    margin-bottom: 5px;
  }

  & > input,
  & > select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    flex: 1;
    margin-right: 10px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`;

export const ControlButtons = styled.div`
  display: flex;
  gap: 5px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const StyledHeading = styled(Heading).attrs({ as: 'h2' })`
  font-size: 1.2rem;
  font-weight: 700;
  color: #333;
`;

export const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
