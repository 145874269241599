const Config = {
    API_URL: process.env.REACT_APP_API_URL,
    TIMEOUT_REQUEST: parseInt(process.env.REACT_APP_TIMEOUT_REQUEST, 10000) || 10000,
    HEADER_REQUEST: {
      Accept: process.env.REACT_APP_REQUEST_ACCEPT || 'application/json'
    },

  };
  
  // Pontos de verificação
/* console.log('API_URL:', process.env.REACT_APP_API_URL);
console.log('TIMEOUT_REQUEST:', process.env.REACT_APP_TIMEOUT_REQUEST);
console.log('HEADER_REQUEST Accept:', process.env.REACT_APP_REQUEST_ACCEPT); */
  
  export default Config;
  