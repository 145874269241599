import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL; // Acessando a variável de ambiente

export interface Procedimento {
  id_procedimento: number;
  procedimento: string;
  ds_procedimento?: string;
  tempo_procedimento?: string;
  profissionalId: number;
  valor_procedimento?: string;
}

export const fetchProcedimentosByProfissional = async (profissionalId: number) => {
  try {
    const response = await axios.get<Procedimento[]>(`${API_URL}/api/procedimentos/profissional/${profissionalId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching procedimentos for profissional with id ${profissionalId}:`, error);
    throw new Error('Failed to fetch procedimentos.');
  }
};

export const fetchProcedimentoById = async (id: number) => {
  try {
    const response = await axios.get<Procedimento>(`${API_URL}/api/procedimentos/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching procedimento with id ${id}:`, error);
    throw new Error('Failed to fetch procedimento.');
  }
};

export const createProcedimento = async (procedimento: Partial<Procedimento>) => {
  try {

    console.log('Procedimento', procedimento);
    const response = await axios.post<Procedimento>(`${API_URL}/api/procedimentos`, procedimento);
    console.log('response.data', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating procedimento:', error);
    throw new Error('Failed to save procedimento.');
  }
};

export const updateProcedimento = async (id: number, procedimento: Partial<Procedimento>) => {
  try {
    const response = await axios.put<Procedimento>(`${API_URL}/api/procedimentos/${id}`, procedimento);
    return response.data;
  } catch (error) {
    console.error(`Error updating procedimento with id ${id}:`, error);
    throw new Error('Failed to update procedimento.');
  }
};

export const deleteProcedimento = async (id: number) => {
  try {
    await axios.delete(`${API_URL}/api/procedimentos/${id}`);
  } catch (error) {
    console.error(`Error deleting procedimento with id ${id}:`, error);
    throw new Error('Failed to delete procedimento.');
  }
};
