import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Tooltip } from '@chakra-ui/react';
import { FaStethoscope, FaBell, FaCog, FaBars, FaSignOutAlt, FaClipboardList , FaArrowRight, FaCalendarAlt, FaQuestionCircle, FaClinicMedical, FaUser, FaUserMd, FaServer, FaChartLine, FaFileInvoiceDollar, FaReceipt, FaRobot } from 'react-icons/fa';
import { useAlertStore } from 'store/alertStore';
import {
  HeaderContainer,
  LogoContainer,
  MenuIcon,
  RightIcons,
  Icon,
  AlertBadge,
  Profile,
  Menu,
  MenuItem,
  MenuTitle,
  SettingsBox,
  TrialContainer,
  TrialTextLeft,
  TrialTextCenter,
} from './Header.styles';
import { useAuth } from 'context/authContext';
import SettingsMenu from 'components/SettingsMenu';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  theme: string;
  toggleTheme: () => void;
}

interface UserData {
  id_users: string;
  nome_usuario: string;
  email_usuario: string;
  url_imagem: string;
  status_usuario: string;
  privilegio_usuario: string;
  id_clinica: string;
}

const Header: React.FC<HeaderProps> = ({ theme, toggleTheme }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const settingsRef = useRef<HTMLDivElement>(null);
  const { logout, userData, setUserData } = useAuth();
  const { alerts } = useAlertStore();
  const navigate = useNavigate();

  const handleMenuClick = useCallback((path: string) => {
    navigate(path);
    setMenuOpen(false);
  }, [navigate]);

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const handleUserDataUpdate = useCallback((updatedUserData: UserData) => {
    setUserData(updatedUserData);
  }, [setUserData]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuOpen(false);
      }
      if (settingsRef.current && !settingsRef.current.contains(event.target as Node) && !modalOpen) {
        setSettingsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, settingsRef, modalOpen]);

  if (!userData) {
    return null;
  }

  const userImage = userData.url_imagem ? `${process.env.REACT_APP_API_URL}${userData.url_imagem}` : 'perfil_default.jpeg';
  const userName = userData.nome_usuario || '';
  const userPrivilege = userData.privilegio_usuario || '';

  const isAdmin = userPrivilege.toLowerCase() === 'administrador';

  const trialEndFormatted = userData.stripeStatus?.trialEnd
    ? new Date(userData.stripeStatus.trialEnd).toLocaleDateString('pt-BR')
    : '';

  return (
    <>
      {userData.stripeStatus?.status === 'trialing' && (
        <TrialContainer>
          <TrialTextLeft>
            Modo de teste até {trialEndFormatted}
          </TrialTextLeft>
          <TrialTextCenter onClick={() => handleMenuClick('/planos')}>
            <FaFileInvoiceDollar style={{ marginRight: '5px' }} />
            Conheça nossos planos
            <FaArrowRight style={{ marginLeft: '5px' }} />
          </TrialTextCenter>
        </TrialContainer>
      )}
      <HeaderContainer>
        <MenuIcon onClick={() => setMenuOpen(!menuOpen)}>
          <FaBars size={20} color="#fff" />
        </MenuIcon>
        <LogoContainer>
          <img src="/minner-icon.png" alt="Logo" />
          <span>Minner AI</span>
        </LogoContainer>
        <RightIcons>
          <Icon>
            <FaBell size={20} color="#fff" />
            {alerts.length > 0 && <AlertBadge>{alerts.length}</AlertBadge>}
          </Icon>
          <Icon onClick={() => setSettingsOpen(!settingsOpen)}>
            <FaCog size={20} color="#fff" />
          </Icon>
          <Tooltip label={userName} placement="bottom" hasArrow>
            <Profile>
              <img src={userImage} alt="Profile" />
            </Profile>
          </Tooltip>
          <Icon onClick={handleLogout}>
            <FaSignOutAlt size={20} color="#fff" />
          </Icon>
        </RightIcons>
        <Menu $isOpen={menuOpen} ref={menuRef}>
          <MenuTitle>ATENDIMENTO</MenuTitle>
          <MenuItem onClick={() => handleMenuClick('/atendimento')}>
            <FaClipboardList /> Atendimento
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick('/agendas')}>
            <FaCalendarAlt /> Agendas
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick('/chat-secretaria')}>
            <FaRobot /> Chat IA Secretária
          </MenuItem>
          <MenuItem onClick={() => handleMenuClick('/ajuda')}>
            <FaQuestionCircle /> Ajuda
          </MenuItem>
          {isAdmin && (
            <>
              <MenuTitle>GESTÃO</MenuTitle>
              <MenuItem onClick={() => handleMenuClick('/dashboard')}>
                <FaChartLine /> Dashboard
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick('/clinica')}>
                <FaClinicMedical /> Clínica
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick('/usuarios')}>
                <FaUser /> Usuários
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick('/profissionais')}>
                <FaUserMd /> Profissionais
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick('/especialidades')}>
                <FaStethoscope /> Especialidades
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick('/instancias')}>
                <FaServer /> Instâncias
              </MenuItem>
              <MenuTitle>ADMINISTRAÇÃO</MenuTitle>
              <MenuItem onClick={() => handleMenuClick('/subscription')}>
                <FaFileInvoiceDollar /> Assinatura
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick('/planos')}>
                <FaReceipt /> Planos
              </MenuItem>
            </>
          )}
        </Menu>
        {settingsOpen && (
          <SettingsBox ref={settingsRef}>
            <SettingsMenu
              setModalOpen={setModalOpen}
              onUserDataUpdate={handleUserDataUpdate}
              setSettingsOpen={setSettingsOpen}
              handleMenuClick={handleMenuClick}
            />
          </SettingsBox>
        )}
      </HeaderContainer>
    </>
  );
};

export default Header;