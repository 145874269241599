import styled from 'styled-components';

export const ContactsContainer = styled.div`
  padding: 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.light};

  body.dark-theme & {
    background-color: ${({ theme }) => theme.colors.background.dark};
  }
`;

export const Header = styled.h1`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.light};

  body.dark-theme & {
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;

export const SearchInput = styled.input`
  width: 300px;
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  border: 1px solid ${({ theme }) => theme.colors.primary[400]};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.text.light};

  body.dark-theme & {
    border-color: ${({ theme }) => theme.colors.primary[700]};
    background-color: ${({ theme }) => theme.colors.background.dark};
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;

export const NewContactButton = styled.button`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.primary[500]};
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;

  body.dark-theme & {
    background-color: ${({ theme }) => theme.colors.primary[700]};
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary[400]};
  cursor: pointer;

  body.dark-theme & {
    border-color: ${({ theme }) => theme.colors.primary[700]};
  }
`;

export const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.light};

  body.dark-theme & {
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  font-family: ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.text.light};

  body.dark-theme & {
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.background.light};
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  position: relative;

  body.dark-theme & {
    background-color: ${({ theme }) => theme.colors.background.dark};
  }
`;

export const ModalHeader = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.light};
  margin-bottom: 20px;

  body.dark-theme & {
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.primary[400]};
  border-radius: 4px;

  body.dark-theme & {
    border-color: ${({ theme }) => theme.colors.primary[700]};
    background-color: ${({ theme }) => theme.colors.background.dark};
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;

export const SaveButton = styled.button`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.primary[500]};
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  body.dark-theme & {
    background-color: ${({ theme }) => theme.colors.primary[700]};
  }
`;

export const CancelButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.text.light};

  body.dark-theme & {
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;

export const DeleteButton = styled.button`
  padding: 10px;
  background-color: red;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export {};
