import React from 'react';
import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import Header from 'components/Header';
import ChatSecretaria from 'components/ChatSecretaria';
import { useAuth } from 'context/authContext';
import { DefaultPageContainer, ContentContainer, LeftContainer } from '../../styles/DefaultPage.styles';

const ChatSecretariaPage: React.FC = () => {
  const { toggleColorMode } = useColorMode();
  const currentTheme = useColorModeValue('light', 'dark');
  const { userData } = useAuth();

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header
        theme={currentTheme}
        toggleTheme={toggleColorMode}
      />
      <DefaultPageContainer>
        <ContentContainer>
          <LeftContainer>
            <ChatSecretaria />
          </LeftContainer>
        </ContentContainer>
      </DefaultPageContainer>
    </>
  );
};

export default ChatSecretariaPage;