import React, { useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import axios from 'axios';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Register from 'pages/Register';
import Atendimento from 'pages/Atendimento';
import Agendas from 'pages/Agendas';
import Help from 'pages/Help';
import Clinica from 'pages/Clinica';
import Users from 'pages/Users';
import Profissionais from 'pages/Profissionais';
import Instancias from 'pages/Instancias';
import Planos from 'pages/Planos';
import Success from 'pages/Success';
import Cancel from 'pages/Cancel';
import ErrorPage from 'pages/ErrorPage';
import ChatSecretariaPage from 'pages/ChatSecretaria';
import theme from 'config/theme';
import Config from 'config/Config';
import { loginWithToken, getUserProfile } from 'services/userService';
import { useAuth } from 'context/authContext';
import GlobalStyles from './styles/GlobalStyles';
import { PaymentProvider } from 'context/PaymentContext';
import { SocketProvider } from 'context/SocketContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SubscriptionPage from 'pages/Subscription';
import ProtectedRoute from 'components/ProtectedRoute';
import Especialidades from 'pages/Especialidades';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';

function defineInterceptor(token: string | null) {
  axios.interceptors.response.use(
    (response) => response,
    (err) => {
      return new Promise(async (resolve, reject) => {
        const originalReq = err.config;
        if (err.response && err.response.status === 401 && err.config && !err.config._retry) {
          originalReq._retry = true;
          if (token) {
            try {
              const res = await axios.post(`${Config.API_URL}token/refresh`, { oldToken: token });
              const newToken = res.data.access_token;
              await AsyncStorage.setItem('TOKEN', newToken);
              originalReq.headers['Authorization'] = `Bearer ${newToken}`;
              const retryRes = await axios(originalReq);
              resolve(retryRes);
            } catch (retryErr) {
              reject(retryErr);
            }
          } else {
            reject(err);
          }
        } else {
          reject(err);
        }
      });
    }
  );
}

interface AuthWrapperProps {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const { isAuthenticated, setIsAuthenticated, setToken, setUserData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkToken = async () => {
      try {
        const savedToken = await AsyncStorage.getItem('TOKEN');
        if (savedToken) {
          const response = await loginWithToken({ token: savedToken });
          if (response && response.data) {
            setIsAuthenticated(true);
            setToken(response.data.access_token);
            const userProfileResponse = await getUserProfile(response.data.access_token);
            if (userProfileResponse && userProfileResponse.data) {
              setUserData(userProfileResponse.data);
            } else {
              throw new Error('Perfil do usuário não encontrado');
            }
          } else {
            throw new Error('Token de acesso não encontrado na resposta');
          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Erro ao verificar o token:', error);
        setIsAuthenticated(false);
      }
    };
    checkToken();
  }, [setIsAuthenticated, setToken, setUserData]);

  useEffect(() => {
    if (isAuthenticated) {
      if (location.pathname === '/login') {
        navigate('/');
      }
    } else if (!isAuthenticated) {
      const publicRoutes = ['/login', '/register', '/forgot-password'];
      const isResetPasswordRoute = location.pathname.startsWith('/reset-password');

      if (!publicRoutes.includes(location.pathname) && !isResetPasswordRoute) {
        navigate('/login');
      }
    }
  }, [isAuthenticated, navigate, location.pathname]);

  return <>{children}</>;
};

const App: React.FC = () => {
  const { token } = useAuth();

  useEffect(() => {
    defineInterceptor(token);
  }, [token]);

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <GlobalStyles />
      <AuthWrapper>
        <SocketProvider>
          <PaymentProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/atendimento" element={<Atendimento />} />
                <Route path="/agendas" element={<Agendas />} />
                <Route path="/contacts" element={<div>Contacts Page</div>} />
                <Route path="/ajuda" element={<Help />} />
                <Route path="/clinica" element={<Clinica />} />
                <Route path="/usuarios" element={<Users />} />
                <Route path="/profissionais" element={<Profissionais />} />
                <Route path="/instancias" element={<Instancias />} />
                <Route path="/relatorios" element={<div>Relatorios Page</div>} />
                <Route path="/subscription" element={<SubscriptionPage />} />
                <Route path="/especialidades" element={<Especialidades />} />
                <Route path="/planos" element={<Planos />} />
                <Route path="/success" element={<Success />} />
                <Route path="/cancel" element={<Cancel />} />
                <Route path="/chat-secretaria" element={<ChatSecretariaPage />} />
              </Route>
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </PaymentProvider>
        </SocketProvider>
      </AuthWrapper>
    </ChakraProvider>
  );
};

export default App;