import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaPaperPlane, FaSmile } from 'react-icons/fa';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { motion, AnimatePresence } from 'framer-motion';

interface MessageInputProps {
  newMessage: string;
  suggestions: string[];
  onMessageChange: (message: string) => void;
  onSendMessage: () => void;
  onSuggestionClick: (suggestion: string) => void;
}

const MAX_MESSAGE_LENGTH = 500;

const MessageInputComponent: React.FC<MessageInputProps> = ({
  newMessage,
  suggestions,
  onMessageChange,
  onSendMessage,
  onSuggestionClick,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const emojiPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newMessage]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target as Node)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSendMessage();
    }
  };

  const handleEmojiClick = (emojiData: EmojiClickData) => {
    onMessageChange(newMessage + emojiData.emoji);
  };

  const remainingCharacters = MAX_MESSAGE_LENGTH - newMessage.length;

  return (
    <MessageInputWrapper>
      <SuggestionsContainer>
        <AnimatePresence>
          {suggestions.map((suggestion, index) => (
            <motion.div
              key={suggestion}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <SuggestionChip
                onClick={() => onSuggestionClick(suggestion)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {suggestion}
              </SuggestionChip>
            </motion.div>
          ))}
        </AnimatePresence>
      </SuggestionsContainer>
      <MessageInputContainer>
        <EmojiButton
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          aria-label="Emoji Picker"
        >
          <FaSmile />
        </EmojiButton>
        <AnimatePresence>
          {showEmojiPicker && (
            <EmojiPickerWrapper
              ref={emojiPickerRef}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
            >
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </EmojiPickerWrapper>
          )}
        </AnimatePresence>
        <StyledTextarea
          ref={textareaRef}
          value={newMessage}
          onChange={(e) =>
            onMessageChange(e.target.value.slice(0, MAX_MESSAGE_LENGTH))
          }
          placeholder="Digite sua mensagem..."
          onKeyPress={handleKeyPress}
          maxLength={MAX_MESSAGE_LENGTH}
          aria-label="Message Input"
        />
        <CharacterCount
          $isNearLimit={remainingCharacters <= 50}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {remainingCharacters}
        </CharacterCount>
        <SendButton
          onClick={onSendMessage}
          disabled={!newMessage.trim()}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          aria-label="Send Message"
        >
          <FaPaperPlane />
        </SendButton>
      </MessageInputContainer>
    </MessageInputWrapper>
  );
};

const MessageInputWrapper = styled.div`
  background-color: #f0f4f8;
  padding: 10px;
  border-top: 1px solid #8da4cc;
`;

const SuggestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const SuggestionChip = styled(motion.button)`
  background-color: #8da4cc;
  color: #ffffff;
  border: none;
  border-radius: 16px;
  padding: 6px 12px;
  margin: 0 4px 4px 0;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #7b93b8;
  }
`;

const MessageInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  background-color: #ffffff;
  border: 1px solid #8da4cc;
  border-radius: 24px;
  padding: 8px 16px;
  position: relative;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const StyledTextarea = styled.textarea`
  flex: 1;
  border: none;
  font-size: 16px;
  outline: none;
  resize: none;
  min-height: 24px;
  max-height: 120px;
  padding: 0;
  margin: 0 8px;
  font-family: inherit;
  background-color: transparent;
  color: #333;

  &::placeholder {
    color: #888;
  }
`;

const SendButton = styled(motion.button)`
  background-color: #c8f577;
  color: #333;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;

  &:disabled {
    background-color: #e6edf7;
    color: #888;
    cursor: not-allowed;
  }
`;

const EmojiButton = styled(motion.button)`
  background: none;
  border: none;
  color: #8da4cc;
  cursor: pointer;
  font-size: 20px;
  padding: 0;
  display: flex;
  align-items: center;
`;

const EmojiPickerWrapper = styled(motion.div)`
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 10;
`;

const CharacterCount = styled(motion.span)<{ $isNearLimit: boolean }>`
  font-size: 12px;
  color: ${({ $isNearLimit }) => ($isNearLimit ? '#e74c3c' : '#888')};
  margin-right: 8px;
  transition: color 0.3s ease;
`;

export default MessageInputComponent;
