import styled from 'styled-components';
import { InputGroup, InputLeftElement } from '@chakra-ui/react';

export const WPInstanciasContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #f7f8fc;
`;

export const SidebarContainer = styled.div<{ isOpen: boolean }>`
  width: ${(props) => (props.isOpen ? '350px' : '50px')};
  padding: ${(props) => (props.isOpen ? '20px' : '10px 5px')};
  border-right: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05);
  transition: width 0.3s ease, padding 0.3s ease;
  position: relative;
`;

export const RightContainer = styled.div`
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.05);
`;

export const SearchInput = styled(InputGroup)`
  background-color: white;
  border-radius: 0px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;

  input {
    border: none;
    padding-left: 40px;
    height: 40px;
    &:focus {
      box-shadow: none;
    }
  }
`;

export const StyledInputLeftElement = styled(InputLeftElement)`
  color: #a0aec0;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
  padding-left: 10px;
`;

export const SidebarToggleButton = styled.button<{ isOpen: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: -20px;
  transform: translateX(50%);
  z-index: 10;

  svg {
    color: #4a5568;
    font-size: 20px;
  }
`;

export const AddInstanceIconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #edf2f7;
  }

  svg {
    color: #4a5568;
    font-size: 20px;
  }
`;

export const InstanceListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  flex-grow: 1;
`;

export const InstanceCard = styled.li<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;
  background-color: ${(props) => (props.isSelected ? '#ebf8ff' : 'transparent')};

  &:hover {
    background-color: #e6fffa;
  }
`;

export const InstanceInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InstanceName = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #2d3748;
`;

export const InstanceStatus = styled.span`
  font-size: 12px;
  color: #718096;
`;

export const Footer = styled.div`
  font-size: 14px;
  text-align: left;
  padding: 10px;
  border-top: 1px solid #e2e8f0;
  margin-top: auto;
`;

export const FiltersWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;