import React, { useState, useEffect, useCallback } from 'react';
import {
  ProfissionaisContainer,
  SearchInput,
  SidebarContainer,
  RightContainer,
  Flag,
  FiltersWrapper,
  Footer,
  RadioWrapper,
  RadioLabel,
  ProfileImage,
  ProfissionalListContainer,
  ProfissionalCard,
  ProfissionalInfo,
  ProfissionalName,
  ProfissionalRole,
  StyledInputLeftElement,
  SidebarToggleButton,
  AddProfissionalIconButton,
  ProcedimentoCard
} from './ProfissionaisModule.styles';
import {
  fetchProfissionais,
  createProfissional,
  updateProfissional,
  fetchProfissionalById,
  Profissional
} from 'services/profissionaisService';
import { fetchEspecialidades, Especialidade } from 'services/especialidadesService';
import { fetchConveniosByClinica, Convenio } from 'services/conveniosService';
import { fetchProcedimentosByProfissional, createProcedimento, updateProcedimento, deleteProcedimento, Procedimento } from 'services/procedimentosService';
import { FaCircle, FaCheckCircle, FaTimesCircle, FaSearch, FaChevronLeft, FaChevronRight, FaUserMd, FaPlus, FaEdit, FaSave, FaTrash, FaTimes } from 'react-icons/fa';
import Config from 'config/Config';
import ProfissionalProfile from 'components/ProfissionalProfile';
import { useAuth } from 'context/authContext';
import { useToast, Tooltip, HStack, Text, Tabs, TabList, TabPanels, Tab, TabPanel, Input, Select, Button, VStack, SimpleGrid } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { NumericFormat } from 'react-number-format';
import { Tag as EspecialidadeTag } from 'components/TagPickerEspecialidades';
import { Tag as ConvenioTag } from 'components/TagPickerConvenios';

const API_URL = Config.API_URL;

const ProfissionaisModule: React.FC = () => {
  const { userData } = useAuth();
  const [profissionais, setProfissionais] = useState<Profissional[]>([]);
  const [selectedProfissionalId, setSelectedProfissionalId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('Ativo');
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [tags, setTags] = useState<EspecialidadeTag[]>([]);
  const [convenioTags, setConvenioTags] = useState<ConvenioTag[]>([]);
  const [especialidadeError, setEspecialidadeError] = useState<string | null>(null);
  const [convenioError, setConvenioError] = useState<string | null>(null);
  const [procedimentos, setProcedimentos] = useState<Procedimento[]>([]);
  const [newProcedimento, setNewProcedimento] = useState<Partial<Procedimento>>({});
  //const [selectedProcedimento, setSelectedProcedimento] = useState<Partial<Procedimento> | null>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isAddingProcedimento, setIsAddingProcedimento] = useState(false);
  const [isEditingProcedimento, setIsEditingProcedimento] = useState(false);
  const [editingProcedimento, setEditingProcedimento] = useState<Partial<Procedimento>>({});

  const toast = useToast();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formattedValorProcedimento, setFormattedValorProcedimento] = useState('');

  const formatCurrency = (value: string) => {
    const numericValue = parseInt(value, 10);
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(numericValue);
  };

  const handleValorProcedimentoChange = (values: any) => {
    const { value, floatValue } = values;
    setFormattedValorProcedimento(value);
  
    if (isEditingProcedimento) {
      setEditingProcedimento({
        ...editingProcedimento,
        valor_procedimento: floatValue ? floatValue.toString() : '',
      });
    } else {
      setNewProcedimento({
        ...newProcedimento,
        valor_procedimento: floatValue ? floatValue.toString() : '',
      });
    }
  };

  const loadProfissionais = useCallback(async () => {
    try {
      if (!userData?.id_clinica) {
        throw new Error('ID da clínica não encontrado.');
      }
      const data = await fetchProfissionais(userData.id_clinica);
      data.sort((a: Profissional, b: Profissional) => a.nome_profissional.localeCompare(b.nome_profissional));
      setProfissionais(data);
      setSelectedProfissionalId(data[0]?.id_profissional || null);
    } catch (error) {
      console.error('Failed to load profissionais', error);
      toast({
        title: "Erro",
        description: "Falha ao carregar profissionais. Veja detalhes no console.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [userData, toast]);

  useEffect(() => {
    if (userData) {
      loadProfissionais();
    }
  }, [userData, loadProfissionais]);

  useEffect(() => {
    const loadTags = async () => {
      try {
        const especialidades = await fetchEspecialidades();
        setTags(especialidades.map((especialidade: Especialidade) => ({
          id: especialidade.id,
          label: especialidade.ds_especialidades,
          color: especialidade.cor,
        })));
      } catch (error) {
        console.error('Failed to load tags', error);
      }
    };

    const loadConvenioTags = async () => {
      if (!userData?.id_clinica) {
        return;
      }
      try {
        const convenios = await fetchConveniosByClinica(userData.id_clinica.toString());
        setConvenioTags(convenios.map((convenio: Convenio) => ({
          id: convenio.id_convenio,
          label: convenio.ds_convenio,
          color: convenio.cor,
          convenioId: convenio.id_convenio,
          id_profissional: 0,
        })));
      } catch (error) {
        console.error('Failed to load convenio tags', error);
      }
    };

    if (userData?.id_clinica) {
      loadTags();
      loadConvenioTags();
    }
  }, [userData]);

  useEffect(() => {
    const loadProcedimentos = async () => {
      if (selectedProfissionalId !== null) {
        try {
          const data = await fetchProcedimentosByProfissional(selectedProfissionalId);
          setProcedimentos(data);

          // Formatando o valor do procedimento
          const formattedData = data.map(procedimento => ({
            ...procedimento,
            valor_procedimento: procedimento.valor_procedimento 
              ? formatCurrency(procedimento.valor_procedimento) 
              : ''
          }));

          setProcedimentos(formattedData);
        } catch (error) {
          console.error('Failed to load procedimentos', error);
        }
      }
    };

    loadProcedimentos();
  }, [selectedProfissionalId]);

  const handleAddProfissional = () => {
    if (!userData?.id_clinica) {
      toast({
        title: "Erro",
        description: "ID da clínica não encontrado.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setSelectedProfissionalId(null);
    setIsEditing(true);
    setIsAdding(true);
    setProcedimentos([{
      id_procedimento: 0,
      procedimento: "Consulta",
      ds_procedimento: "Consulta Padrão",
      tempo_procedimento: "30",
      valor_procedimento: '',
      profissionalId: 0
    }]);
  };

  const handleSaveProfissional = async (profissional: Partial<Profissional>): Promise<Profissional | undefined> => {
    if (!profissional.especialidades || profissional.especialidades.length === 0) {
      setEspecialidadeError("Selecione pelo menos uma especialidade.");
      return undefined;
    }

    if (!profissional.convenios || profissional.convenios.length === 0) {
      setConvenioError("Selecione pelo menos um convênio.");
      return undefined;
    }

    setEspecialidadeError(null);
    setConvenioError(null);

    try {
      if (isAdding) {
        const newProfissional = await createProfissional({ ...profissional, clinica: userData?.id_clinica });
        setProfissionais([...profissionais, newProfissional]);

        // Create default "Consulta" procedure
        await createProcedimento({
          procedimento: "Consulta",
          ds_procedimento: "Consulta Padrão",
          tempo_procedimento: "30",
          profissionalId: newProfissional.id_profissional
        });
      } else if (selectedProfissionalId) {
        const updatedProfissional = await updateProfissional(selectedProfissionalId.toString(), profissional as Profissional);
        setProfissionais(profissionais.map((p) => p.id_profissional === updatedProfissional.id_profissional ? updatedProfissional : p));
      }
      setIsEditing(false);
      setIsAdding(false);
      setSelectedProfissionalId(profissionais[0]?.id_profissional || null);
      loadProfissionais();
    } catch (error) {
      console.error('Failed to save profissional', error);
      toast({
        title: "Erro",
        description: "Falha ao salvar profissional. Veja detalhes no console.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCancelEdit = async () => {
    setIsEditing(false);
    setIsAdding(false);
    if (selectedProfissionalId) {
      try {
        const profissionalData = await fetchProfissionalById(selectedProfissionalId.toString());
        setProfissionais((prevProfissionais) =>
          prevProfissionais.map((p) => (p.id_profissional === profissionalData.id_profissional ? profissionalData : p))
        );
        setSelectedProfissionalId(profissionalData.id_profissional);
      } catch (error) {
        console.error('Failed to refresh profissional', error);
      }
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddProcedimento = () => {
    setNewProcedimento({ profissionalId: selectedProfissionalId ?? 0 });
    setIsAddingProcedimento(true);
    setIsEditingProcedimento(false);
  };

  const handleCancelAddProcedimento = () => {
    setNewProcedimento({});
    setIsAddingProcedimento(false);
  };

  const handleSaveProcedimento = async () => {
    if (!newProcedimento.procedimento || !newProcedimento.tempo_procedimento) {
      toast({
        title: "Erro",
        description: "Procedimento e Duração são obrigatórios.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      const savedProcedimento = await createProcedimento({
        ...newProcedimento,
        tempo_procedimento: newProcedimento.tempo_procedimento?.toString() || '',
        valor_procedimento: newProcedimento.valor_procedimento || '',
      });
      setProcedimentos([...procedimentos, savedProcedimento]);
      setNewProcedimento({});
      setIsAddingProcedimento(false);
      setFormattedValorProcedimento('');
    } catch (error) {
      console.error('Failed to save procedimento', error);
      toast({
        title: "Erro",
        description: "Falha ao salvar o procedimento.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditProcedimento = (procedimento: Procedimento) => {
    setEditingProcedimento(procedimento);
    setIsEditingProcedimento(true);

    // Carregar valor do procedimento selecionado
    if (procedimento.valor_procedimento) {
      const formattedValue = formatCurrency(procedimento.valor_procedimento);
      setFormattedValorProcedimento(formattedValue);
    } else {
      setFormattedValorProcedimento('');
    }
  };

  const handleUpdateProcedimento = async () => {
    if (!editingProcedimento.procedimento || !editingProcedimento.tempo_procedimento) {
      toast({
        title: "Erro",
        description: "Procedimento e duração são obrigatórios.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  
    try {
      if (editingProcedimento.id_procedimento !== undefined) {
        const updatedProcedimento = await updateProcedimento(editingProcedimento.id_procedimento, {
          ...editingProcedimento,
          tempo_procedimento: editingProcedimento.tempo_procedimento?.toString() || '',
          valor_procedimento: editingProcedimento.valor_procedimento || '',
        });
        setProcedimentos(procedimentos.map((p) => p.id_procedimento === updatedProcedimento.id_procedimento ? updatedProcedimento : p));
        setIsEditingProcedimento(false);
        setEditingProcedimento({});
        setFormattedValorProcedimento('');
      }
    } catch (error) {
      console.error('Failed to update procedimento', error);
      toast({
        title: "Erro",
        description: "Falha ao atualizar o procedimento.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCancelEditProcedimento = () => {
    setIsEditingProcedimento(false);
    setEditingProcedimento({});
  };

  const handleDeleteProcedimento = async (id: number) => {
    if (procedimentos.length <= 1) {
      toast({
        title: "Aviso",
        description: "Não é permitido deletar todos os procedimentos de um profissional.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const confirmed = window.confirm('Tem certeza que deseja deletar este procedimento?');
    if (!confirmed) return;

    try {
      await deleteProcedimento(id);
      setProcedimentos(procedimentos.filter((p) => p.id_procedimento !== id));
    } catch (error) {
      console.error('Failed to delete procedimento', error);
      toast({
        title: "Erro",
        description: "Falha ao deletar o procedimento.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filteredProfissionais = profissionais
    .filter((profissional) => profissional.nome_profissional.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((profissional) => filterStatus === 'Todos' ? true : profissional.status_profissional === filterStatus);

  return (
    <ProfissionaisContainer>
      <SidebarContainer isOpen={isSidebarOpen}>
        <SidebarToggleButton onClick={toggleSidebar} isOpen={isSidebarOpen}>
          {isSidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
        </SidebarToggleButton>
        {isSidebarOpen && (
          <>
            <FiltersWrapper>
              <SearchInput>
                <StyledInputLeftElement>
                  <FaSearch />
                </StyledInputLeftElement>
                <input
                  type="text"
                  placeholder="Pesquisar por nome"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </SearchInput>
              <RadioWrapper>
                <Tooltip label="Todos">
                  <RadioLabel>
                    <input
                      type="radio"
                      name="status"
                      value="Todos"
                      checked={filterStatus === 'Todos'}
                      onChange={(e) => setFilterStatus(e.target.value)}
                    />
                    <FaCircle />
                  </RadioLabel>
                </Tooltip>
                <Tooltip label="Ativo">
                  <RadioLabel>
                    <input
                      type="radio"
                      name="status"
                      value="Ativo"
                      checked={filterStatus === 'Ativo'}
                      onChange={(e) => setFilterStatus(e.target.value)}
                    />
                    <FaCheckCircle />
                  </RadioLabel>
                </Tooltip>
                <Tooltip label="Inativo">
                  <RadioLabel>
                    <input
                      type="radio"
                      name="status"
                      value="Inativo"
                      checked={filterStatus === 'Inativo'}
                      onChange={(e) => setFilterStatus(e.target.value)}
                    />
                    <FaTimesCircle />
                  </RadioLabel>
                </Tooltip>
              </RadioWrapper>
              <Tooltip label="Adicionar profissional" placement="right">
                <AddProfissionalIconButton onClick={handleAddProfissional}>
                  <FaUserMd />
                </AddProfissionalIconButton>
              </Tooltip>
            </FiltersWrapper>
            <ProfissionalListContainer>
              <AnimatePresence>
                {filteredProfissionais.map((profissional, index) => (
                  <motion.div
                    key={profissional.id_profissional}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2, delay: index * 0.05 }}
                  >
                    <ProfissionalCard
                      onClick={() => setSelectedProfissionalId(profissional.id_profissional)}
                      isSelected={selectedProfissionalId === profissional.id_profissional}
                    >
                      <HStack spacing={4}>
                        <ProfileImage
                          src={profissional.url_imagem ? `${API_URL}${profissional.url_imagem}` : '/perfil_default.jpeg'}
                          alt="Imagem de Perfil"
                        />
                        <ProfissionalInfo>
                          <ProfissionalName>{profissional.nome_profissional}</ProfissionalName>
                          <ProfissionalRole>{profissional.especialidades[0] || 'Sem especialidade'}</ProfissionalRole>
                        </ProfissionalInfo>
                      </HStack>
                      <Flag $status={profissional.status_profissional.toLowerCase()} />
                    </ProfissionalCard>
                  </motion.div>
                ))}
              </AnimatePresence>
            </ProfissionalListContainer>
            <Footer>
              <Text fontSize="sm" color="gray.500">Total de profissionais: {filteredProfissionais.length}</Text>
            </Footer>
          </>
        )}
      </SidebarContainer>
      <RightContainer>
        {selectedProfissionalId && !isAdding && (
          <Tabs index={activeTabIndex} onChange={setActiveTabIndex}>
            <TabList>
              <Tab>Perfil</Tab>
              <Tab>Procedimentos</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ProfissionalProfile
                  id_profissional={selectedProfissionalId}
                  onCancel={handleCancelEdit}
                  onSave={handleSaveProfissional}
                  isAdding={false}
                  especialidades={tags}
                  convenios={convenioTags}
                  isProfissionaisModule={true}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  updateProfissionaisList={loadProfissionais}
                  especialidadeError={especialidadeError}
                  setEspecialidadeError={setEspecialidadeError}
                  convenioError={convenioError}
                  setConvenioError={setConvenioError}
                  setIsAdding={setIsAdding}
                  setSelectedProfissionalId={setSelectedProfissionalId}
                />
              </TabPanel>
              <TabPanel>
                <VStack spacing={4} align="stretch">
                  <Button
                    style={{ width: 200 }}
                    leftIcon={<FaPlus />}
                    colorScheme="blue"
                    onClick={handleAddProcedimento}
                    isDisabled={isAddingProcedimento || isEditingProcedimento}
                  >
                    Novo Procedimento
                  </Button>
                  {(isAddingProcedimento || isEditingProcedimento) && (
                    <ProcedimentoCard>
                      <VStack spacing={3} align="stretch">
                        <Input
                          placeholder="Nome do procedimento"
                          value={isEditingProcedimento ? editingProcedimento.procedimento : newProcedimento.procedimento || ''}
                          onChange={(e) => {
                            console.log('isEdittingProcedimento e isAdd', isEditingProcedimento, isAddingProcedimento);
                            if (isEditingProcedimento) {
                              setEditingProcedimento({ ...editingProcedimento, procedimento: e.target.value });
                            } else {
                              setNewProcedimento({ ...newProcedimento, procedimento: e.target.value });
                            }
                          }}
                        />
                        <Input
                          placeholder="Descrição do procedimento"
                          value={isEditingProcedimento ? editingProcedimento.ds_procedimento : newProcedimento.ds_procedimento || ''}
                          onChange={(e) => {
                            if (isEditingProcedimento) {
                              setEditingProcedimento({ ...editingProcedimento, ds_procedimento: e.target.value });
                            } else {
                              setNewProcedimento({ ...newProcedimento, ds_procedimento: e.target.value });
                            }
                          }}
                        />
                        <Select
                          placeholder="Selecione a duração"
                          value={isEditingProcedimento ? editingProcedimento.tempo_procedimento : newProcedimento.tempo_procedimento || ''}
                          onChange={(e) => {
                            if (isEditingProcedimento) {
                              setEditingProcedimento({ ...editingProcedimento, tempo_procedimento: e.target.value });
                            } else {
                              setNewProcedimento({ ...newProcedimento, tempo_procedimento: e.target.value });
                            }
                          }}
                        >
                          {Array.from({ length: 25 }, (_, i) => (i + 1) * 5).map((min) => (
                            <option key={min} value={min}>{min} min</option>
                          ))}
                        </Select>

                        <label>Valor do Procedimento</label>
                        <NumericFormat
                          customInput={Input}
                          value={isEditingProcedimento ? editingProcedimento.valor_procedimento : newProcedimento.valor_procedimento}
                          onValueChange={handleValorProcedimentoChange}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          decimalScale={2}
                          fixedDecimalScale
                          allowNegative={false}
                        />

                        <HStack spacing={2}>
                          <Button
                            leftIcon={<FaSave />}
                            colorScheme="green"
                            onClick={isEditingProcedimento ? handleUpdateProcedimento : handleSaveProcedimento}
                          >
                            {isEditingProcedimento ? 'Atualizar' : 'Salvar'}
                          </Button>
                          <Button
                            leftIcon={<FaTimes />}
                            colorScheme="red"
                            onClick={isEditingProcedimento ? handleCancelEditProcedimento : handleCancelAddProcedimento}
                          >
                            Cancelar
                          </Button>
                        </HStack>
                      </VStack>
                    </ProcedimentoCard>
                  )}
                  <SimpleGrid columns={4} spacing={4} w="100%">
                    {procedimentos.map((procedimento) => (
                      <ProcedimentoCard key={procedimento.id_procedimento}>
                        <HStack justifyContent="space-between" w="100%">
                          <VStack align="start" spacing={1}>
                            <Text fontWeight="bold">{procedimento.procedimento}</Text>
                            <Text fontSize="sm" color="gray.600">{procedimento.ds_procedimento}</Text>
                            <Text fontSize="sm" color="blue.500">Duração: {procedimento.tempo_procedimento} min</Text>
                            <Text fontWeight="bold">Valor: {procedimento.valor_procedimento}</Text>
                            <HStack>
                              <Button
                                leftIcon={<FaEdit />}
                                colorScheme="yellow"
                                onClick={() => handleEditProcedimento(procedimento)}
                                size="sm"
                                isDisabled={isAddingProcedimento || isEditingProcedimento}
                              >
                                Editar
                              </Button>
                              <Button
                                leftIcon={<FaTrash />}
                                colorScheme="red"
                                onClick={() => handleDeleteProcedimento(procedimento.id_procedimento)}
                                size="sm"
                                isDisabled={isAddingProcedimento || isEditingProcedimento}
                              >
                                Deletar
                              </Button>
                            </HStack>
                          </VStack>

                        </HStack>
                      </ProcedimentoCard>
                    ))}
                  </SimpleGrid>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        {isAdding && (
          <Tabs index={activeTabIndex} onChange={setActiveTabIndex}>
            <TabList>
              <Tab>Perfil</Tab>
              <Tab>Procedimentos</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ProfissionalProfile
                  id_profissional={null}
                  onCancel={handleCancelEdit}
                  onSave={handleSaveProfissional}
                  isAdding={true}
                  especialidades={tags}
                  convenios={convenioTags}
                  isProfissionaisModule={true}
                  isEditing={true}
                  setIsEditing={setIsEditing}
                  updateProfissionaisList={loadProfissionais}
                  especialidadeError={especialidadeError}
                  setEspecialidadeError={setEspecialidadeError}
                  convenioError={convenioError}
                  setConvenioError={setConvenioError}
                  setIsAdding={setIsAdding}
                  setSelectedProfissionalId={setSelectedProfissionalId}
                />
              </TabPanel>
              <TabPanel>
                <VStack spacing={4} align="stretch">
                  <ProcedimentoCard>
                    <HStack justifyContent="space-between" w="100%">
                      <VStack align="start" spacing={1}>
                        <Text fontWeight="bold">Consulta</Text>
                        <Text fontSize="sm" color="gray.600">Consulta Padrão</Text>
                        <Text fontSize="sm" color="blue.500">30 min</Text>
                      </VStack>
                    </HStack>
                  </ProcedimentoCard>
                </VStack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </RightContainer>
    </ProfissionaisContainer>
  );
};

export default ProfissionaisModule;
