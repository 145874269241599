import React, { useState } from 'react';
import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import Header from 'components/Header';
import Agendas from 'modules/AgendasModule';
import WPTickets from 'modules/WPTickets';
import HelpModule from 'modules/HelpModule';
import Contacts from 'modules/Contacts';
import ClinicaModule from 'modules/ClinicaModule';
import UsersModule from 'modules/UsersModule';
import ProfissionaisModule from 'modules/ProfissionaisModule';
import WPInstancias from 'modules/WPInstancias';
import PaymentFormWrapper from 'modules/PaymentForm';
import ProductsList from 'modules/PlanosModule'; // Alterar a importação
import { DefaultPageContainer, ContentContainer, LeftContainer } from '../../styles/DefaultPage.styles';

const Home: React.FC = () => {
  const [activeComponent] = useState<string>('calendario');
  const { toggleColorMode } = useColorMode();
  const currentTheme = useColorModeValue('light', 'dark');

  const renderContent = () => {
   // console.log(`Active Component: ${activeComponent}`); // Log para verificar o componente ativo
    switch (activeComponent) {
      case 'atendimento':
        return <WPTickets />;
      case 'ajuda':
        return <HelpModule />;
      case 'contacts':
        return <Contacts />;
      case 'usuarios':
        return <UsersModule />;
      case 'clinica':
        return <ClinicaModule />;
      case 'profissionais':
        return <ProfissionaisModule />;
      case 'instancias':
        return <WPInstancias />;
      case 'pagamentos':
        return <PaymentFormWrapper />;
      case 'planos':
        return <ProductsList />; // Chamar o componente ProductsList
      case 'payment': // Certificando-se de que 'payment' é um dos componentes ativos
        return <PaymentFormWrapper />;
      default:
        return <Agendas />;
    }
  };

  return (
    <>
      <Header
        theme={currentTheme}
        toggleTheme={toggleColorMode}
      />
      <DefaultPageContainer>
        <ContentContainer>
          <LeftContainer>
            {renderContent()}
          </LeftContainer>
        </ContentContainer>
      </DefaultPageContainer>
    </>
  );
};

export default Home;
