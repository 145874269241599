import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import theme from './config/theme';
import GlobalStyles from './styles/GlobalStyles';
import { store } from './store';
import { AuthProvider } from './context/authContext';
import { ThemeProvider as StyledThemeProvider } from 'styled-components'; // Importando o ThemeProvider do styled-components

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <AuthProvider>
                <Router>
                  <GlobalStyles />
                  <App />
                </Router>
            </AuthProvider>
          </StyledThemeProvider>
        </ChakraProvider>
      </Provider>
    </React.StrictMode>
  );
} else {
  console.error('Root element not found');
}
