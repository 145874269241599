import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer, Views, SlotInfo } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  AgendasContainer,
  SidebarContainer,
  CalendarContainer,
  Footer,
  SearchInput,
  AgendaList,
  AgendaItem,
  AgendaItemProfile,
  AgendaItemName,
  AgendaItemStatus,
  CalendarWrapper,
  FiltersWrapper,
  RadioWrapper,
  RadioLabel,
  Flag,
  SidebarToggleButton,
  CalendarHeader,
  ProfissionalInfo,
  EventTitle,
  EventDescription,
  EventTime,
  TooltipContent,
  NewAppointmentButton,
  StyledInputLeftElement,
} from './AgendasModule.styles';
import { fetchProfissionais, Profissional } from 'services/profissionaisService';
import {
  fetchAgendasByProfissional,
  createAgenda,
  updateAgenda,
  deleteAgenda,
  Agenda,
} from 'services/agendasService';
import Config from 'config/Config';
import {
  FaCheckCircle,
  FaTimesCircle,
  FaCircle,
  FaSearch,
  FaCalendarAlt,
  FaTrash,
  FaSave,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Select,
  FormControl,
  FormLabel,
  useDisclosure,
  useToast,
  Tooltip,
  VStack,
  HStack,
  Text,
  Box,
} from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import { useAuth } from 'context/authContext';
import { motion, AnimatePresence } from 'framer-motion';

moment.locale('pt-br');
const localizer = momentLocalizer(moment);

const API_URL = Config.API_URL;

interface CustomEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  resource: Agenda;
  color: string;
  description: string;
}

const AgendasModule: React.FC = () => {
  const { userData } = useAuth();
  const [profissionais, setProfissionais] = useState<Profissional[]>([]);
  const [filteredProfissionais, setFilteredProfissionais] = useState<Profissional[]>([]);
  const [view, setView] = useState<typeof Views[keyof typeof Views]>(Views.WEEK);
  const [events, setEvents] = useState<CustomEvent[]>([]);
  const [filterStatus, setFilterStatus] = useState('Todos');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentEvent, setCurrentEvent] = useState<Partial<Agenda>>({});
  const [selectedProfissional, setSelectedProfissional] = useState<Profissional | null>(null);
  const [planosSaude, setPlanosSaude] = useState<string[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Estado para controlar a sidebar
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [lastClick, setLastClick] = useState<number | null>(null);

  const handleAgendaClick = useCallback(
    async (profissional: Profissional) => {
      try {
        setSelectedProfissional(profissional);
        const response = await fetchAgendasByProfissional(profissional.id_profissional);
        const agendas = Array.isArray(response) ? response : [response];
        setEvents(
          agendas.map((agenda) => ({
            id: agenda.id_agenda,
            title: agenda.titulo,
            start: new Date(agenda.data_inicio),
            end: new Date(agenda.data_fim),
            allDay: false,
            resource: agenda,
            color: agenda.cor,
            description: agenda.descricao || 'Sem descrição',
          }))
        );

        if (profissional.convenios) {
          setPlanosSaude(profissional.convenios);
        } else {
          setPlanosSaude([]);
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response && error.response.status === 404) {
          setEvents([]);
        } else {
          console.error('Falha ao carregar agendas', error);
          toast({
            title: 'Erro',
            description: 'Falha ao carregar agendas. Veja detalhes no console.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    },
    [setSelectedProfissional, setEvents, setPlanosSaude, toast]
  );

  useEffect(() => {
    const loadProfissionais = async () => {
      try {
        if (!userData?.id_clinica) {
          throw new Error('ID da clínica não encontrado.');
        }
        const data = await fetchProfissionais(userData.id_clinica);
        data.sort((a, b) => a.nome_profissional.localeCompare(b.nome_profissional));
        setProfissionais(data);
        setFilteredProfissionais(data);

        if (data.length > 0) {
          handleAgendaClick(data[0]);
        }
      } catch (error) {
        console.error('Falha ao carregar profissionais', error);
        toast({
          title: 'Erro',
          description: 'Falha ao carregar profissionais. Veja detalhes no console.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (userData) {
      loadProfissionais();
    }
  }, [userData, toast, handleAgendaClick]);

  useEffect(() => {
    let filtered = profissionais;

    if (filterStatus !== 'Todos') {
      filtered = filtered.filter((profissional) => profissional.status_profissional === filterStatus);
    }

    if (searchTerm) {
      filtered = filtered.filter((profissional) =>
        profissional.nome_profissional.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredProfissionais(filtered);
  }, [profissionais, filterStatus, searchTerm]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(e.target.value);
  };

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    const now = Date.now();

    if (lastClick && now - lastClick < 300) {
      handleDoubleClickSlot(slotInfo);
      setLastClick(null);
    } else {
      setLastClick(now);
    }
  };

  const handleDoubleClickSlot = (slotInfo: SlotInfo) => {
    const data_inicio = slotInfo.start;
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);

    if (data_inicio < yesterday) {
      toast({
        title: 'Erro',
        description: 'Não é possível criar um evento para um período passado.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const data_fim = new Date(data_inicio.getTime() + 30 * 60000);

    setCurrentEvent({
      data_inicio,
      data_fim,
      titulo: '',
      descricao: '',
      cor: '#FFDA33',
      origem: 'manual',
      profissional: {
        id_profissional: selectedProfissional?.id_profissional || 0,
        nome_profissional: selectedProfissional?.nome_profissional || '',
      },
    });

    setTimeout(() => {
      onOpen();
    }, 0);
  };

  const handleSelectEvent = (event: CustomEvent) => {
    setCurrentEvent(event.resource);
    onOpen();
  };

  const handleDeleteEvent = async () => {
    if (!currentEvent.id_agenda) return;

    const confirmed = window.confirm('Você tem certeza que deseja deletar este evento?');
    if (confirmed) {
      try {
        await deleteAgenda(currentEvent.id_agenda);
        setEvents(events.filter((e) => e.id !== currentEvent.id_agenda));
        onClose();
        toast({
          title: 'Sucesso',
          description: 'Evento deletado com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Falha ao deletar evento', error);
        toast({
          title: 'Erro',
          description: 'Falha ao deletar evento. Veja detalhes no console.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleSaveEvent = async () => {
    if (
      !selectedProfissional ||
      !currentEvent.titulo ||
      !currentEvent.data_inicio ||
      !currentEvent.data_fim
    ) {
      toast({
        title: 'Erro',
        description: 'Preencha todos os campos obrigatórios.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (currentEvent.data_inicio >= currentEvent.data_fim) {
      toast({
        title: 'Erro',
        description: 'Data de início deve ser menor que a data de fim.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const eventWithDefaults: Agenda = {
        ...currentEvent,
        cor: '#FFDA33',
        origem: 'manual',
        profissional: {
          id_profissional: selectedProfissional.id_profissional,
          nome_profissional: selectedProfissional.nome_profissional,
        },
      } as Agenda;

      let updatedOrNewEvent: Agenda;
      if (currentEvent.id_agenda) {
        updatedOrNewEvent = await updateAgenda(currentEvent.id_agenda, eventWithDefaults);
      } else {
        updatedOrNewEvent = await createAgenda(
          selectedProfissional.id_profissional,
          eventWithDefaults
        );
      }

      setEvents((prevEvents) => {
        const newEvents = currentEvent.id_agenda
          ? prevEvents.map((e) =>
              e.id === updatedOrNewEvent.id_agenda
                ? {
                    ...e,
                    title: updatedOrNewEvent.titulo,
                    start: new Date(updatedOrNewEvent.data_inicio),
                    end: new Date(updatedOrNewEvent.data_fim),
                    resource: updatedOrNewEvent,
                    color: updatedOrNewEvent.cor,
                    description: updatedOrNewEvent.descricao || 'Sem descrição',
                  }
                : e
            )
          : [
              ...prevEvents,
              {
                id: updatedOrNewEvent.id_agenda,
                title: updatedOrNewEvent.titulo,
                start: new Date(updatedOrNewEvent.data_inicio),
                end: new Date(updatedOrNewEvent.data_fim),
                allDay: false,
                resource: updatedOrNewEvent,
                color: updatedOrNewEvent.cor,
                description: updatedOrNewEvent.descricao || 'Sem descrição',
              },
            ];
        return newEvents;
      });

      onClose();
      toast({
        title: 'Sucesso',
        description: 'Evento salvo com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Falha ao salvar evento', error);
      toast({
        title: 'Erro',
        description: 'Falha ao salvar evento. Veja detalhes no console.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const eventStyleGetter = (event: CustomEvent) => ({
    style: {
      backgroundColor: event.color || '#3174ad',
      borderRadius: '4px',
      opacity: 0.8,
      color: 'white',
      border: 'none',
      display: 'block',
      fontSize: '14px',
      padding: '2px 5px',
    },
  });

  const EventComponent = ({ event }: { event: CustomEvent }) => (
    <Tooltip
      label={
        <TooltipContent>
          <EventTitle>{event.title}</EventTitle>
          <EventDescription>{event.description}</EventDescription>
          <EventTime>
            {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
          </EventTime>
          <Text>Convênio: {event.resource.convenio || 'N/A'}</Text>
        </TooltipContent>
      }
    >
      <Box className="rbc-event-content">{event.title}</Box>
    </Tooltip>
  );

  const formats = {
    eventTimeRangeFormat: () => '',
    dayHeaderFormat: (date: Date) => moment(date).format('ddd, D [de] MMMM'),
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <AgendasContainer>
      <SidebarContainer isOpen={isSidebarOpen}>
        <SidebarToggleButton onClick={toggleSidebar} isOpen={isSidebarOpen}>
          {isSidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
        </SidebarToggleButton>
        {isSidebarOpen && (
          <>
            <FiltersWrapper>
              <SearchInput>
                <StyledInputLeftElement>
                  <FaSearch />
                </StyledInputLeftElement>
                <Input placeholder="Pesquisar por nome" onChange={handleSearch} />
              </SearchInput>
              <RadioWrapper>
                <Tooltip label="Todos">
                  <RadioLabel>
                    <input
                      type="radio"
                      name="status"
                      value="Todos"
                      checked={filterStatus === 'Todos'}
                      onChange={handleStatusChange}
                    />
                    <FaCircle />
                  </RadioLabel>
                </Tooltip>
                <Tooltip label="Ativo">
                  <RadioLabel>
                    <input
                      type="radio"
                      name="status"
                      value="Ativo"
                      checked={filterStatus === 'Ativo'}
                      onChange={handleStatusChange}
                    />
                    <FaCheckCircle />
                  </RadioLabel>
                </Tooltip>
                <Tooltip label="Inativo">
                  <RadioLabel>
                    <input
                      type="radio"
                      name="status"
                      value="Inativo"
                      checked={filterStatus === 'Inativo'}
                      onChange={handleStatusChange}
                    />
                    <FaTimesCircle />
                  </RadioLabel>
                </Tooltip>
              </RadioWrapper>
            </FiltersWrapper>
            <AgendaList>
              <AnimatePresence>
                {filteredProfissionais.map((profissional, index) => (
                  <motion.div
                    key={profissional.id_profissional}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2, delay: index * 0.05 }}
                  >
                    <AgendaItem
                      onClick={() => handleAgendaClick(profissional)}
                      isSelected={
                        selectedProfissional?.id_profissional === profissional.id_profissional
                      }
                    >
                      <AgendaItemProfile
                        src={
                          profissional.url_imagem
                            ? `${API_URL}${profissional.url_imagem}`
                            : '/perfil_default.jpeg'
                        }
                        alt="Imagem de Perfil"
                      />
                      <AgendaItemName>{profissional.nome_profissional}</AgendaItemName>
                      <AgendaItemStatus>
                        <Flag $status={profissional.status_profissional.toLowerCase()} />
                      </AgendaItemStatus>
                    </AgendaItem>
                  </motion.div>
                ))}
              </AnimatePresence>
            </AgendaList>
            <Footer>
              <Text fontSize="sm" color="gray.500">
                Total de profissionais: {filteredProfissionais.length}
              </Text>
            </Footer>
          </>
        )}
      </SidebarContainer>
      <CalendarContainer>
        <CalendarHeader>
          <HStack spacing={4}>
            <FaCalendarAlt size={24} />
            {selectedProfissional && (
              <ProfissionalInfo>
                <Text fontSize="md">{selectedProfissional.nome_profissional}</Text>
                <Text fontSize="sm" color="gray.500">
                  {selectedProfissional.especialidades}
                </Text>
              </ProfissionalInfo>
            )}
          </HStack>
          <NewAppointmentButton
            text="Novo Agendamento"
            onClick={() =>
              handleDoubleClickSlot({
                start: new Date(),
                end: new Date(),
                slots: [],
                action: 'select',
              })
            }
            leftIcon={<FaCalendarAlt />}
          />
        </CalendarHeader>
        <CalendarWrapper>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 'calc(100vh - 180px)' }}
            view={view}
            onView={(newView) => setView(newView)}
            selectable
            onSelecting={() => false}
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            eventPropGetter={eventStyleGetter}
            components={{
              event: EventComponent,
            }}
            formats={formats}
            min={new Date(new Date().setHours(6, 0, 0, 0))}
            max={new Date(new Date().setHours(20, 0, 0, 0))}
            messages={{
              date: 'Data',
              time: 'Hora',
              event: 'Evento',
              allDay: 'Dia inteiro',
              week: 'Semana',
              work_week: 'Semana de trabalho',
              day: 'Dia',
              month: 'Mês',
              previous: 'Anterior',
              next: 'Próximo',
              yesterday: 'Ontem',
              tomorrow: 'Amanhã',
              today: 'Hoje',
              agenda: 'Agenda',
              noEventsInRange: 'Não há eventos neste período',
              showMore: (total: number) => `+ Ver mais (${total})`,
            }}
          />
        </CalendarWrapper>
      </CalendarContainer>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {currentEvent.id_agenda ? 'Editar Agendamento' : 'Novo Agendamento'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Título</FormLabel>
                <Input
                  placeholder="Título do agendamento"
                  value={currentEvent.titulo || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentEvent({ ...currentEvent, titulo: e.target.value })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Observações</FormLabel>
                <Input
                  placeholder="Observações adicionais"
                  value={currentEvent.descricao || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentEvent({ ...currentEvent, descricao: e.target.value })
                  }
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Convênio</FormLabel>
                <Select
                  value={currentEvent.convenio || ''}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setCurrentEvent({ ...currentEvent, convenio: e.target.value })
                  }
                  placeholder="Selecione um convênio"
                >
                  {planosSaude.map((plano, index) => (
                    <option key={index} value={plano}>
                      {plano}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Data e Hora de Início</FormLabel>
                <Input
                  type="datetime-local"
                  value={
                    currentEvent.data_inicio
                      ? moment(currentEvent.data_inicio).format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const data_inicio = new Date(e.target.value);
                    const data_fim = new Date(data_inicio.getTime() + 30 * 60000);
                    setCurrentEvent({ ...currentEvent, data_inicio, data_fim });
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Data e Hora de Término</FormLabel>
                <Input
                  type="datetime-local"
                  value={
                    currentEvent.data_fim
                      ? moment(currentEvent.data_fim).format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentEvent({ ...currentEvent, data_fim: new Date(e.target.value) })
                  }
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={3}>
              {currentEvent.id_agenda && (
                <CustomButton
                  text="Excluir"
                  colorScheme="red"
                  onClick={handleDeleteEvent}
                  leftIcon={<FaTrash />}
                />
              )}
              <CustomButton text="Cancelar" colorScheme="gray" onClick={onClose} />
              <CustomButton
                text="Salvar"
                colorScheme="blue"
                onClick={handleSaveEvent}
                leftIcon={<FaSave />}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AgendasContainer>
  );
};

export default AgendasModule;
