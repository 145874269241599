import axios from 'axios';
import Config from 'config/Config';
import { getMessageByStatus } from 'helpers/messages.helper';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Interfaces
export interface UserData {
  email_usuario: string;
  nome_usuario: string;
  senha_usuario: string;
}

export interface LoginData {
  email_usuario: string;
  senha_usuario: string;
}

export interface StripeCustomer {
  stripeCustomerId: string;
  name: string;
  email: string;
  id: string;
}

export interface User {
  id_users: string;
  nome_usuario: string;
  email_usuario: string;
  senha_usuario?: string;
  url_imagem: string;
  privilegio_usuario: string;
  status_usuario: string;
  fone_user: string;
  clinica: string;
  stripeCustomer?: StripeCustomer;
}

const handleError = (error: any): Promise<any> => {
  console.error('Erro durante o processo:', error);
  const status = error.response?.status;
  const message = error.response?.data?.message || getMessageByStatus(status) || 'Ocorreu um erro desconhecido.';
  return Promise.reject(new Error(message));
};

export const register = async (data: UserData) => {
  try {
    //console.log('Enviando dados de registro:', data);

    const response = await axios({
      url: `${Config.API_URL}/api/users/create-admin-clinica`,
      method: 'POST',
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: Config.HEADER_REQUEST,
    });

    //console.log('Resposta de registro recebida:', response);
    return Promise.resolve(response);
  } catch (error: any) {
    return handleError(error);
  }
};

export const login = async (data: LoginData) => {
  try {
    const response = await axios({
      url: `${Config.API_URL}/api/auth/login`,
      method: 'POST',
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: Config.HEADER_REQUEST,
    });

    const token = response.data.access_token;
    if (!token) {
      throw new Error('Token de acesso não encontrado na resposta.');
    }

    await AsyncStorage.setItem('TOKEN', token);

    const userResponse = await axios({
      url: `${Config.API_URL}/api/users/profile`,
      method: 'GET',
      headers: {
        ...Config.HEADER_REQUEST,
        Authorization: `Bearer ${token}`,
      },
      params: {
        email_usuario: data.email_usuario,
      },
    });

    const userData = userResponse.data;


    if (userData.status_usuario.toLowerCase() !== 'ativo') {
      throw new Error('Usuário inativo. Entre em contato com o suporte.');
    }

    // Captura e adiciona stripeCustomerId ao contexto de autenticação
    const stripeCustomerResponse = await axios.get(`${Config.API_URL}/api/stripe/customer/${userData.id_users}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (stripeCustomerResponse && stripeCustomerResponse.data) {
      userData.stripeCustomerId = stripeCustomerResponse.data.stripeCustomerId;
    }

    return userData;
  } catch (error: any) {
    return handleError(error);
  }
};


export const loginWithToken = async (data: { token: string }) => {
  try {
    //console.log('Iniciando login com token:', data);

    const response = await axios({
      url: `${Config.API_URL}/api/token/refresh`,
      method: 'POST',
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: Config.HEADER_REQUEST,
    });

    if (response && response.data && response.data.access_token) {
      await AsyncStorage.setItem('TOKEN', response.data.access_token);
      //console.log('Token atualizado e armazenado no AsyncStorage:', response.data.access_token);
      return response;
    } else {
      throw new Error('Token de acesso não encontrado na resposta.');
    }
  } catch (error: any) {
    return handleError(error);
  }
};

export const getUserProfile = async (token: string) => {
  try {
    // console.log('Obtendo perfil do usuário com token:', token);

    const response = await axios({
      url: `${Config.API_URL}/api/users/profile`,
      method: 'GET',
      headers: {
        ...Config.HEADER_REQUEST,
        Authorization: `Bearer ${token}`,
      },
    });

    if (response && response.data) {
      //console.log('Perfil do usuário obtido com sucesso, resposta:', response);
      return response;
    } else {
      throw new Error('Perfil do usuário não encontrado');
    }
  } catch (error: any) {
    return handleError(error);
  }
};

export const getUsersByClinicId = async (clinicId: string) => {
  try {
    // console.log('Obtendo usuários por ID da clínica:', clinicId);

    const response = await axios({
      url: `${Config.API_URL}/api/users/clinica/${clinicId}`,
      method: 'GET',
      timeout: Config.TIMEOUT_REQUEST,
      headers: Config.HEADER_REQUEST,
    });

    // console.log('Usuários recebidos:', response.data);
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};

export const getUserById = async (userId: string) => {
  try {
    // console.log('Obtendo usuário por ID:', userId);

    const response = await axios({
      url: `${Config.API_URL}/api/users/${userId}`,
      method: 'GET',
      timeout: Config.TIMEOUT_REQUEST,
      headers: Config.HEADER_REQUEST,
    });

    //console.log('Dados do usuário recebidos:', response.data);
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};

export const createUser = async (data: Partial<User>) => {
  try {
    // console.log('Criando usuário com dados:', data);

    const response = await axios({
      url: `${Config.API_URL}/api/users`,
      method: 'POST',
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: Config.HEADER_REQUEST,
    });

    //console.log('Usuário criado com sucesso, resposta:', response);
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};

export const updateUser = async (userId: string, data: Partial<User>) => {
  try {
    // console.log('Atualizando usuário com dados:', data);

    const response = await axios({
      url: `${Config.API_URL}/api/users/${userId}`,
      method: 'PATCH',
      timeout: Config.TIMEOUT_REQUEST,
      data: data,
      headers: Config.HEADER_REQUEST,
    });

    // console.log('Usuário atualizado com sucesso, resposta:', response);
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};

export const deleteUser = async (userId: string) => {
  try {
    //console.log('Deletando usuário com ID:', userId);

    const response = await axios({
      url: `${Config.API_URL}/api/users/${userId}`,
      method: 'DELETE',
      timeout: Config.TIMEOUT_REQUEST,
      headers: Config.HEADER_REQUEST,
    });

    // console.log('Usuário deletado com sucesso, resposta:', response);
    return response.data;
  } catch (error: any) {
    return handleError(error);
  }
};
