import React, { useState, useEffect } from 'react';
import { Avatar, Box, Typography, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Chip } from '@mui/material';
import { BsX, BsCheck, BsEye, BsClock } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';
import { styled } from '@mui/system';
import { tooltipClasses } from '@mui/material/Tooltip';
import ViewChatModal from './ViewChatModal';
import { Chat } from '../types';
import whatsAppMultTicketService from 'services/whatsAppMultTicketService';

const lightHeaderColor = '#8DA4CC';

interface CardPerfilProps {
  chat: Chat;
  changeCurrent: (id: string) => void;
  endChat: (id: string) => void;
  acceptChat: (id: string) => void;
  viewChat: (id: string) => void;
  setTabIndex: (index: number) => void;
  setSubTabIndex: (index: number) => void;
}

const CustomTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '8px',
  },
});

const ProfileImage = styled(Avatar)({
  width: 40,
  height: 40,
  border: `2px solid ${lightHeaderColor}`,
});

const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '12px',
  borderBottom: '1px solid #e0e0e0',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(141, 164, 204, 0.1)',
  },
  position: 'relative',
  minHeight: '80px', // Set a minimum height to prevent collapsing
}));

const StatusDot = styled('div')<{ status: string }>(({ status }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: status === 'open' ? '#48bb78' : '#f56565',
  marginLeft: 'auto',
}));

const CardPerfil: React.FC<CardPerfilProps> = ({ chat, changeCurrent, endChat, acceptChat, viewChat, setTabIndex, setSubTabIndex }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  const lastMessage = chat.messages[chat.messages.length - 1];

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (!chat.session) {
        console.error('Session ID is undefined for chat:', chat);
        return;
      }

      try {
        console.log('Fetching profile picture for session:', chat.session, 'and user:', chat.userId);
        const picture = await whatsAppMultTicketService.getProfilePicture(chat.session.id, chat.userId);
        if (picture) {
          setProfilePicture(picture);
        } else {
          console.warn('No profile picture returned for user:', chat.userId);
        }
      } catch (error) {
        console.error('Failed to fetch profile picture', error);
      }
    };

    fetchProfilePicture();
  }, [chat, chat.session, chat.userId]);

  const handleCardClick = () => {
    if (chat.status === 'closed' || chat.classification === 'ASSIGNED') {
      changeCurrent(chat.id);
    }
  };

  const handleViewChat = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleAcceptChat = (e: React.MouseEvent) => {
    e.stopPropagation();
    acceptChat(chat.id);
    setTabIndex(0);
    setSubTabIndex(0);
  };

  const handleEndChat = (e: React.MouseEvent) => {
    e.stopPropagation();
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirmEndChat = () => {
    endChat(chat.id);
    setConfirmOpen(false);
  };

  const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diffInHours = (now.getTime() - date.getTime()) / (1000 * 60 * 60);

    if (diffInHours < 24) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString();
    }
  };

  return (
    <>
      <CardContainer onClick={handleCardClick}>
      <ProfileImage 
          alt={chat.userName} 
          src={profilePicture || chat.userImage || '/default-avatar.png'} 
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null;
            target.src = '/default-avatar.png';
          }}
        />      
        <Box sx={{ flexGrow: 1, marginLeft: '16px', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', color: lightHeaderColor }}>
            <FaWhatsapp size={16} style={{ marginRight: '5px', color: lightHeaderColor }} /> {chat.userName}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {lastMessage ? lastMessage.body.substring(0, 50) + (lastMessage.body.length > 50 ? '...' : '') : ''}
          </Typography>
          <Box sx={{ display: 'flex', gap: '5px', marginTop: '5px', flexWrap: 'wrap' }}>
            {chat.tags?.map((tag, index) => (
              <Chip
                key={`${chat.id}-tag-${index}`}
                label={tag}
                size="small"
                sx={{ backgroundColor: 'rgba(141, 164, 204, 0.2)', color: lightHeaderColor, borderRadius: '16px' }}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minWidth: '70px' }}>
          <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', color: 'text.secondary' }}>
            <BsClock style={{ marginRight: '4px' }} />
            {lastMessage ? formatTimestamp(lastMessage.timestamp) : ''}
          </Typography>
          <Box sx={{ display: 'flex', gap: '5px', marginTop: '5px', justifyContent: 'flex-end' }}>
            {chat.status === 'open' && chat.classification !== 'ASSIGNED' && (
              <CustomTooltip title="Aceitar Chat" arrow>
                <IconButton
                  onClick={handleAcceptChat}
                  size="small"
                  sx={{ backgroundColor: lightHeaderColor, color: 'white', '&:hover': { backgroundColor: '#7B93B8' } }}
                >
                  <BsCheck />
                </IconButton>
              </CustomTooltip>
            )}
            {chat.status === 'open' && (
              <CustomTooltip title="Encerrar Chat" arrow>
                <IconButton
                  onClick={handleEndChat}
                  size="small"
                  sx={{ backgroundColor: '#ff4d4d', color: 'white', '&:hover': { backgroundColor: '#ff3333' } }}
                >
                  <BsX />
                </IconButton>
              </CustomTooltip>
            )}
            <CustomTooltip title="Ver Chat" arrow>
              <IconButton
                onClick={handleViewChat}
                size="small"
                sx={{ backgroundColor: lightHeaderColor, color: 'white', '&:hover': { backgroundColor: '#7B93B8' } }}
              >
                <BsEye />
              </IconButton>
            </CustomTooltip>
          </Box>
        </Box>
        <StatusDot status={chat.status} />
      </CardContainer>
      <ViewChatModal open={modalOpen} onClose={handleCloseModal} chat={chat} />
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Confirmar Ação</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja encerrar este chat?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmEndChat} color="primary" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardPerfil;