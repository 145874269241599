// src/services/emailService.ts

import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;

export const sendReportEmail = async (data: any) => {
  try {
    const response = await axios.post(`${API_URL}/api/emails/report`, data);
    return response.data;
  } catch (error) {
    console.error('Error sending report email:', error);
    throw new Error('Failed to send report email.');
  }
  
};
