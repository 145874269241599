import React, { useEffect, useRef } from 'react';
import { Modal, Box, Typography, IconButton, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import { BsX } from 'react-icons/bs';
import { Chat } from '../types';

interface ViewChatModalProps {
  open: boolean;
  onClose: () => void;
  chat: Chat | null;
}

const MessageBubble = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isAttendant'
})<{ isAttendant: boolean }>(({ isAttendant }) => ({
  marginBottom: '10px',
  maxWidth: '60%',
  padding: '10px',
  borderRadius: '15px',
  position: 'relative',
  backgroundColor: isAttendant ? '#dcf8c6' : '#ffffff',
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
  alignSelf: isAttendant ? 'flex-end' : 'flex-start',
  color: '#000000',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '10px',
    [isAttendant ? 'right' : 'left']: '-10px',
    borderWidth: '10px',
    borderStyle: 'solid',
    borderColor: isAttendant
      ? 'transparent #dcf8c6 transparent transparent'
      : 'transparent transparent transparent #ffffff',
  },
}));

const ChatContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

const formatMessage = (text: string) => {
  text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
  text = text.replace(/~~(.*?)~~/g, '<del>$1</del>');
  text = text.replace(/```(.*?)```/g, '<code>$1</code>');
  text = text.replace(/\n/g, '<br />');
  return text;
};

const ViewChatModal: React.FC<ViewChatModalProps> = ({ open, onClose, chat }) => {
  const chatEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    if (chat) scrollToBottom();
  }, [chat]);

  if (!chat) return null;

  return (
    <Modal open={open} onClose={onClose} onKeyDown={(e) => e.key === 'Escape' && onClose()}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        height: '80%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(/wa-background-light.png)`,
        backgroundSize: 'cover',
        overflowY: 'auto',
        borderRadius: '10px',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={chat.userName} src={chat.userImage} />
            <Typography variant="h6" sx={{ marginLeft: '10px' }}>{chat.userName}</Typography>
          </Box>
          <IconButton onClick={onClose} sx={{ color: 'gray' }}>
            <BsX />
          </IconButton>
        </Box>
        <ChatContainer sx={{ flexGrow: 1, position: 'relative', overflowY: 'auto', padding: '10px', scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}>
        {chat.messages.slice().map((message) => (
            <MessageBubble key={`${chat.id}-${message.id}`} isAttendant={message.from !== chat.userId}>
              <Typography 
                variant="body2" 
                color="textPrimary"
                dangerouslySetInnerHTML={{ __html: formatMessage(message.body) }}
              />
              <Typography variant="caption" display="block" color="textSecondary">
                {new Date(message.timestamp).toLocaleString()}
              </Typography>
            </MessageBubble>
          ))}
          <div ref={chatEndRef} />
        </ChatContainer>
      </Box>
    </Modal>
  );
};

export default ViewChatModal;