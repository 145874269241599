import React from 'react';
import styled from 'styled-components';

interface ToggleProps {
  isDarkMode: boolean;
  toggleTheme: () => void;
}

const Toggle: React.FC<ToggleProps> = ({ isDarkMode, toggleTheme }) => {
  return (
    <ToggleContainer onClick={toggleTheme} aria-label="Toggle Dark Mode">
      <ToggleCircle $isDarkMode={isDarkMode} />
    </ToggleContainer>
  );
};

const ToggleContainer = styled.div`
  width: 50px;
  height: 24px;
  background-color: #c8f577;
  border-radius: 12px;
  padding: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ToggleCircle = styled.div<{ $isDarkMode: boolean }>`
  width: 20px;
  height: 20px;
  background-color: #8da4cc;
  border-radius: 50%;
  transform: ${({ $isDarkMode }) =>
    $isDarkMode ? 'translateX(26px)' : 'translateX(0)'};
  transition: transform 0.3s ease;
`;

export default Toggle;
