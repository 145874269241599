import React, { useState } from 'react';
import { Box, Button, Text, Stack, Divider, List, ListItem, ListIcon, useToast } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { Product } from 'modules/PlanosModule/types';
import { createCheckoutSession } from 'services/stripeService';
import { useAuth } from '../../context/authContext';

interface PricingCardProps {
  product: Product;
  isAnnual: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({ product, isAnnual }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const { userData } = useAuth(); // Acessa os dados do usuário no contexto

  const monthlyPrice = product.prices.find(price => price.nickname?.includes('Mensal'));
  const annualPrice = product.prices.find(price => price.nickname?.includes('Anual'));

  const selectedPrice = isAnnual && annualPrice ? annualPrice : monthlyPrice;


  const handleRenewClick = async () => {
    if (!userData || !userData.stripeCustomer?.stripeCustomerId) {
      toast({
        title: 'Erro',
        description: 'Informações do cliente não encontradas.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    try {
      const requestData = {
        customerId: userData.stripeCustomer.stripeCustomerId,
        priceId: selectedPrice?.stripePriceId || '',
        paymentMethodId: 'card',
        userId: userData.id_users, // Certifique-se de que userId está sendo passado
        clinicaId: userData.id_clinica, // Certifique-se de que clinicaId está sendo passado
      };

      const response = await createCheckoutSession(requestData);

      if (response?.url) {
        window.location.href = response.url;
      } else {
        toast({
          title: 'Erro',
          description: 'URL de checkout não retornada. Por favor, tente novamente.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Falha ao iniciar o processo de pagamento. Por favor, tente novamente.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box border="1px solid #e2e8f0" borderRadius="8px" padding="16px" width="250px" boxShadow="md">
      <Text fontSize="xl" fontWeight="bold" textAlign="center">{product.name}</Text>
      <Text textAlign="center" marginBottom="8px">
        {selectedPrice ?
          `${(selectedPrice.unitAmount / 100).toLocaleString('pt-BR', {
            style: 'currency',
            currency: selectedPrice.currency.toUpperCase()
          })}/${selectedPrice.interval === 'year' ? 'ano' : selectedPrice.interval === 'month' ? 'mês' : selectedPrice.interval}`
          : 'N/A'}

      </Text>
      <Button
        width="100%"
        colorScheme="blue"
        marginBottom="16px"
        onClick={handleRenewClick}
        isLoading={isLoading}
        loadingText="Processando"
      >
        Renovar agora
      </Button>
      <Text fontSize="sm" textAlign="center" marginBottom="16px">
        A assinatura continua até ser cancelada.
      </Text>
      <Divider marginBottom="16px" />
      <Text fontSize="lg" fontWeight="bold" marginBottom="8px">Aplicativos:</Text>
      <Stack direction="row" spacing={4} justifyContent="center" marginBottom="16px">
        {/* Aqui você pode adicionar ícones ou imagens representando os aplicativos */}
      </Stack>
      <Text fontSize="lg" fontWeight="bold" marginBottom="8px">Recursos:</Text>
      <List spacing={3}>
        {Object.entries(product.metadata).map(([key, value]) => (
          <ListItem key={key}>
            <ListIcon as={CheckIcon} color="blue.500" />
            {value}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default PricingCard;
