import React, { useState } from 'react';
import { Box, Tabs, Tab, InputBase, Typography, IconButton, Badge, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { BsFilter, BsInbox, BsCheckCircle, BsSearch, BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import CardPerfil from '../components/CardPerfil';
import { Chat } from '../types';

const lightHeaderColor = '#8DA4CC';

const SidebarContainer = styled(Box)<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  width: isOpen ? '350px' : '60px',
  minWidth: isOpen ? '350px' : '60px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRight: '1px solid #ddd',
  backgroundColor: '#fff',
  transition: 'all 0.3s ease',
  overflow: 'hidden',
  position: 'relative',
}));

const SidebarToggleButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  zIndex: 10,
  backgroundColor: lightHeaderColor,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: 60,
  fontWeight: 'bold',
  fontSize: '0.875rem',
  color: lightHeaderColor,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

interface LeftContainerProps {
  chats: {
    list: Chat[];
    current: string | null;
  };
  changeCurrent: (id: string) => void;
  endChat: (id: string) => void;
  acceptChat: (id: string) => void;
  viewChat: (id: string) => void;
  tabIndex: number;
  setTabIndex: (index: number) => void;
  subTabIndex: number;
  setSubTabIndex: (index: number) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const LeftContainer: React.FC<LeftContainerProps> = ({
  chats,
  changeCurrent,
  endChat,
  acceptChat,
  viewChat,
  tabIndex,
  setTabIndex,
  subTabIndex,
  setSubTabIndex,
  isSidebarOpen,
  toggleSidebar,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSubTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSubTabIndex(newValue);
  };

  const filteredChats = (status: 'open' | 'closed', classification?: 'ASSIGNED' | 'PENDING') => {
    return chats.list.filter(chat => chat.status === status && (classification ? chat.classification === classification : true));
  };

  const searchedChats = chats.list.filter(chat => 
    chat.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    chat.messages.some(message => 
      message.body.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleIconClick = (newTabIndex: number, newSubTabIndex?: number) => {
    setTabIndex(newTabIndex);
    if (newSubTabIndex !== undefined) {
      setSubTabIndex(newSubTabIndex);
    }
    if (!isSidebarOpen) {
      toggleSidebar();
    }
  };

  const renderChats = () => {
    let chatsToRender: Chat[] = [];
    if (tabIndex === 0) {
      chatsToRender = subTabIndex === 0 
        ? filteredChats('open', 'ASSIGNED') 
        : filteredChats('open', 'PENDING');
    } else if (tabIndex === 1) {
      chatsToRender = filteredChats('closed');
    } else if (tabIndex === 2) {
      chatsToRender = searchedChats;
    }

    return chatsToRender.map((chat) => (
      <CardPerfil
        key={chat.id}
        chat={chat}
        changeCurrent={changeCurrent}
        endChat={endChat}
        acceptChat={acceptChat}
        viewChat={viewChat}
        setTabIndex={setTabIndex}
        setSubTabIndex={setSubTabIndex}
      />
    ));
  };

  return (
    <SidebarContainer isOpen={isSidebarOpen}>
      <SidebarToggleButton 
        onClick={toggleSidebar}
        sx={{ right: isSidebarOpen ? '-20px' : '10px' }}
      >
        {isSidebarOpen ? <BsChevronLeft /> : <BsChevronRight />}
      </SidebarToggleButton>
      {isSidebarOpen ? (
        <>
          <Box sx={{ bgcolor: lightHeaderColor, color: 'white', p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Minner AI Clínica</Typography>
          </Box>
          <Tabs 
            value={tabIndex} 
            onChange={handleTabChange} 
            variant="fullWidth" 
            sx={{ 
              bgcolor: '#ECE5DD',
              '& .MuiTabs-indicator': {
                backgroundColor: lightHeaderColor,
              },
            }}
          >
            <StyledTab 
              icon={<StyledBadge badgeContent={filteredChats('open').length} color="secondary"><BsInbox /></StyledBadge>} 
              iconPosition="start" 
              label="ABERTOS" 
            />
            <StyledTab 
              icon={<StyledBadge badgeContent={filteredChats('closed').length} color="secondary"><BsCheckCircle /></StyledBadge>} 
              iconPosition="start" 
              label="FECHADOS" 
            />
            <StyledTab icon={<BsSearch />} iconPosition="start" label="BUSCAR" />
          </Tabs>

          {tabIndex === 0 && (
            <Tabs 
              value={subTabIndex} 
              onChange={handleSubTabChange} 
              variant="fullWidth" 
              sx={{ 
                bgcolor: '#F0F2F5',
                '& .MuiTabs-indicator': {
                  backgroundColor: lightHeaderColor,
                },
              }}
            >
              <StyledTab label={`ATRIBUÍDOS (${filteredChats('open', 'ASSIGNED').length})`} />
              <StyledTab label={`PENDENTES (${filteredChats('open', 'PENDING').length})`} />
            </Tabs>
          )}

          {tabIndex === 2 && (
            <Box sx={{ display: 'flex', alignItems: 'center', padding: '10px', bgcolor: '#F0F2F5' }}>
              <InputBase 
                placeholder="Buscar tickets e mensagens" 
                fullWidth 
                sx={{ 
                  padding: '8px 12px', 
                  bgcolor: '#fff', 
                  borderRadius: '20px',
                  '&:focus-within': {
                    boxShadow: `0 0 0 1px ${lightHeaderColor}`,
                  },
                }} 
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                startAdornment={<BsSearch style={{ marginRight: 8, color: lightHeaderColor }} />}
              />
              <IconButton sx={{ ml: 1, color: lightHeaderColor }}>
                <BsFilter />
              </IconButton>
            </Box>
          )}

          <Box sx={{ 
            flexGrow: 1, 
            overflowY: 'auto', 
            overflowX: 'hidden',
            '&::-webkit-scrollbar': { width: '6px' }, 
            '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0,0,0,.2)' },
            display: 'flex',
            flexDirection: 'column',
          }}>
            {renderChats()}
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
          <Tooltip title="Chats Abertos" placement="right">
            <Badge badgeContent={filteredChats('open').length} color="secondary">
              <IconButton onClick={() => handleIconClick(0, 0)}>
                <BsInbox />
              </IconButton>
            </Badge>
          </Tooltip>
          <Tooltip title="Chats Fechados" placement="right">
            <Badge badgeContent={filteredChats('closed').length} color="secondary">
              <IconButton onClick={() => handleIconClick(1)}>
                <BsCheckCircle />
              </IconButton>
            </Badge>
          </Tooltip>
          <Tooltip title="Buscar" placement="right">
            <IconButton onClick={() => handleIconClick(2)}>
              <BsSearch />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </SidebarContainer>
  );
};

export default LeftContainer;