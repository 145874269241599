import React, { useState, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, Textarea, Select, Box, Text } from '@chakra-ui/react';
import { FaSave } from 'react-icons/fa';
import styled from 'styled-components';
import CustomButton from 'components/CustomButton';
import { useAuth } from 'context/authContext';
import { fetchClinicaById } from 'services/clientesService';
import { sendReportEmail } from 'services/emailService';

const ErrorText = styled(Text)`
  color: red;
`;

interface ReportProblemModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ReportProblemModal: React.FC<ReportProblemModalProps> = ({ isOpen, onClose }) => {
  const { userData } = useAuth();
  const [clinica, setClinica] = useState<any>(null);
  const [reportType, setReportType] = useState('');
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (userData?.id_clinica) {
      fetchClinicaById(userData.id_clinica).then(setClinica).catch(console.error);
    }
  }, [userData]);

  const handleSave = async () => {
    if (!summary) {
      setError('O resumo é obrigatório.');
      return;
    }

    const emailData = {
      id_user: userData?.id_users,
      nome: userData?.nome_usuario,
      email: userData?.email_usuario,
      id_clinica: userData?.id_clinica,
      nome_clinica: clinica?.nome_clinica,
      telefone_clinica: clinica?.telefone_clinica,
      reportType,
      summary,
      description,
    };

    try {
      await sendReportEmail(emailData);
      onClose();
    } catch (err) {
      setError('Erro ao enviar o e-mail. Tente novamente.');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === 'reportType') setReportType(value);
    if (name === 'summary') setSummary(value);
    if (name === 'description') setDescription(value);
    setError('');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Reportar um Problema</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Text>Tipo de Relatório</Text>
            <Select name="reportType" value={reportType} onChange={handleChange}>
              <option value="Sugestão de Melhorias">Sugestão de Melhorias</option>
              <option value="Problema encontrado">Problema encontrado</option>
              <option value="Dúvidas">Dúvidas</option>
            </Select>
          </Box>
          <Box mb={4}>
            <Text>Resumo</Text>
            <Input
              type="text"
              name="summary"
              value={summary}
              onChange={handleChange}
              isRequired
            />
            {error && <ErrorText>{error}</ErrorText>}
          </Box>
          <Box mb={4}>
            <Text>Descrição Detalhada</Text>
            <Textarea
              name="description"
              value={description}
              onChange={handleChange}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <CustomButton leftIcon={<FaSave />} onClick={handleSave} text="Enviar" />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReportProblemModal;
