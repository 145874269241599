import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Heading,
  Text,
  Link,
  Image,
  useToast,
  VStack,
  HStack,
  Grid,
  GridItem,
  IconButton,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { login } from 'services/userService';
import { useAuth } from 'context/authContext';
import minnerIcon from '../../images/minner-logov1.png';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { setIsAuthenticated, setUserData } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const [formError, setFormError] = useState('');

  useEffect(() => {
    if (formError) {
      const timer = setTimeout(() => {
        setFormError('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [formError]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const loginData = {
        email_usuario: email,
        senha_usuario: password,
      };
      const userData = await login(loginData);

      setIsAuthenticated(true);
      setUserData(userData);

      navigate('/');
    } catch (error: any) {
      console.error('Erro durante login:', error);
      toast({
        title: 'Erro no login.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgGradient="linear(135deg, #8DA4CC 0%, #6F87B5 50%, #4A5E80 100%)"
      px={{ base: 4, md: 0 }}
    >
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
        gap={0}
        w="100%"
        maxW="800px"
        bg="white"
        boxShadow="0 4px 12px rgba(0, 0, 0, 0.1)"
        borderRadius="8px"
        overflow="hidden"
      >
        <GridItem
          display={{ base: 'none', md: 'block' }}
          bgImage="url('/secretaria.png')"
          bgSize="cover"
          bgPosition="center"
        />
        <GridItem bg="#f7f8fc" p={8}>
          <VStack spacing={6} align="stretch">
            <Box>
              <Image src={minnerIcon} alt="Logotipo" mx="auto" mb={4} maxW="150px" />
            </Box>
            <Box>
              <Heading as="h2" size="xl" mb={2}>Bem-vindo!</Heading>
              <Text color="gray.500">Faça login em sua conta</Text>
            </Box>
            <Box as="form" onSubmit={handleLogin}>
              <VStack spacing={4}>
                <FormControl>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="username"
                  />
                </FormControl>
                <FormControl>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Senha"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                    />
                    <InputRightElement>
                      <IconButton
                        aria-label={showPassword ? "Hide password" : "Show password"}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        onClick={togglePasswordVisibility}
                        variant="ghost"
                        size="sm"
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                {formError && (
                  <Text color="red.500">
                    {formError}
                  </Text>
                )}
                <HStack justify="space-between" w="100%">
                  <Button colorScheme="blue" type="submit" width="45%">
                    Continuar
                  </Button>
                  <Link color="blue.500" href="/forgot-password">Esqueceu a senha?</Link>
                </HStack>
              </VStack>
            </Box>
            <VStack spacing={2} align="stretch">
              <Text color="gray.500">
                Você ainda não tem uma conta Minner AI Clinical?
              </Text>
              <Link href="/register" color="blue.500" fontWeight="bold">
                Crie sua conta grátis aqui!
              </Link>
            </VStack>
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Login;