import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from 'context/authContext';
import {
  getChatsList,
  getChatMessages,
  sendMessage,
  createNewChat,
  updateChatTitle,
  deleteChatService,
} from 'services/chatSecretariaService';
import ChatListComponent from './ChatList';
import ChatMessagesComponent from './ChatMessages';
import MessageInputComponent from './MessageInput';
import ConfirmDialog from './ConfirmDialog';
import { FaBars, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import styled, { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import Toggle from './Toggle';

interface ChatSecretariaEntity {
  id: string;
  id_user: string;
  id_clinica: string;
  title: string;
  created_at: Date;
}

interface ChatSecretariaMessageEntity {
  id: string;
  id_user: string;
  user_query?: string;
  llm_answer?: string;
  created_at: Date;
}

const ChatSecretaria: React.FC = () => {
  const { userData } = useAuth();
  const [chats, setChats] = useState<ChatSecretariaEntity[]>([]);
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [messages, setMessages] = useState<ChatSecretariaMessageEntity[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [editingChatId, setEditingChatId] = useState<string | null>(null);
  const [editingTitle, setEditingTitle] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [chatToDelete, setChatToDelete] = useState<string | null>(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleError = (errorMessage: string, toastMessage: string) => {
    setError(errorMessage);
    toast.error(toastMessage, {
      position: 'top-right',
      autoClose: 5000,
    });
  };

  const loadChats = useCallback(async () => {
    if (userData?.id_users && userData?.id_clinica) {
      try {
        setIsLoadingChats(true);
        const chatsList = await getChatsList(
          userData.id_users,
          userData.id_clinica
        );
        setChats(chatsList);
        setError(null);
      } catch (error) {
        console.error('Error loading chats:', error);
        handleError(
          'Failed to load chats. Please try again.',
          'Error: Could not load chat list'
        );
      } finally {
        setIsLoadingChats(false);
      }
    }
  }, [userData]);

  const loadMessages = useCallback(
    async (chatId: string) => {
      if (userData?.id_clinica) {
        try {
          setIsLoadingMessages(true);
          const chatMessages = await getChatMessages(
            chatId,
            userData.id_clinica
          );
          setMessages(chatMessages);
          setError(null);
        } catch (error) {
          console.error('Error loading messages:', error);
          handleError(
            'Failed to load messages. Please try again.',
            'Error: Could not load messages'
          );
        } finally {
          setIsLoadingMessages(false);
        }
      }
    },
    [userData]
  );

  useEffect(() => {
    loadChats();
  }, [loadChats]);

  useEffect(() => {
    if (selectedChat) {
      loadMessages(selectedChat);
    }
  }, [selectedChat, loadMessages]);

  const handleSendMessage = useCallback(async () => {
    if (
      newMessage.trim() &&
      selectedChat &&
      userData?.id_users &&
      userData?.id_clinica
    ) {
      try {
        setIsTyping(true);
        const { userMessage, aiMessage } = await sendMessage(
          userData.id_users,
          selectedChat,
          userData.id_clinica,
          newMessage
        );
        setMessages((prevMessages) => [...prevMessages, userMessage, aiMessage]);
        setNewMessage('');
        setIsTyping(false);
        setSuggestions([
          'Pode me dar mais detalhes?',
          'Entendi, vou verificar isso.',
          'Gostaria de agendar uma consulta?',
        ]);
        setError(null);
      } catch (error) {
        console.error('Error sending message:', error);
        handleError(
          'Failed to send message. Please try again.',
          'Error: Could not send message'
        );
        setIsTyping(false);
      }
    }
  }, [newMessage, selectedChat, userData]);

  const handleNewChat = async () => {
    if (userData?.id_users && userData?.id_clinica) {
      try {
        const newChat = await createNewChat(
          userData.id_users,
          userData.id_clinica
        );
        setChats((prevChats) => [newChat, ...prevChats]);
        setSelectedChat(newChat.id);
        setMessages([]);
        setError(null);
      } catch (error) {
        console.error('Error creating new chat:', error);
        handleError(
          'Failed to create new chat. Please try again.',
          'Error: Could not create new chat'
        );
      }
    }
  };

  const handleEditTitle = async (chatId: string, newTitle: string) => {
    try {
      const updatedChat = await updateChatTitle(chatId, newTitle);
      setChats((prevChats) =>
        prevChats.map((chat) => (chat.id === chatId ? updatedChat : chat))
      );
      setEditingChatId(null);
      setError(null);
      toast.success('Chat title updated successfully');
    } catch (error) {
      console.error('Error updating chat title:', error);
      handleError(
        'Failed to update chat title. Please try again.',
        'Error: Could not update chat title'
      );
    }
  };

  const handleDeleteChat = (chatId: string) => {
    setChatToDelete(chatId);
    setIsConfirmDialogOpen(true);
  };

  const confirmDeleteChat = async () => {
    if (chatToDelete) {
      try {
        await deleteChatService(chatToDelete);
        setChats((prevChats) =>
          prevChats.filter((chat) => chat.id !== chatToDelete)
        );
        if (selectedChat === chatToDelete) {
          setSelectedChat(null);
          setMessages([]);
        }
        toast.success('Chat deleted successfully');
      } catch (error) {
        console.error('Error deleting chat:', error);
        handleError(
          'Failed to delete chat. Please try again.',
          'Error: Could not delete chat'
        );
      }
    }
    setIsConfirmDialogOpen(false);
    setChatToDelete(null);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  if (!userData) {
    return <LoadingIndicator>Loading user data...</LoadingIndicator>;
  }

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <ChatContainer>
        <AnimatePresence>
          {isSidebarOpen && (
            <LeftContainer
              initial={{ x: -250, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -250, opacity: 0 }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              isSidebarOpen={isSidebarOpen}
            >
              <NewChatButton
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleNewChat}
              >
                + Novo Chat
              </NewChatButton>
              {isLoadingChats ? (
                <LoadingIndicator>
                  <LoadingSpinner />
                  Carregando chats...
                </LoadingIndicator>
              ) : (
                <ChatListComponent
                  chats={chats}
                  selectedChat={selectedChat}
                  editingChatId={editingChatId}
                  editingTitle={editingTitle}
                  onChatSelect={setSelectedChat}
                  onEditStart={(chatId, title) => {
                    setEditingChatId(chatId);
                    setEditingTitle(title);
                  }}
                  onEditCancel={() => setEditingChatId(null)}
                  onEditSave={handleEditTitle}
                  onTitleChange={setEditingTitle}
                  onDeleteChat={handleDeleteChat}
                />
              )}
            </LeftContainer>
          )}
        </AnimatePresence>
        <RightContainer $isSidebarOpen={isSidebarOpen}>
          <ChatHeader>
            <ToggleSidebarButton
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={toggleSidebar}
              aria-label="Toggle Sidebar"
            >
              {isSidebarOpen ? <FaTimes /> : <FaBars />}
            </ToggleSidebarButton>
            <HeaderTitle>
              {selectedChat
                ? chats.find((chat) => chat.id === selectedChat)?.title ||
                  'Chat Secretária'
                : 'Selecione ou crie um novo chat'}
            </HeaderTitle>
            <Toggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
          </ChatHeader>
          <MessageArea>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            {isLoadingMessages ? (
              <LoadingIndicator>
                <LoadingSpinner />
                Carregando mensagens...
              </LoadingIndicator>
            ) : (
              <ChatMessagesComponent
                messages={messages}
                isTyping={isTyping}
                currentUserId={userData?.id_users || ''}
              />
            )}
          </MessageArea>
          <MessageInputComponent
            newMessage={newMessage}
            suggestions={suggestions}
            onMessageChange={setNewMessage}
            onSendMessage={handleSendMessage}
            onSuggestionClick={(suggestion) =>
              setNewMessage((prevMessage) => prevMessage + suggestion)
            }
          />
        </RightContainer>
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          onClose={() => setIsConfirmDialogOpen(false)}
          onConfirm={confirmDeleteChat}
          message="Tem certeza que deseja excluir este chat? Esta ação não pode ser desfeita."
        />
      </ChatContainer>
    </ThemeProvider>
  );
};

const ChatContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
  color: ${({ theme }) => theme.text};
  position: relative;
`;

const LeftContainer = styled(motion.div)<{ isSidebarOpen: boolean }>`
  position: fixed;
  width: 250px;
  height: 100%;
  background-color: #8da4cc;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 10;
  left: ${({ isSidebarOpen }) => (isSidebarOpen ? '0' : '-250px')};
  transition: left 0.3s ease;

  @media (max-width: 768px) {
    left: ${({ isSidebarOpen }) => (isSidebarOpen ? '0' : '-250px')};
  }
`;

const RightContainer = styled.div<{ $isSidebarOpen: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
  transition: margin-left 0.3s ease, width 0.3s ease;
  margin-left: ${({ $isSidebarOpen }) => ($isSidebarOpen ? '250px' : '0')};

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const NewChatButton = styled(motion.button)`
  width: 100%;
  padding: 12px;
  background-color: #c8f577;
  color: #333;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #b9e668;
  }

  svg {
    margin-right: 8px;
  }
`;

const ChatHeader = styled.div`
  background-color: #8da4cc;
  color: #ffffff;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const HeaderTitle = styled(motion.div)`
  flex: 1;
  text-align: center;
`;

const ToggleSidebarButton = styled(motion.button)`
  background: none;
  border: none;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageArea = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 120px);
`;

const LoadingIndicator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #8da4cc;
  font-style: italic;

  svg {
    margin-bottom: 10px;
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(141, 164, 204, 0.3);
  border-top: 4px solid #8da4cc;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled(motion.div)`
  background-color: #ff6b6b;
  color: #ffffff;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 14px;
`;

export default ChatSecretaria;
