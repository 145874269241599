// theme.ts
export const lightTheme = {
    background: '#f0f4f8',
    backgroundSecondary: '#ffffff',
    text: '#333',
    primary: '#8da4cc',
    messageBackground: '#f0f4f8',
    scrollbarTrack: '#e6edf7',
    scrollbarThumb: '#8da4cc',
    scrollbarThumbHover: '#7b93b8',
    buttonCancelBackground: '#1a1a2e',
    buttonCancelText: '#ffffff',
    buttonCancelHover: '#0f3460',
    buttonConfirmBackground: '#c8f577',
    buttonConfirmText: '#16213e',
    buttonConfirmHover: '#bde368',
  };
  
  export const darkTheme = {
    background: '#1a1a2e',
    backgroundSecondary: '#16213e',
    text: '#ffffff',
    primary: '#c8f577',
    messageBackground: '#1a1a2e',
    scrollbarTrack: '#0f3460',
    scrollbarThumb: '#c8f577',
    scrollbarThumbHover: '#bde368',
    buttonCancelBackground: '#1a1a2e',
    buttonCancelText: '#ffffff',
    buttonCancelHover: '#0f3460',
    buttonConfirmBackground: '#c8f577',
    buttonConfirmText: '#16213e',
    buttonConfirmHover: '#bde368',
  };