import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

// Configuração do modo de cor
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

// Definindo as cores baseadas na cor do header
const lightHeaderColor = '#8DA4CC';
const darkHeaderColor = '#8DA4CC'; //'#427BCA';

// Cores derivadas da cor do header
const lightColors = {
  background: '#F0F4FA',
  softbackground: '#D6E0F0',
  text: '#2F3D40',
  accent: '#28403D',
};

const darkColors = {
  background: '#5A8DDE', // Tom levemente mais claro que #427BCA para o conteúdo
  softbackground: '#689CE8', // Tom levemente mais claro que #5A8DDE
  text: '#E2E8F0',
  accent: '#6C8A9F',
  menuBackground: '#5A8DDE', // Fundo do menu no modo escuro
  menuText: '#E2E8F0', // Texto do menu no modo escuro
};

// Configuração do tema
const theme = extendTheme({
  config,
  colors: {
    primary: {
      100: '#E5FCF1',
      200: '#27EF96',
      300: '#10DE82',
      400: '#0EBE6F',
      500: '#0CA25F',
      600: '#0A864F',
      700: '#086F42',
      800: '#075C37',
      900: '#064C2E',
    },
    brand: {
      500: '#455559',
    },
    background: {
      light: lightColors.background, // Fundo claro
      dark: darkColors.background, // Fundo escuro
    },
    softbackground: {
      light: lightColors.softbackground, // Fundo suave claro
      dark: darkColors.softbackground, // Fundo suave escuro
    },
    text: {
      light: lightColors.text, // Texto escuro no modo claro
      dark: darkColors.text, // Texto claro no modo escuro
    },
    header: {
      light: lightHeaderColor, // Cor do header no modo claro
      dark: darkHeaderColor, // Cor do header no modo escuro
    },
    accent: {
      light: lightColors.accent, // Cor de acento no modo claro
      dark: darkColors.accent, // Cor de acento no modo escuro
    },
    menu: {
      background: {
        light: '#fff', // Fundo do menu no modo claro
        dark: darkColors.menuBackground, // Fundo do menu no modo escuro
      },
      text: {
        light: '#2F3D40', // Texto do menu no modo claro
        dark: darkColors.menuText, // Texto do menu no modo escuro
      },
    },
  },
  fonts: {
    heading: 'Nunito, sans-serif',
    body: 'Nunito, sans-serif',
  },
  styles: {
    global: (props: any) => ({
      'html, body': {
        fontFamily: 'Nunito, sans-serif',
        backgroundColor: mode('background.light', 'background.dark')(props),
        color: mode('text.light', 'text.dark')(props),
        margin: 0,
        padding: 0,
        transition: 'background-color 0.2s, color 0.2s', // Transição suave ao mudar o tema
      },
      header: {
        backgroundColor: mode('header.light', 'header.dark')(props),
      },
      a: {
        color: mode('primary.500', 'accent.dark')(props),
        _hover: {
          textDecoration: 'underline',
        },
      },
    }),
  },
});

export default theme;
