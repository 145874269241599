import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;

export interface Help {
  id_help: number;
  indice: number;
  titulo: string;
  descricao: string;
  date_create: Date;
  url_video: string;
}

export interface CreateHelpDto {
  indice: number;
  titulo: string;
  descricao: string;
  url_video: string;
}

export interface UpdateHelpDto {
  indice?: number;
  titulo?: string;
  descricao?: string;
  url_video?: string;
}

export const fetchHelps = async (): Promise<Help[]> => {
  const response = await axios.get<Help[]>(`${API_URL}/api/help`);
  return response.data;
};

export const fetchHelpById = async (id_help: number): Promise<Help> => {
  const response = await axios.get<Help>(`${API_URL}/api/help/${id_help}`);
  return response.data;
};

export const createHelp = async (createHelpDto: CreateHelpDto): Promise<Help> => {
  const response = await axios.post<Help>(`${API_URL}/api/help`, createHelpDto);
  return response.data;
};

export const updateHelp = async (id_help: number, updateHelpDto: UpdateHelpDto): Promise<Help> => {
  const response = await axios.patch<Help>(`${API_URL}/api/help/${id_help}`, updateHelpDto);
  return response.data;
};

export const deleteHelp = async (id_help: number): Promise<void> => {
  await axios.delete(`${API_URL}/api/help/${id_help}`);
};
