import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL; // Usar a URL base corretamente

export interface Image {
    id: number;
    filename: string;
    originalname: string;
    mimetype: string;
    size: number;
    path: string;
    imageUrl: string; // Adicionando a propriedade imageUrl
  }
  

export const uploadImage = async (file: File): Promise<Image> => {
  const formData = new FormData();
  formData.append('image', file); // Chave ajustada para 'image'

  try {
    const response = await axios.post<Image>(`${API_URL}/api/images/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw new Error('Failed to upload image.');
  }
};
