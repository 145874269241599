import React, { useState, useEffect, useRef } from 'react';
import { MultiValue, InputActionMeta } from 'react-select';
import { fetchEspecialidadesByClinica, EspecialidadeClinica } from 'services/especialidadesClinicaService';
import styled from 'styled-components';
import { useAuth } from 'context/authContext';

export interface Tag { // Adicionado export
  id: number;
  label: string;
  color: string;
}

interface TagPickerProps {
  selectedTags: Tag[];
  onTagSelect: (tag: Tag) => void;
  onTagDeselect: (tag: Tag) => void;
  isEditable: boolean;
}

const TagPickerContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
`;

const TagItem = styled.span<{ color: string }>`
  padding: 5px 10px;
  background-color: ${({ color }) => color};
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  position: relative;
`;

const TagDropdownItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TagDropdownItem = styled.div<{ color: string }>`
  padding: 5px 10px;
  background-color: ${({ color }) => color};
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin: 2px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
  font-size: 12px;
  width: fit-content;
`;

const TagPickerInput = styled.input`
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 12px;
`;

const TagDropdown = styled.div`
  width: 200px;
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  font-size: 10px;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  scrollbar-width: none;
`;

const TagPickerEspecialidades: React.FC<TagPickerProps> = ({ selectedTags, onTagSelect, onTagDeselect, isEditable }) => {
  const [tags, setTags] = useState<Tag[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { userData } = useAuth();

  useEffect(() => {
    const fetchTags = async () => {
      if (!userData?.id_clinica) {
        console.error('ID da clínica não disponível');
        return;
      }
      try {
        const especialidades = await fetchEspecialidadesByClinica(userData.id_clinica);
        setTags(especialidades.map(especialidade => ({
          id: especialidade.id,
          label: especialidade.ds_especialidades,
          color: especialidade.cor,
        })));
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };
    fetchTags();
  }, [userData?.id_clinica]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
        setInputValue('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTagClick = (tag: Tag) => {
    if (!selectedTags.some(selectedTag => selectedTag.label === tag.label)) {
      onTagSelect(tag);
      //console.log("Selected Tags after tag click:", selectedTags);
    }
    setInputValue('');
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    setInputValue(newValue);
    //console.log("TagPickerInput value:", newValue);
  };

  const handleChange = (selectedOptions: MultiValue<{ value: number; label: string; color: string }>) => {
   // console.log("Selected Tags before change:", selectedTags);
    
    const selectedTagsList = selectedOptions.map(option => ({
      id: option.value,
      label: option.label,
      color: option.color,
    }));

    selectedTagsList.forEach(tag => {
      if (!selectedTags.some(st => st.label === tag.label)) {
        onTagSelect(tag);
        //console.log("Selected Tags after tag select:", selectedTags);
      } else {
       // console.log(`Tag ${tag.label} is already selected.`);
      }
    });

    //console.log("Selected Tags after change:", selectedTags);
  };

  const handleRemove = (tag: Tag) => {
    onTagDeselect(tag);
    //console.log("Selected Tags after tag deselect:", selectedTags);
  };

  const filteredTags = tags.filter(tag => 
    !selectedTags.some(selectedTag => selectedTag.label === tag.label) &&
    tag.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <TagPickerContainer ref={containerRef}>
      <TagList onClick={isEditable ? handleInputFocus : undefined}>
        {selectedTags.map(tag => (
          <TagItem key={tag.id} color={tag.color}>
            {tag.label}
            {isEditable && <span onClick={() => handleRemove(tag)}>x</span>}
          </TagItem>
        ))}
        {isEditable && (
          <TagPickerInput
            type="text"
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value, { action: 'input-change', prevInputValue: inputValue })}
            onFocus={handleInputFocus}
            placeholder="Pesquisar rótulo"
          />
        )}
      </TagList>
      {showDropdown && isEditable && (
        <TagDropdown>
          {filteredTags.map(tag => (
            <TagDropdownItemContainer key={tag.id}>
              <TagDropdownItem
                color={tag.color}
                onClick={() => handleTagClick(tag)}
              >
                {tag.label}
              </TagDropdownItem>
            </TagDropdownItemContainer>
          ))}
        </TagDropdown>
      )}
    </TagPickerContainer>
  );
};

export default TagPickerEspecialidades;
