import React, { useState, useEffect } from 'react';
import { Text, Input, Switch, Tooltip } from '@chakra-ui/react';
import { getUserById, deleteUser } from 'services/userService';
import { uploadImage } from 'services/imagesService';
import Config from 'config/Config';
import CustomButton from 'components/CustomButton';
import {
  ProfileContainer,
  ProfileImageLabel,
  ProfileImage,
  FormContainer,
  FormField,
  FormRow,
  ControlButtons,
  StyledHeading,
  InfoContainer,
  HeaderContainer,
} from './UserProfile.styles';
import { passwordRegex } from '../../helpers/regex.helper';

const API_URL = Config.API_URL;

interface User {
  id_users: string;
  nome_usuario: string;
  email_usuario: string;
  senha_usuario?: string;
  url_imagem: string;
  privilegio_usuario: string;
  status_usuario: string;
  fone_user: string;
  clinica: string;
}

interface UserProfileProps {
  id_user: string;
  onCancel: () => void;
  onSave: (user: Partial<User>) => void;
  isAdding: boolean;
  updateUsersList: () => void;
}

const UserProfile: React.FC<UserProfileProps> = ({ id_user, onCancel, onSave, isAdding, updateUsersList }) => {
  const [user, setUser] = useState<Partial<User> | null>(null);
  const [isEditing, setIsEditing] = useState(isAdding);
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [formStatus, setFormStatus] = useState('ATIVO');
  const [formPrivilege, setFormPrivilege] = useState('USUARIO');

  useEffect(() => {
    if (!isAdding) {
      const loadUser = async () => {
        try {
          const userData = await getUserById(id_user);
          setUser(userData);
          setProfileImageUrl(userData.url_imagem ? `${API_URL}${userData.url_imagem}` : null);
          setFormStatus(userData.status_usuario || 'ATIVO');
          setFormPrivilege(userData.privilegio_usuario || 'USUARIO');
          setIsEditing(false);
        } catch (error) {
          console.error('Failed to load user', error);
          alert('Failed to load user. See console for details.');
        }
      };

      loadUser();
    } else {
      setUser({
        nome_usuario: '',
        email_usuario: '',
        url_imagem: '',
        status_usuario: 'ATIVO',
        privilegio_usuario: 'USUARIO',
        fone_user: '',
        clinica: '',
      });
      setIsEditing(true);
    }
  }, [id_user, isAdding]);


  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    if ((!newPassword || !confirmPassword) && isAdding) {
      setPasswordError('As senhas não podem estar vazias');
      return;
    }
    if (newPassword !== confirmPassword) {
      setPasswordError('As senhas não são iguais.');
      return;
    }

    if (newPassword && !passwordRegex.test(newPassword)) {
      setPasswordError('A senha deve conter no mínimo 8 caracteres, uma letra maiúscula e um caractere especial.');
      return;
    }

    if (user) {
      user.status_usuario = formStatus;
      user.privilegio_usuario = formPrivilege;

      if (profileImageFile) {
        const uploadedImage = await uploadImage(profileImageFile);
        user.url_imagem = uploadedImage.imageUrl;
      }

      if (isAdding) {
        user.senha_usuario = newPassword;
      }

      onSave(user);
      setIsEditing(false);
      setNewPassword('');
      setConfirmPassword('');
      setProfileImageFile(null);
      setProfileImageUrl(null);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfileImageFile(file);
      setProfileImageUrl(URL.createObjectURL(file));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (user) {
      const { name, value } = e.target;
      setUser({ ...user, [name]: value });
      if (name === 'status_usuario') {
        setFormStatus(value);
      }
      if (name === 'privilegio_usuario') {
        setFormPrivilege(value);
      }
    }
  };

  const handleDeleteUser = async () => {
    const confirmed = window.confirm('Você tem certeza que deseja deletar este usuário?');
    if (confirmed && user && user.id_users) {
      try {
        await deleteUser(user.id_users);
        alert('Usuário deletado com sucesso');
        await updateUsersList();
        onCancel();
      } catch (error) {
        console.error('Failed to delete user', error);
        alert('Failed to delete user. See console for details.');
      }
    }
  };

  const handleCancel = async () => {
    if (!isAdding) {
      try {
        const userData = await getUserById(id_user);
        setUser(userData);
        setProfileImageUrl(userData.url_imagem ? `${API_URL}${userData.url_imagem}` : null);
        setFormStatus(userData.status_usuario || 'ATIVO');
        setFormPrivilege(userData.privilegio_usuario || 'USUARIO');
        setIsEditing(false);
      } catch (error) {
        console.error('Failed to refresh user', error);
      }
    } else {
      setUser({
        nome_usuario: '',
        email_usuario: '',
        url_imagem: '',
        status_usuario: 'ATIVO',
        privilegio_usuario: 'USUARIO',
        fone_user: '',
        clinica: '',
      });
      setProfileImageUrl(null);
      setNewPassword('');
      setConfirmPassword('');
      setPasswordError('');
      setIsEditing(false);
    }
    setProfileImageFile(null); // Limpa o arquivo de imagem temporário ao cancelar
    onCancel();
  };

  return (
    <ProfileContainer>
      <HeaderContainer>
        <StyledHeading>Perfil de Usuário</StyledHeading>
        <ControlButtons>
          {!isEditing && !isAdding && (
            <>
              <CustomButton
                text="Editar"
                colorScheme="blue"
                onClick={() => setIsEditing(true)}
              />
              <CustomButton
                text="Deletar"
                colorScheme="red"
                onClick={handleDeleteUser}
                ml="2"
              />
            </>
          )}
        </ControlButtons>
      </HeaderContainer>
      {user && (
        <FormContainer onSubmit={handleSave}>
          <FormRow>
            <Tooltip label="Clique no botão de Editar e depois aqui para alterar a imagem do perfil.">
              <ProfileImageLabel htmlFor="profileImageUpload">
                <ProfileImage
                  src={profileImageUrl || '/perfil_default.jpeg'}
                  alt="Imagem de Perfil"
                />
              </ProfileImageLabel>
            </Tooltip>
            <Input
              type="file"
              id="profileImageUpload"
              name="profile_image"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              disabled={!isEditing}
            />
            <InfoContainer>
              <Text fontSize="lg">{user.nome_usuario}</Text>
              <Text fontSize="sm">{user.email_usuario}</Text>
              <FormRow>
                <FormField>
                  <Switch
                    isChecked={formStatus === 'ATIVO'}
                    onChange={(e) => {
                      const status = e.target.checked ? 'ATIVO' : 'INATIVO';
                      setFormStatus(status);
                      if (user) {
                        setUser({ ...user, status_usuario: status });
                      }
                    }}
                    disabled={!isEditing}
                    colorScheme="green"
                  />
                </FormField>
              </FormRow>
            </InfoContainer>
          </FormRow>
          <FormField>
            <label>Nome</label>
            <Input
              type="text"
              name="nome_usuario"
              value={user.nome_usuario || ''}
              onChange={handleChange}
              required
              disabled={!isEditing}
            />
          </FormField>
          <FormRow>
            <FormField>
              <label>Email</label>
              <Input
                type="email"
                name="email_usuario"
                value={user.email_usuario || ''}
                onChange={handleChange}
                required
                disabled={!isEditing}
              />
            </FormField>
            <FormField>
              <label>Telefone</label>
              <Input
                type="text"
                name="fone_user"
                value={user.fone_user || ''}
                onChange={handleChange}
                disabled={!isEditing}
              />
            </FormField>
          </FormRow>
          <FormRow style={{ maxWidth: '160px' }}>
            <FormField >
              <label>Privilégio</label>
              <select
                name="privilegio_usuario"
                value={formPrivilege}
                onChange={handleChange}
                disabled={!isEditing}
              >
                <option value="USUARIO">USUARIO</option>
                <option value="ADMINISTRADOR">ADMINISTRADOR</option>
              </select>
            </FormField>
          </FormRow>
          {isEditing && (
            <>
              <FormRow>
                <FormField>
                  <label>Nova Senha</label>
                  <Input
                    type="password"
                    name="new_password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <label>Confirme a Senha</label>
                  <Input
                    type="password"
                    name="confirm_password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FormField>
              </FormRow>
              {passwordError && (
                <Text color="red" mb="1rem">
                  {passwordError}
                </Text>
              )}
              <ControlButtons>
                <CustomButton text="Salvar" colorScheme="blue" type="submit" />
                <CustomButton
                  text="Cancelar"
                  colorScheme="gray"
                  onClick={handleCancel}
                  ml="2"
                />
              </ControlButtons>
            </>
          )}
        </FormContainer>
      )}
    </ProfileContainer>
  );
};

export default UserProfile;
