import React, { createContext, useState, ReactNode, useContext } from 'react';

interface PaymentDetails {
  amount: number;
  productId: string;
  priceId: string;
}

interface PaymentContextProps extends PaymentDetails {
  clientSecret: string | null;
  setPaymentDetails: (details: PaymentDetails) => void;
  setClientSecret: (secret: string | null) => void;
  setActiveComponent: (component: string) => void;
  activeComponent: string;
}

const PaymentContext = createContext<PaymentContextProps | undefined>(undefined);

export const PaymentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>({ amount: 0, productId: '', priceId: '' });
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [activeComponent, setActiveComponent] = useState<string>('calendario');

  const handleSetPaymentDetails = (details: PaymentDetails) => {
    setPaymentDetails(details);
  };

  const handleSetClientSecret = (secret: string | null) => {
    setClientSecret(secret);
  };

  return (
    <PaymentContext.Provider
      value={{
        ...paymentDetails,
        clientSecret,
        setPaymentDetails: handleSetPaymentDetails,
        setClientSecret: handleSetClientSecret,
        setActiveComponent,
        activeComponent
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePayment = (): PaymentContextProps => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }
  return context;
};