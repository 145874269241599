import axios from 'axios';
import Config from 'config/Config';
import { getMessageByStatus } from 'helpers/messages.helper';

// Define and export WhatsAppSession interface
export interface WhatsAppSession {
  id: string;
  name: string | null;
  status: string;
  phoneNumber: string | null;
  padrao: boolean; // Add this line
  sessionData: string;
  createdAt: string;
  clinica: {
    id_clinica: string;
    nome_clinica: string | null;
    nome_do_chatbot: string | null;
    endereco_clinica: string;
    site_clinica: string;
    telefone_clinica: string;
    horario_duteis: string | null;
    dt_create: string;
  };
}


// Interfaces
interface WhatsAppInstanceResponse {
  id: string;
  qrCode: string;
}

interface WhatsAppStatusResponse {
  status: string;
}

  // WhatsAppService Class
class WhatsAppConnectionService {
  // Cria instância do WhatsApp
  async createWhatsAppInstance(clinicId: string, name?: string, padrao: boolean = false): Promise<WhatsAppInstanceResponse> {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/${clinicId}`,
        method: 'POST',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
        data: { name, padrao },
      });
      return response.data; // Certifique-se de que o ID da sessão é retornado aqui
    } catch (error: any) {
      return this.handleError(error);
    }
  }


  // Obtém o QR Code do WhatsApp
  async getWhatsAppQRCode(sessionId: string): Promise<{ qr: string }> {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/${sessionId}/qr`,
        method: 'GET',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  // Verifica o status de conexão do WhatsApp usando sessionId
  async getWhatsAppSessionStatus(sessionId: string): Promise<WhatsAppStatusResponse> {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/${sessionId}/status`,
        method: 'GET',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  // Obtém todas as instâncias de WhatsApp por clínica
  async getWhatsAppInstancesByClinic(clinicId: string): Promise<WhatsAppSession[]> {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/clinica/${clinicId}/sessions`,
        method: 'GET',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  // Atualiza uma sessão do WhatsApp
  async updateWhatsAppSession(id: string, updateData: Partial<WhatsAppSession>): Promise<any> {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/${id}`,
        method: 'PATCH',
        data: updateData,
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }


  // Tratamento de erros
  private handleError(error: any): Promise<any> {
    console.error('Error during process:', error);
    const status = error.response?.status;
    const message = error.response?.data?.message || getMessageByStatus(status) || 'Ocorreu um erro desconhecido.';
    return Promise.reject(new Error(message));
  }

  async disconnectWhatsAppSession(sessionId: string): Promise<any> {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/${sessionId}/disconnect`,
        method: 'POST',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  // Método para excluir uma sessão do WhatsApp
  async deleteWhatsAppSession(sessionId: string): Promise<any> {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/${sessionId}`,
        method: 'DELETE',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }
  
}

// Export
const whatsAppConnectionService = new WhatsAppConnectionService();
export default whatsAppConnectionService;