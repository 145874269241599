import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Spinner,
  Text,
  Input,
  HStack,
  Box,
  VStack,
  Button,
  useToast,
  IconButton,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon, ChevronLeftIcon } from "@chakra-ui/icons"; 
import { FaTimes } from 'react-icons/fa';
import { fetchEspecialidades, Especialidade } from '../../../services/especialidadesService';
import { fetchEspecialidadesByClinica, EspecialidadeClinica, createEspecialidade, deleteEspecialidade, updateEspecialidade } from '../../../services/especialidadesClinicaService';
import { useAuth } from 'context/authContext';

interface EspecialidadesManagerProps {
  onBack: () => void;
}

const EspecialidadesManager: React.FC<EspecialidadesManagerProps> = ({ onBack }) => {
  const [especialidades, setEspecialidades] = useState<Especialidade[]>([]);
  const [especialidadesClinica, setEspecialidadesClinica] = useState<EspecialidadeClinica[]>([]);
  const [filteredEspecialidades, setFilteredEspecialidades] = useState<Especialidade[]>([]);
  const [filteredEspecialidadesClinica, setFilteredEspecialidadesClinica] = useState<EspecialidadeClinica[]>([]);
  const [isLoadingEspecialidades, setIsLoadingEspecialidades] = useState(true);
  const [isLoadingClinicaEspecialidades, setIsLoadingClinicaEspecialidades] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermClinica, setSearchTermClinica] = useState('');
  const [newEspecialidade, setNewEspecialidade] = useState('');
  const [newCor, setNewCor] = useState('#000000');
  const [showAddEspecialidadeForm, setShowAddEspecialidadeForm] = useState(false);
  const [editEspecialidade, setEditEspecialidade] = useState<EspecialidadeClinica | null>(null);
  const [selectedEspecialidadeId, setSelectedEspecialidadeId] = useState<number | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userData } = useAuth();
  const toast = useToast();

  const colorOptions = [
    '#FF5733', // Laranja avermelhado
  '#33FF57', // Verde brilhante
  '#3357FF', // Azul escuro
  '#F333FF', // Rosa forte
  '#FF3383', // Rosa avermelhado
  '#33FFF5', // Verde água
  '#FF9933', // Laranja
  '#33FF99', // Verde limão
  '#FF33FF', // Magenta
  '#FF3333', // Vermelho forte
  '#3333FF', // Azul royal
  '#33FF33', // Verde brilhante
  '#FF7F50', // Coral
  '#D2691E', // Chocolate
  '#8B0000', // Vermelho escuro
  '#006400', // Verde escuro
  '#483D8B', // Azul ardósia escuro
  '#2F4F4F', // Cinza escuro
  '#8B4513', // Marrom sela
  '#FFD700', // Ouro
  ];


  const loadEspecialidades = useCallback(async () => {
    try {
      setIsLoadingEspecialidades(true);
      const data = await fetchEspecialidades();
      const sortedData = data.sort((a, b) =>
        a.ds_especialidades.localeCompare(b.ds_especialidades)
      );
      setEspecialidades(sortedData);
      setFilteredEspecialidades(sortedData);
      setError(null);
    } catch (err) {
      setError('Falha ao carregar especialidades.');
      console.error(err);
    } finally {
      setIsLoadingEspecialidades(false);
    }
  }, []);

  const loadEspecialidadesClinica = useCallback(async () => {
    if (!userData?.id_clinica) {
      setError('ID da clínica não disponível.');
      return;
    }
    try {
      setIsLoadingClinicaEspecialidades(true);
      const data = await fetchEspecialidadesByClinica(userData.id_clinica);
      const sortedData = data.sort((a, b) =>
        a.ds_especialidades.localeCompare(b.ds_especialidades)
      );
      setEspecialidadesClinica(sortedData);
      setFilteredEspecialidadesClinica(sortedData);
    } catch (err) {
      setError('Falha ao carregar especialidades da clínica.');
      console.error(err);
    } finally {
      setIsLoadingClinicaEspecialidades(false);
    }
  }, [userData?.id_clinica]);

  useEffect(() => {
    loadEspecialidades();
    if (userData?.id_clinica) {
      loadEspecialidadesClinica();
    }
  }, [userData?.id_clinica, loadEspecialidades, loadEspecialidadesClinica]);

  useEffect(() => {
    const filtered = especialidades.filter(esp =>
      esp.ds_especialidades.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !especialidadesClinica.some(clinicaEsp => clinicaEsp.id_especialidades === esp.id)
    );
    setFilteredEspecialidades(filtered);
  }, [searchTerm, especialidades, especialidadesClinica]);

  useEffect(() => {
    const filtered = especialidadesClinica.filter(esp =>
      esp.ds_especialidades.toLowerCase().includes(searchTermClinica.toLowerCase())
    );
    setFilteredEspecialidadesClinica(filtered);
  }, [searchTermClinica, especialidadesClinica]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClinica = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTermClinica(event.target.value);
  };

  const confirmDelete = (id: number) => {
    setSelectedEspecialidadeId(id);
    onOpen();
  };

  const handleDelete = async () => {
    if (selectedEspecialidadeId === null) return;

    try {
      await deleteEspecialidade(selectedEspecialidadeId);
      setEspecialidadesClinica(prev => prev.filter(esp => esp.id !== selectedEspecialidadeId));
      toast({
        title: "Sucesso",
        description: "Especialidade deletada com sucesso.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error('Erro ao deletar especialidade:', error);
      toast({
        title: "Erro",
        description: "Falha ao deletar especialidade.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (especialidade: EspecialidadeClinica) => {
    setEditEspecialidade(especialidade);
    setNewEspecialidade(especialidade.ds_especialidades);
    setNewCor(especialidade.cor);
    setShowAddEspecialidadeForm(true); // Exibe o formulário para edição
  };

  const handleDoubleClick = async (especialidade: Especialidade) => {
    if (!userData?.id_clinica) {
      toast({
        title: "Erro",
        description: "ID da clínica não disponível.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const isDuplicate = especialidadesClinica.some(
      esp => esp.id_especialidades === especialidade.id
    );

    if (isDuplicate) {
      toast({
        title: "Aviso",
        description: "Esta especialidade já está na lista da clínica.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const newEspecialidadeClinica = await createEspecialidade({
        id_especialidades: especialidade.id,
        ds_especialidades: especialidade.ds_especialidades,
        cor: especialidade.cor,
        clinica_id: userData.id_clinica
      });

      setEspecialidadesClinica(prev => [...prev, newEspecialidadeClinica].sort((a, b) =>
        a.ds_especialidades.localeCompare(b.ds_especialidades)
      ));

      toast({
        title: "Sucesso",
        description: "Especialidade adicionada à clínica.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setFilteredEspecialidades(prev =>
        prev.filter(esp => esp.id !== especialidade.id)
      );

    } catch (error) {
      console.error('Erro ao adicionar especialidade:', error);
      toast({
        title: "Erro",
        description: "Falha ao adicionar especialidade à clínica.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSaveEspecialidade = async () => {
    if (!newEspecialidade || !newCor) {
      toast({
        title: "Erro",
        description: "Por favor, preencha todos os campos.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!userData?.id_clinica) {
      toast({
        title: "Erro",
        description: "ID da clínica não disponível.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      if (editEspecialidade) {
        const updatedEspecialidadeClinica = await updateEspecialidade(editEspecialidade.id, {
          ds_especialidades: newEspecialidade,
          cor: newCor,
        });
  
        setEspecialidadesClinica(prev =>
          prev.map(esp =>
            esp.id === updatedEspecialidadeClinica.id ? updatedEspecialidadeClinica : esp
          )
        );
  
        toast({
          title: "Sucesso",
          description: "Especialidade atualizada com sucesso.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
  

      } else {
        const newEspecialidadeData = {
          ds_especialidades: newEspecialidade,
          cor: newCor,
          clinica_id: userData.id_clinica,
          id_especialidades: undefined // Adicione este campo se necessário para novas especialidades
        };

        console.log('newEspecialidadeData', newEspecialidadeData);
  
        const newEspecialidadeClinica = await createEspecialidade(newEspecialidadeData);
  
        setEspecialidadesClinica(prev => [...prev, newEspecialidadeClinica].sort((a, b) =>
          a.ds_especialidades.localeCompare(b.ds_especialidades)
        ));

        toast({
          title: "Sucesso",
          description: "Especialidade adicionada à clínica.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      setNewEspecialidade('');
      setNewCor('#000000');
      setShowAddEspecialidadeForm(false);
      setEditEspecialidade(null);

    } catch (error) {
      console.error('Erro ao salvar especialidade:', error);
      toast({
        title: "Erro",
        description: "Falha ao salvar especialidade.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl" py={12}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Tooltip label="Voltar" placement="right">
          <IconButton
            icon={<ChevronLeftIcon boxSize={6} />}
            aria-label="Voltar"
            onClick={onBack}
            variant="ghost"
          />
        </Tooltip>
        </Flex>
      <SimpleGrid columns={2} spacing={8}>
      {showAddEspecialidadeForm && (
          <Card gridColumn="span 2">
            <CardHeader display="flex" justifyContent="space-between" alignItems="center">
      <Heading size="md" mb={4}>
        {editEspecialidade ? 'Editar Especialidade' : 'Adicionar Nova Especialidade'}
      </Heading>
      <IconButton
        icon={<FaTimes />}
        aria-label="Cancelar"
        onClick={() => setShowAddEspecialidadeForm(false)}
        variant="ghost"
        colorScheme="red"
      />
    </CardHeader>
            <CardBody>
              <VStack spacing={4}>
                <Input
                  placeholder="Descrição da Especialidade"
                  value={newEspecialidade}
                  onChange={(e) => setNewEspecialidade(e.target.value.toUpperCase())}
                />
                <HStack spacing={2} wrap="wrap">
                  {colorOptions.map((color) => (
                    <Box
                      key={color}
                      w="30px"
                      h="30px"
                      borderRadius="50%"
                      bg={color}
                      cursor="pointer"
                      border={newCor === color ? '3px solid teal' : '1px solid gray'}
                      onClick={() => setNewCor(color)}
                    />
                  ))}
                </HStack>
                <Button colorScheme="blue" onClick={handleSaveEspecialidade}>
                  {editEspecialidade ? 'Atualizar Especialidade' : 'Salvar Especialidade'}
                </Button>
              </VStack>
            </CardBody>
          </Card>
        )}
        <Card>
          <CardHeader>
            <HStack justifyContent="space-between">
              <Heading size="md" mb={4}>Especialidades da Clínica</Heading>
              <Tooltip label="Adicionar Especialidade" aria-label="Adicionar Especialidade Tooltip">
                <IconButton
                  icon={<AddIcon />}
                  aria-label="Adicionar Especialidade"
                  size="sm"
                  onClick={() => {
                    setEditEspecialidade(null); // Reseta o estado de edição
                    setShowAddEspecialidadeForm(true); // Mostra o formulário ao clicar no botão
                  }}
                />
              </Tooltip>
            </HStack>
            <Input
              placeholder="Pesquisar especialidades da clínica"
              value={searchTermClinica}
              onChange={handleSearchClinica}
            />
          </CardHeader>
          <CardBody>
            {isLoadingClinicaEspecialidades ? (
              <Spinner />
            ) : error ? (
              <Text color="red.500">{error}</Text>
            ) : (
              <VStack
                align="stretch"
                maxHeight="400px"
                overflowY="auto"
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'gray.300',
                    borderRadius: '24px',
                  },
                }}
              >
                {filteredEspecialidadesClinica.map((esp) => (
                  <HStack
                    key={esp.id}
                    spacing={4}
                    p={2}
                    borderRadius="md"
                    justifyContent="space-between"
                  >
                    <HStack spacing={4}>
                      <Box
                        w="20px"
                        h="20px"
                        borderRadius="50%"
                        bg={esp.cor}
                      />
                      <Text>{esp.ds_especialidades}</Text>
                    </HStack>
                    <HStack spacing={2}>
                      <IconButton
                        icon={<EditIcon />}
                        aria-label="Editar Especialidade"
                        size="sm"
                        onClick={() => handleEdit(esp)}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        aria-label="Deletar Especialidade"
                        size="sm"
                        onClick={() => confirmDelete(esp.id)}
                      />
                    </HStack>
                  </HStack>
                ))}
              </VStack>
            )}
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Heading size="md" mb={4}>Especialidades - CFM</Heading>
            <Input
              placeholder="Pesquisar especialidades"
              value={searchTerm}
              onChange={handleSearch}
            />
          </CardHeader>
          <CardBody>
            {isLoadingEspecialidades ? (
              <Spinner />
            ) : error ? (
              <Text color="red.500">{error}</Text>
            ) : (
              <VStack
                align="stretch"
                maxHeight="350px"
                overflowY="auto"
                sx={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: 'gray.300',
                    borderRadius: '24px',
                  },
                }}
              >
                {filteredEspecialidades.map((especialidade) => (
                  <HStack
                    key={especialidade.id}
                    spacing={4}
                    onDoubleClick={() => handleDoubleClick(especialidade)}
                    cursor="pointer"
                    p={2}
                    borderRadius="md"
                    _hover={{ bg: "gray.100" }}
                  >
                    <Box
                      w="20px"
                      h="20px"
                      borderRadius="50%"
                      bg={especialidade.cor}
                    />
                    <Text>{especialidade.ds_especialidades}</Text>
                  </HStack>
                ))}
              </VStack>
            )}
          </CardBody>
        </Card>

      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Deleção</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Tem certeza que deseja deletar esta especialidade?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDelete}>
              Deletar
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default EspecialidadesManager;
