import React, { useEffect, useState, useCallback  } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Box, Button, Input, FormControl, FormLabel, Heading, VStack, useToast, Spinner } from '@chakra-ui/react';
import { createPaymentIntent } from 'services/stripeService';
import { FormContainer, LeftContainer, RightContainer, Card, PaymentCard } from './PaymentForm.styles';
import { usePayment } from 'context/PaymentContext';
import { useAuth } from '../../context/authContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const PaymentForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const { amount } = usePayment();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      setIsLoading(false);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-complete`,
        payment_method_data: {
          billing_details: {
            name,
            email,
          },
        },
      },
    });

    if (result.error) {
      toast({
        title: 'Payment Failed',
        description: result.error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Payment Successful',
        description: 'Your payment has been processed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }

    setIsLoading(false);
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <FormContainer>
        <LeftContainer>
          <VStack spacing={4} align="stretch">
            <Heading size="md">Informações do Pagamento</Heading>
            <FormControl id="name" isRequired>
              <FormLabel>Nome Completo</FormLabel>
              <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
          </VStack>
        </LeftContainer>
        <RightContainer>
          <PaymentCard>
            <PaymentElement />
          </PaymentCard>
          <Button
            type="submit"
            colorScheme="blue"
            mt="4"
            width="100%"
            isLoading={isLoading}
            loadingText="Processando"
            disabled={!stripe}
          >
            Pagar {(amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </Button>
          <Card>
            <Heading size="md">Resumo do Pedido</Heading>
            <Box mt="4">
              <Heading size="sm">
                Total: {(amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </Heading>
            </Box>
          </Card>
        </RightContainer>
      </FormContainer>
    </Box>
  );
};



const PaymentFormWrapper: React.FC = () => {
  const { amount, priceId, productId, clientSecret, setClientSecret } = usePayment();
  const { userData } = useAuth(); // Mova o `useAuth` para fora do `useCallback`
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const initializePayment = useCallback(async () => {
    if (!amount || !productId || !priceId) {
      setError('Missing payment information. Please select a plan.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      console.log('Initializing payment with:', { amount, priceId, productId });
      const { clientSecret: newClientSecret } = await createPaymentIntent(
        {
          amount,
          currency: 'brl',
          productId,
          priceId,
        },
        userData?.id_users || '', // Passe o userId aqui
        userData?.id_clinica || '' // Passe o clinicaId aqui
      );
      console.log('Received clientSecret:', newClientSecret);
      setClientSecret(newClientSecret);
    } catch (error) {
      console.error('Error initializing payment:', error);
      setError('Failed to initialize payment. Please try again.');
      toast({
        title: 'Error',
        description: 'Failed to initialize payment. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [amount, productId, priceId, setClientSecret, toast, userData]);

  useEffect(() => {
    if (!clientSecret) {
      initializePayment();
    }
  }, [clientSecret, initializePayment]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" p={5}>
        <Heading size="md" color="red.500">{error}</Heading>
      </Box>
    );
  }

  if (!clientSecret) {
    return (
      <Box textAlign="center" p={5}>
        <Heading size="md" color="red.500">Unable to initialize payment. Please try again later.</Heading>
      </Box>
    );
  }

  console.log('Rendering Elements with clientSecret:', clientSecret);

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <PaymentForm />
    </Elements>
  );
};


export default PaymentFormWrapper;