import React, { useEffect } from 'react';
import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import Header from 'components/Header';
import ClinicaModule from 'modules/ClinicaModule';
import { DefaultPageContainer, ContentContainer, LeftContainer } from '../../styles/DefaultPage.styles';

const Clinicas: React.FC = () => {
  const { toggleColorMode } = useColorMode();
  const currentTheme = useColorModeValue('light', 'dark');

  useEffect(() => {
    //console.log('Clinica page loaded');
  }, []);

  return (
    <>
      <Header theme={currentTheme} toggleTheme={toggleColorMode} />
      <DefaultPageContainer>
        <ContentContainer>
          <LeftContainer>
            <ClinicaModule />
          </LeftContainer>
        </ContentContainer>
      </DefaultPageContainer>
    </>
  );
};

export default Clinicas;