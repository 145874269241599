import axios from 'axios';

interface ViaCEPResponse {
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  ibge?: string;
  gia?: string;
  ddd?: string;
  siafi?: string;
  erro?: boolean;
}

export class ViaCEPService {
  private static readonly BASE_URL = 'https://viacep.com.br/ws';

  static async getAddressByCEP(cep: string): Promise<ViaCEPResponse | null> {
    try {
      const response = await axios.get<ViaCEPResponse>(`${this.BASE_URL}/${cep}/json`);
      if (response.data.erro) {
        throw new Error('CEP não encontrado');
      }
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar endereço:', error);
      return null;
    }
  }
}
