import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;

export interface EspecialidadeClinica {
  id: number;
  id_especialidades?: number;
  ds_especialidades: string;
  cor: string;
  clinica_id: string;
}

// Função para buscar todas as especialidades de uma clínica específica
export const fetchEspecialidadesByClinica = async (clinica_id: string): Promise<EspecialidadeClinica[]> => {
  if (!clinica_id) {
    throw new Error('ID da clínica não fornecido.');
  }
  try {
    const response = await axios.get<EspecialidadeClinica[]>(`${API_URL}/api/especialidade-clinica/clinica/${clinica_id}`);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error('Error fetching especialidades:', error.message, error.response?.data);
    } else {
      console.error('Unexpected error fetching especialidades:', error);
    }
    throw new Error('Failed to fetch especialidades.');
  }
};

// Função para buscar uma especialidade por ID
export const fetchEspecialidadeById = async (id: number): Promise<EspecialidadeClinica> => {
  try {
    const response = await axios.get<EspecialidadeClinica>(`${API_URL}/api/especialidade-clinica/${id}`);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error(`Error fetching especialidade with id ${id}:`, error.message, error.response?.data);
    } else {
      console.error(`Unexpected error fetching especialidade with id ${id}:`, error);
    }
    throw new Error('Failed to fetch especialidade.');
  }
};

// Função para criar uma nova especialidade
export const createEspecialidade = async (especialidade: Omit<EspecialidadeClinica, 'id'>): Promise<EspecialidadeClinica> => {
  try {
    const response = await axios.post<EspecialidadeClinica>(`${API_URL}/api/especialidade-clinica`, especialidade);
    console.log('response.data', response.data);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error('Error creating especialidade:', error.message, error.response?.data);
    } else {
      console.error('Unexpected error creating especialidade:', error);
    }
    throw new Error('Failed to create especialidade.');
  }
};

// Função para atualizar uma especialidade
export const updateEspecialidade = async (id: number, especialidade: Partial<EspecialidadeClinica>): Promise<EspecialidadeClinica> => {
  try {
    const response = await axios.patch<EspecialidadeClinica>(`${API_URL}/api/especialidade-clinica/${id}`, especialidade);
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error(`Error updating especialidade with id ${id}:`, error.message, error.response?.data);
    } else {
      console.error(`Unexpected error updating especialidade with id ${id}:`, error);
    }
    throw new Error('Failed to update especialidade.');
  }
};

// Função para deletar uma especialidade
export const deleteEspecialidade = async (id: number): Promise<void> => {
  try {
    await axios.delete(`${API_URL}/api/especialidade-clinica/${id}`);
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      console.error(`Error deleting especialidade with id ${id}:`, error.message, error.response?.data);
    } else {
      console.error(`Unexpected error deleting especialidade with id ${id}:`, error);
    }
    throw new Error('Failed to delete especialidade.');
  }
};

// Função utilitária para remover especialidades duplicadas
export const removeDuplicateEspecialidades = (especialidades: EspecialidadeClinica[]): EspecialidadeClinica[] => {
  const uniqueEspecialidades = especialidades.reduce((acc, current) => {
    const exists = acc.find(item => item.ds_especialidades === current.ds_especialidades);
    if (!exists) {
      acc.push(current);
    }
    return acc;
  }, [] as EspecialidadeClinica[]);
  return uniqueEspecialidades;
};