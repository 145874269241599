import React, { useEffect } from 'react';
import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import Header from 'components/Header';
import WPTickets from 'modules/WPTickets';
import { DefaultPageContainer, ContentContainer, LeftContainer } from '../../styles/DefaultPage.styles';

const Atendimento: React.FC = () => {
  const { toggleColorMode } = useColorMode();
  const currentTheme = useColorModeValue('light', 'dark');

  useEffect(() => {
    //console.log('Atendimento page loaded');
  }, []);

  return (
    <>
      <Header
        theme={currentTheme}
        toggleTheme={toggleColorMode}
      />
      <DefaultPageContainer>
        <ContentContainer>
          <LeftContainer>
            <WPTickets />
          </LeftContainer>
        </ContentContainer>
      </DefaultPageContainer>
    </>
  );
};

export default Atendimento;
