import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

interface ConfirmDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <DialogOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <DialogContent
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p>{message}</p>
            <ButtonContainer>
              <CancelButton
                onClick={onClose}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Cancelar
              </CancelButton>
              <ConfirmButton
                onClick={onConfirm}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Confirmar
              </ConfirmButton>
            </ButtonContainer>
          </DialogContent>
        </DialogOverlay>
      )}
    </AnimatePresence>
  );
};

const DialogOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const DialogContent = styled(motion.div)`
  background-color: ${({ theme }) => theme.backgroundSecondary};
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  color: ${({ theme }) => theme.text};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  p {
    margin: 0;
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Button = styled(motion.button)`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  font-weight: bold;
  transition: all 0.2s ease;
  font-size: 14px;
`;

const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.buttonCancelBackground};
  color: ${({ theme }) => theme.buttonCancelText};

  &:hover {
    background-color: ${({ theme }) => theme.buttonCancelHover};
  }
`;

const ConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.buttonConfirmBackground};
  color: ${({ theme }) => theme.buttonConfirmText};

  &:hover {
    background-color: ${({ theme }) => theme.buttonConfirmHover};
  }
`;

export default ConfirmDialog;
