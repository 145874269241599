import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;

export interface Especialidade {
  id: number;
  id_especialidades: number;
  ds_especialidades: string;
  cor: string;
}

// Função para buscar todas as especialidades
export const fetchEspecialidades = async (): Promise<Especialidade[]> => {
  try {
    const response = await axios.get<Especialidade[]>(`${API_URL}/api/especialidades`);
    return response.data;
  } catch (error) {
    console.error('Error fetching especialidades:', error);
    throw new Error('Failed to fetch especialidades.');
  }
};
