import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { motion, AnimatePresence } from 'framer-motion';
import { FaUser, FaRobot, FaHeart, FaShare, FaReply } from 'react-icons/fa';
import confetti from 'canvas-confetti';

interface MessageData {
  id: string;
  id_user: string;
  user_query?: string;
  llm_answer?: string;
  created_at: Date;
}

interface ChatMessagesProps {
  messages: MessageData[];
  isTyping: boolean;
  currentUserId: string;
}

const ChatMessagesComponent: React.FC<ChatMessagesProps> = ({
  messages,
  isTyping,
  currentUserId,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [hoveredMessage, setHoveredMessage] = useState<string | null>(null);
  const [likedMessages, setLikedMessages] = useState<Set<string>>(new Set());

  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLike = (messageId: string) => {
    if (!likedMessages.has(messageId)) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
      setLikedMessages(new Set(likedMessages).add(messageId));
    }
  };

  const renderMessageGroup = (message: MessageData, index: number) => {
    const isUser = message.id_user === currentUserId;
    const showTimestamp =
      index === 0 ||
      new Date(message.created_at).getTime() -
        new Date(messages[index - 1].created_at).getTime() >
        5 * 60 * 1000;

    return (
      <MessageGroup key={message.id} $isUser={isUser}>
        {showTimestamp && (
          <Timestamp>
            {format(new Date(message.created_at), 'dd MMM yyyy, HH:mm', {
              locale: ptBR,
            })}
          </Timestamp>
        )}
        <MessageWrapper
          $isUser={isUser}
          onMouseEnter={() => setHoveredMessage(message.id)}
          onMouseLeave={() => setHoveredMessage(null)}
        >
          <AnimatedAvatar
            $isUser={isUser}
            whileHover={{ scale: 1.1 }}
            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
          >
            {isUser ? <FaUser /> : <FaRobot />}
          </AnimatedAvatar>
          <AnimatedMessage
            $isUser={isUser}
            whileHover={{ y: -2 }}
            transition={{ type: 'spring', stiffness: 300, damping: 20 }}
          >
            <ReactMarkdown>
              {message.user_query || message.llm_answer || ''}
            </ReactMarkdown>
            <AnimatePresence>
              {hoveredMessage === message.id && (
                <ActionButtons
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  transition={{ duration: 0.2 }}
                >
                  <ActionButton
                    onClick={() => handleLike(message.id)}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    $isLiked={likedMessages.has(message.id)}
                    aria-label="Like Message"
                  >
                    <FaHeart />
                  </ActionButton>
                  <ActionButton
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    aria-label="Share Message"
                  >
                    <FaShare />
                  </ActionButton>
                  <ActionButton
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    aria-label="Reply to Message"
                  >
                    <FaReply />
                  </ActionButton>
                </ActionButtons>
              )}
            </AnimatePresence>
          </AnimatedMessage>
        </MessageWrapper>
      </MessageGroup>
    );
  };

  return (
    <ChatMessagesContainer>
      <>
        {messages.map(renderMessageGroup)}
        {isTyping && (
          <TypingIndicator>
            <AnimatedAvatar $isUser={false}>
              <FaRobot />
            </AnimatedAvatar>
            <TypingContent>
              IA está digitando
              <LoadingDots>
                <motion.div
                  animate={{
                    scale: [1, 1.2, 1],
                    transition: {
                      duration: 0.6,
                      repeat: Infinity,
                      repeatType: 'loop',
                    },
                  }}
                />
                <motion.div
                  animate={{
                    scale: [1, 1.2, 1],
                    transition: {
                      duration: 0.6,
                      delay: 0.2,
                      repeat: Infinity,
                      repeatType: 'loop',
                    },
                  }}
                />
                <motion.div
                  animate={{
                    scale: [1, 1.2, 1],
                    transition: {
                      duration: 0.6,
                      delay: 0.4,
                      repeat: Infinity,
                      repeatType: 'loop',
                    },
                  }}
                />
              </LoadingDots>
            </TypingContent>
          </TypingIndicator>
        )}
      </>
      <div ref={messagesEndRef} />
    </ChatMessagesContainer>
  );
};

const ChatMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: auto;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.messageBackground};

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.scrollbarTrack};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbarThumb};
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.scrollbarThumbHover};
  }
`;

const MessageGroup = styled.div<{ $isUser: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ $isUser }) => ($isUser ? 'flex-end' : 'flex-start')};
  margin-bottom: 16px;
`;

const MessageWrapper = styled.div<{ $isUser: boolean }>`
  display: flex;
  flex-direction: ${({ $isUser }) => ($isUser ? 'row-reverse' : 'row')};
  align-items: flex-end;
`;

const AnimatedAvatar = styled(motion.div)<{ $isUser: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: ${({ $isUser }) =>
    $isUser ? '#c8f577' : '#8da4cc'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ $isUser }) => ($isUser ? '0 0 0 12px' : '0 12px 0 0')};
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const AnimatedMessage = styled(motion.div)<{ $isUser: boolean }>`
  max-width: 70%;
  padding: 14px 18px;
  border-radius: ${({ $isUser }) =>
    $isUser ? '20px 20px 0 20px' : '20px 20px 20px 0'};
  background: ${({ $isUser }) =>
    $isUser
      ? 'linear-gradient(135deg, #c8f577 0%, #b1e35f 100%)'
      : 'linear-gradient(135deg, #ffffff 0%, #f9f9f9 100%)'};
  color: #333;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  font-size: 15px;
  line-height: 1.5;
  position: relative;
  overflow-wrap: break-word;

  p {
    margin: 0 0 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 8px;
  }

  pre {
    background-color: #e6edf7;
    color: #333;
    padding: 12px;
    border-radius: 10px;
    overflow-x: auto;
  }

  code {
    font-family: 'Courier New', Courier, monospace;
  }
`;

const Timestamp = styled.div`
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 6px;
  padding: 4px 8px;
  background-color: rgba(141, 164, 204, 0.7);
  border-radius: 12px;
  backdrop-filter: blur(4px);
`;

const TypingIndicator = styled.div`
  display: flex;
  align-items: center;
  color: #8da4cc;
  font-style: italic;
  margin-top: 12px;
`;

const TypingContent = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 14px;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const LoadingDots = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 10px;

  div {
    width: 8px;
    height: 8px;
    background-color: #8da4cc;
    border-radius: 50%;
    margin: 0 3px;
  }
`;

const ActionButtons = styled(motion.div)`
  position: absolute;
  bottom: -30px;
  right: 0;
  display: flex;
  gap: 8px;
`;

const ActionButton = styled(motion.button)<{ $isLiked?: boolean }>`
  background-color: ${({ $isLiked }) => ($isLiked ? '#ff6b6b' : '#ffffff')};
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $isLiked }) => ($isLiked ? '#ffffff' : '#8da4cc')};
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ $isLiked }) => ($isLiked ? '#ff6b6b' : '#f0f4f8')};
    color: ${({ $isLiked }) => ($isLiked ? '#ffffff' : '#7b93b8')};
  }
`;

export default ChatMessagesComponent;
