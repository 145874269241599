import React from 'react';
import styled from 'styled-components';
import { FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

interface Chat {
  id: string;
  title: string;
}

interface ChatListProps {
  chats: Chat[];
  selectedChat: string | null;
  editingChatId: string | null;
  editingTitle: string;
  onChatSelect: (chatId: string) => void;
  onEditStart: (chatId: string, title: string) => void;
  onEditCancel: () => void;
  onEditSave: (chatId: string, newTitle: string) => void;
  onTitleChange: (newTitle: string) => void;
  onDeleteChat: (chatId: string) => void;
}

const ChatListComponent: React.FC<ChatListProps> = ({
  chats,
  selectedChat,
  editingChatId,
  editingTitle,
  onChatSelect,
  onEditStart,
  onEditCancel,
  onEditSave,
  onTitleChange,
  onDeleteChat,
}) => {
  return (
    <ChatList>
      <AnimatePresence>
        {chats.map((chat, index) => (
          <ChatItem
            key={chat.id}
            layoutId={chat.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3, delay: index * 0.05 }}
          >
            <ChatItemContent
              onClick={() => onChatSelect(chat.id)}
              $isActive={chat.id === selectedChat}
            >
              {editingChatId === chat.id ? (
                <EditingForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    onEditSave(chat.id, editingTitle);
                  }}
                >
                  <EditInput
                    value={editingTitle}
                    onChange={(e) => onTitleChange(e.target.value)}
                    onBlur={() => onEditSave(chat.id, editingTitle)}
                    autoFocus
                  />
                  <EditActions>
                    <ActionButton
                      type="submit"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      $isActive={true}
                      aria-label="Save Title"
                    >
                      <FaCheck />
                    </ActionButton>
                    <ActionButton
                      type="button"
                      onClick={onEditCancel}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      $isActive={false}
                      aria-label="Cancel Edit"
                    >
                      <FaTimes />
                    </ActionButton>
                  </EditActions>
                </EditingForm>
              ) : (
                <>
                  <ChatTitle $isActive={chat.id === selectedChat}>
                    {chat.title || `Chat ${chat.id.substring(0, 8)}...`}
                  </ChatTitle>
                  <ChatActions>
                    <ActionButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditStart(chat.id, chat.title);
                      }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      $isActive={false}
                      aria-label="Edit Chat"
                    >
                      <FaEdit />
                    </ActionButton>
                    <ActionButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeleteChat(chat.id);
                      }}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      $isActive={false}
                      aria-label="Delete Chat"
                    >
                      <FaTrash />
                    </ActionButton>
                  </ChatActions>
                </>
              )}
            </ChatItemContent>
          </ChatItem>
        ))}
      </AnimatePresence>
    </ChatList>
  );
};

const ChatList = styled(motion.div)`
  background-color: #f0f4f8;
  overflow-y: auto;
  flex-grow: 1;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f4f8;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8da4cc;
    border-radius: 20px;
  }
`;

const ChatItem = styled(motion.div)`
  margin-bottom: 10px;
`;

const ChatItemContent = styled.div<{ $isActive: boolean }>`
  padding: 15px;
  cursor: pointer;
  border-radius: 12px;
  background: ${({ $isActive }) =>
    $isActive
      ? 'linear-gradient(135deg, #c8f577 0%, #8da4cc 100%)'
      : 'linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)'};
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ChatTitle = styled.span<{ $isActive: boolean }>`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  font-weight: ${({ $isActive }) => ($isActive ? 'bold' : 'normal')};
  color: ${({ $isActive }) => ($isActive ? '#333' : '#666')};
  transition: all 0.3s ease;

  ${ChatItemContent}:hover & {
    color: #333;
  }
`;

const EditingForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
`;

const EditInput = styled.input`
  flex-grow: 1;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #8da4cc;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(200, 245, 119, 0.5);
  }
`;

const EditActions = styled.div`
  display: flex;
  margin-left: 5px;
`;

const ChatActions = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease;

  ${ChatItemContent}:hover & {
    opacity: 1;
  }
`;

const ActionButton = styled(motion.button)<{ $isActive: boolean }>`
  background: none;
  border: none;
  color: ${({ $isActive }) => ($isActive ? '#ffffff' : '#8da4cc')};
  background-color: ${({ $isActive }) =>
    $isActive ? '#8da4cc' : 'transparent'};
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: all 0.3s ease;

  &:hover {
    transform: rotate(15deg) scale(1.2);
    background-color: #c8f577;
    color: #333;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(200, 245, 119, 0.5);
  }
`;

export default ChatListComponent;
